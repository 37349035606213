import React, { useState } from "react";
import { motion } from "framer-motion";
import Flag from "react-flagkit";
import edit_2 from "../static/images/edit_2.svg";
import x from "../static/images/x.svg";
import check from "../static/images/check.svg";
import axios from "axios";
import { api_url } from "../constants";
import { countries } from "../constants";
const ShippingCountryCard = ({
  country,
  shippingCountries,
  setShippingCountries,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [countryName, setCountryName] = useState(country.name);
  const [countryCode, setCountryCode] = useState(country.code);
  const [priceStandard, setPriceStandard] = useState(country.price_standard);
  const [pricePerUnitStandard, setPricePerUnitStandard] = useState(
    country.price_per_unit_standard
  );
  const [priceExpress, setPriceExpress] = useState(country.price_express);
  const [pricePerUnitExpress, setPricePerUnitExpress] = useState(
    country.price_per_unit_express
  );
  const [priceEconomy, setPriceEconomy] = useState(country.price_economy);
  const [pricePerUnitEconomy, setPricePerUnitEconomy] = useState(
    country.price_per_unit_economy
  );

  function ChangeShippingCountry(e) {
    e.preventDefault();
    const data = {
      name: countryName,
      code: countryCode,
      price_standard: priceStandard,
      price_express: priceExpress,
      price_economy: priceEconomy,
      price_per_unit_standard: pricePerUnitStandard,
      price_per_unit_express: pricePerUnitExpress,
      price_per_unit_economy: pricePerUnitEconomy,
    };
    axios
      .put(`${api_url}/shipping/country/${country.id}/`, data)
      .then((res) => {
        console.log(res.data);
        setIsEditing(false);
      })
      .catch((err) => console.log(err));
  }

  function DeleteShippingCountry(e) {
    e.preventDefault();
    axios
      .delete(`${api_url}/shipping/country/${country.id}/`)
      .then((res) => {
        console.log(res.data);
        const newShippingCountries = [...shippingCountries];
        const index = newShippingCountries.findIndex(
          (item) => item.id == country.id
        );
        newShippingCountries.splice(index, 1);
        setShippingCountries(newShippingCountries);
      })
      .catch((err) => console.log(err));
  }

  return (
    <form
      onSubmit={ChangeShippingCountry}
      className="grid items-center py-2 grid-cols-5 gap-y-2 sm:grid-cols-1 md:grid-cols-2 bg-white px-4 rounded-md"
    >
      <div className="gap-x-2 flex items-baseline font-semibold text-lg">
        <Flag country={countryCode} />{" "}
        {!isEditing ? (
          countryName
        ) : (
          <select
            value={countryName}
            onChange={(e) => {
              setCountryName(e.target.value);
              setCountryCode(
                countries.find((country) => country.name == e.target.value).code
              );
            }}
            className="border border-gray-300 px-1 py-0.5 outline-none"
          >
            {countries.map((country) => (
              <option>{country.name}</option>
            ))}
          </select>
        )}
      </div>
      {!isEditing ? (
        <div>
          <span className="hidden md:inline-block sm:inline-block font-semibold">
            Standard price:{" "}
          </span>{" "}
          {priceStandard} € / {pricePerUnitStandard} €
        </div>
      ) : (
        <span>
          <span className="hidden md:inline-block sm:inline-block font-semibold">
            Standard price:{" "}
          </span>{" "}
          <input
            type="number"
            placeholder="Price"
            value={priceStandard}
            onChange={(e) => setPriceStandard(e.target.value)}
            className="border border-gray-300 px-1 py-0.5 w-20 outline-none text-sm"
          />{" "}
          <input
            type="number"
            placeholder="Price per kg"
            value={pricePerUnitStandard}
            onChange={(e) => setPricePerUnitStandard(e.target.value)}
            className="border border-gray-300 px-1 py-0.5 w-20 outline-none text-sm"
          />{" "}
        </span>
      )}
      {!isEditing ? (
        <div>
          <span className="hidden md:inline-block sm:inline-block font-semibold">
            Express price:{" "}
          </span>{" "}
          {priceExpress} € / {pricePerUnitExpress} €
        </div>
      ) : (
        <span>
          <span className="hidden md:inline-block sm:inline-block font-semibold">
            Express price:{" "}
          </span>{" "}
          <input
            type="number"
            placeholder="Price"
            value={priceExpress}
            onChange={(e) => setPriceExpress(e.target.value)}
            className="border border-gray-300 px-1 py-0.5 w-20 outline-none text-sm"
          />{" "}
          <input
            type="number"
            placeholder="Price per kg"
            value={pricePerUnitExpress}
            onChange={(e) => setPricePerUnitExpress(e.target.value)}
            className="border border-gray-300 px-1 py-0.5 w-20 outline-none text-sm"
          />{" "}
        </span>
      )}
      {!isEditing ? (
        <div>
          <span className="hidden md:inline-block sm:inline-block font-semibold">
            Economy price:{" "}
          </span>{" "}
          {priceEconomy} € / {pricePerUnitEconomy} €
        </div>
      ) : (
        <span>
          <span className="hidden md:inline-block sm:inline-block font-semibold">
            Economy price:{" "}
          </span>{" "}
          <input
            type="number"
            placeholder="Price"
            value={priceEconomy}
            onChange={(e) => setPriceEconomy(e.target.value)}
            className="border border-gray-300 px-1 py-0.5 w-20 outline-none text-sm"
          />{" "}
          <input
            type="number"
            placeholder="Price per kg"
            value={pricePerUnitEconomy}
            onChange={(e) => setPricePerUnitEconomy(e.target.value)}
            className="border border-gray-300 px-1 py-0.5 w-20 outline-none text-sm"
          />{" "}
        </span>
      )}
      {!isEditing ? (
        <div className="flex justify-end gap-x-4 md:col-span-2">
          <motion.img
            onClick={() => {
              setIsEditing(true);
            }}
            whileHover={{ scale: 1.15 }}
            whileTap={{ scale: 0.9 }}
            src={edit_2}
            className="w-4 cursor-pointer"
          />
          <motion.img
            whileHover={{ scale: 1.15 }}
            whileTap={{ scale: 0.9 }}
            src={x}
            className="w-4 cursor-pointer"
            onClick={DeleteShippingCountry}
          />
        </div>
      ) : (
        <button>
          <img src={check} className="w-4 ml-auto cursor-pointer" />
        </button>
      )}
    </form>
  );
};

export default ShippingCountryCard;

import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const HomeFeature = ({ link, image, header, paragraph, index }) => {
  return (
    <Link to={link}>
      <motion.div
        className="bg-white min-w-[12rem] h-full rounded-xl px-4 py-6 flex gap-x-6 items-center drop-shadow-lg"
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{
          opacity: 1,
          scale: 1,
          transition: { duration: 0.5, delay: index * 0.2 },
        }}
        whileHover={{ scale: 1.02 }}
        viewport={{ once: true }}
      >
        <img src={image} className="h-fit w-28" />
        <div className="">
          <h3 className="text-lg font-semibold">{header}</h3>
          <p className="mt-1">{paragraph}</p>
        </div>
      </motion.div>
    </Link>
  );
};

export default HomeFeature;

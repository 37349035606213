import React, { useState } from "react";
import telephone from "../static/images/telephone.svg";
import open_letter from "../static/images/open-letter.svg";
import facebook from "../static/images/facebook.svg";
import instagram from "../static/images/instagram.svg";
import linkedin from "../static/images/linkedin.svg";
import upload from "../static/images/upload.svg";
import plane from "../static/images/plane.svg";
import { setGlobalErrorMessage } from "../store/errorMessageSlice";
import LoadingSpinner from "../components/LoadingSpinner";
import { useDispatch } from "react-redux";
import axios from "axios";
import { api_url } from "../constants";
import { motion } from "framer-motion";
const Contact = () => {
  const [selectedInterest, setSelectedInterest] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  function SendContactMessage(e) {
    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;
    const message = e.target.message.value;
    if (name == "" || email == "" || phone == "" || message == "") {
      dispatch(setGlobalErrorMessage("Please fill all the fields"));
      return;
    }
    if (!email.includes("@")) {
      dispatch(setGlobalErrorMessage("Please enter a valid email"));
      return;
    }
    if (phone.length < 5) {
      dispatch(setGlobalErrorMessage("Please enter a valid phone number"));
      return;
    }
    if (selectedInterest == "") {
      dispatch(setGlobalErrorMessage("Please select an interest"));
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("message", message);
    formData.append("interest", selectedInterest);
    if (selectedFile) {
      formData.append("file", selectedFile);
      formData.append("file_type", selectedFile.type);
    }

    axios
      .post(api_url + "/contact/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("super");
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        setSelectedFile(null);
        e.target.reset();
        setSelectedInterest("");
      })
      .catch((err) => {
        setIsSuccess(false);
        setIsLoading(false);
        setIsError(true);
      });
  }

  return (
    <div className="grid mx-52 xl:mx-40 sm:flex sm:flex-col-reverse md:flex md:flex-col-reverse lg:mx-12 md:mx-6 sm:mx-6 gap-x-20 lg:gap-x-12 mt-16 md:mt-4 sm:mt-4 p-12 sm:px-4 grid-cols-[0.8fr,1fr] bg-white rounded-3xl">
      <div className="flex flex-col">
        <p className="font-bold md:hidden sm:hidden text-customGray text-4xl lg:text-3xl">
          Let’s discuss on something <span className="text-primary">cool</span>{" "}
          together
        </p>
        <div className="bg-primary rounded-3xl drop-shadow-extraHard px-12 py-14 text-white mt-16 md:mt-4 sm:mt-8">
          <h1 className="w-fit mx-auto text-center text-4xl lg:text-3xl font-bold sm:text-3xl">
            Contact Us
          </h1>
          <div className="flex gap-x-2 items-center font-inter font-medium text-lg w-fit mx-auto text-center mt-6">
            <img src={open_letter} className="w-6" /> office@apakz.com
          </div>
          <div className="flex gap-x-2 items-center font-medium font-inter text-lg w-fit mx-auto text-center mt-6">
            <img src={telephone} className="w-6" /> +373 699 58 558
          </div>
        </div>
        <div className="mt-auto flex gap-x-2 md:mt-8 md:gap-x-4 md:items-center sm:mt-6">
          <img src={facebook} className="w-6 md:w-8" />
          <img src={instagram} className="w-6 md:w-8" />
          <img src={linkedin} className="w-6 md:w-8" />
          <div className="text-customGray font-medium md:text-xl">
            Follow us
          </div>
        </div>
      </div>
      <form
        onSubmit={SendContactMessage}
        className="bg-[#DFDFDF] p-8 sm:px-4 rounded-3xl drop-shadow-hard"
      >
        <p className="font-bold md:block hidden text-customGray text-4xl lg:text-3xl sm:text-3xl">
          Let’s discuss on something <span className="text-primary">cool</span>{" "}
          together
        </p>
        <label className="text-customGray font-medium text-lg md:mt-8 block">
          I'm interested in...
        </label>
        <div className="grid grid-cols-3 gap-x-8 mt-3 sm:grid-cols-1 gap-y-3">
          <button
            disabled={isLoading}
            type="button"
            onClick={() => setSelectedInterest("Packaging")}
            className={
              " border-customGray font-medium transition-colors ease-in-out duration-200 rounded-lg border-2 border-solid  px-4 py-3" +
              (selectedInterest == "Packaging"
                ? " bg-primary text-white border-primary"
                : " hover:bg-primary/50 bg-transparent text-customGray")
            }
          >
            Packaging
          </button>
          <button
            disabled={isLoading}
            type="button"
            onClick={() => setSelectedInterest("Design")}
            className={
              " border-customGray font-medium transition-colors ease-in-out duration-200 rounded-lg border-2 border-solid  px-4 py-3" +
              (selectedInterest == "Design"
                ? " bg-primary text-white border-primary"
                : " hover:bg-primary/50 bg-transparent text-customGray")
            }
          >
            Design
          </button>
          <button
            disabled={isLoading}
            type="button"
            onClick={() => setSelectedInterest("Printing")}
            className={
              " border-customGray font-medium transition-colors ease-in-out duration-200 rounded-lg border-2 border-solid  px-4 py-3" +
              (selectedInterest == "Printing"
                ? " bg-primary text-white border-primary"
                : " hover:bg-primary/50 bg-transparent text-customGray")
            }
          >
            Printing
          </button>
        </div>
        <div className="mt-10 flex flex-col gap-y-12">
          <input
            disabled={isLoading}
            name="name"
            placeholder="Your name"
            className="border-b-customGray border-b-3 focus:px-1 focus:border-b-primary transition-all ease-in-out duration-300 border-b-solid outline-none py-1 font-semibold w-full bg-transparent"
          />
          <input
            disabled={isLoading}
            name="email"
            type="email"
            placeholder="Your email"
            className="border-b-customGray border-b-3 focus:px-1 focus:border-b-primary transition-all ease-in-out duration-300 border-b-solid outline-none py-1 font-semibold w-full bg-transparent"
          />
          <input
            disabled={isLoading}
            name="phone"
            placeholder="Your phone number"
            className="border-b-customGray border-b-3 focus:px-1 focus:border-b-primary transition-all ease-in-out duration-300 border-b-solid outline-none py-1 font-semibold w-full bg-transparent"
          />
          <textarea
            disabled={isLoading}
            name="message"
            placeholder="Your message"
            rows={4}
            className="border-b-customGray border-b-3 focus:px-1 focus:border-b-primary transition-all ease-in-out duration-300 border-b-solid outline-none py-1 font-semibold w-full bg-transparent"
          />
        </div>
        <div className="grid grid-cols-2 mt-12 gap-x-8 sm:grid-cols-1 gap-y-4 ">
          <label
            htmlFor="file"
            className="bg-customGray cursor-pointer justify-center text-white font-medium flex gap-x-2 px-8 lg:px-2 py-4 rounded-lg"
          >
            <img src={upload} />
            {selectedFile ? "Uploaded" : "Upload a File"}
            <input
              type="file"
              id="file"
              className="hidden"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file.size > 5000000) {
                  dispatch(
                    setGlobalErrorMessage("File size must be less than 5MB")
                  );
                  setSelectedFile(null);
                  return;
                }
                setSelectedFile(file);
              }}
            />
          </label>
          <button
            disabled={isLoading}
            className="bg-primary justify-center text-white font-medium flex gap-x-2 px-8 lg:px-2 py-4 rounded-lg"
          >
            <img src={plane} />
            {isLoading
              ? "Sending..."
              : isSuccess
              ? "Sent Successfully"
              : isError
              ? "There was an error"
              : "Send Message"}{" "}
          </button>
        </div>
        <motion.div
          initial={{ height: 0, y: -10 }}
          animate={{
            height: isError || isSuccess ? "auto" : 0,
            y: isError || isSuccess ? 0 : -10,
          }}
          exit={{ height: "auto" }}
          transition={{ duration: 0.5 }}
          className="bg-white overflow-y-auto scrollbar-none rounded-md w-full text-sm text-center mt-4 text-primary font-semibold"
        >
          <div className="px-4 py-4 ">
            {isError &&
              "There was an error. Please try again later or contact us directly!"}
            {isSuccess &&
              "Message sent successfully. We will contact you soon!"}
          </div>
        </motion.div>
      </form>
    </div>
  );
};

export default Contact;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { api_url, media_url } from "../constants";
import plus from "../static/images/plus.svg";
import minus from "../static/images/minus.svg";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { changePage } from "../store/adminPageSlice";
import { useDispatch } from "react-redux";
import { setGlobalNotificationMessage } from "../store/errorMessageSlice";

const AdminBundle = () => {
  const [bundleName, setBundleName] = useState("");
  const [bundle, setBundle] = useState({});
  const [products, setProducts] = useState([]);
  const [bundleProducts, setBundleProducts] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  function ModifyBundle(e) {
    e.preventDefault();
    let data = {
      name: bundleName,
      products: bundleProducts.map((product) => product.id),
    };
    axios
      .put(`${api_url}/bundle/${id}/`, data)
      .then((res) => {
        dispatch(setGlobalNotificationMessage("Bundle updated successfully"));
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    dispatch(changePage("Edit Bundle"));
    axios
      .get(`${api_url}/bundle/${id}/`)
      .then((res) => {
        setBundle(res.data);
        setBundleName(res.data.name);
        setBundleProducts(res.data.products);
        axios.get(`${api_url}/products/`).then((res) => {
          setProducts(res.data);
        });
      })
      .catch((err) => console.log(err));
  }, []);

  function DeleteBundle() {
    axios
      .delete(`${api_url}/bundle/${id}/`)
      .then((res) => {
        dispatch(setGlobalNotificationMessage("Bundle deleted successfully"));
        navigate("/admin/bundles");
      })
      .catch((err) => console.log(err));
  }

  return (
    <form
      onSubmit={ModifyBundle}
      className="mt-8 px-24 pb-20  lg:px-8 md:px-8 sm:px-4 xl:px-12"
    >
      <div className="flex justify-between sm:flex-col-reverse gap-y-4">
        <input
          value={bundleName}
          onChange={(e) => setBundleName(e.target.value)}
          className="admin-input py-1 sm:w-full"
          placeholder="Bundle name"
        />
        <div className="flex gap-x-6 sm:flex-col gap-y-2 sm:w-full">
          <button
            type="button"
            onClick={DeleteBundle}
            className="bg-black text-white sm:w-full block px-8 py-1 w-fit font-medium !rounded-none"
          >
            Delete Bundle
          </button>
          <button className="primary-button  sm:w-full block px-8 py-1 w-fit font-medium !rounded-none">
            Apply Changes
          </button>
        </div>
      </div>
      <div className="flex justify-between mt-6 gap-x-8 gap-y-8 md:flex-col sm:flex-col">
        <div className="w-1/2 sm:w-full md:w-full">
          <h2 className="text-lg font-semibold">Bundle Products</h2>
          <div className=" gap-y-2 flex-col flex">
            {bundleProducts.length === 0 && (
              <div className="bg-black/20 py-3">
                <p className="text-center font-semibold">
                  No products added to bundle
                </p>
              </div>
            )}
            {bundleProducts.map((bundleProduct, index) => (
              <motion.div
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.99 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, delay: index * 0.05 }}
                className="overflow-y-hidden"
              >
                <div
                  onClick={() => {
                    let newBundleProducts = [...bundleProducts];
                    newBundleProducts.splice(index, 1);
                    setBundleProducts(newBundleProducts);
                  }}
                  className="group cursor-pointer grid grid-cols-9 items-center gap-x-4 bg-white p-2"
                >
                  <img
                    className="w-full"
                    src={media_url + bundleProduct.image.image}
                  />
                  <div className="col-span-7">
                    <p className="font-semibold">{bundleProduct.title}</p>
                  </div>
                  <div className="px-4">
                    <img
                      src={minus}
                      className="ml-auto w-6 group-hover:opacity-100 opacity-0 ease-in-out duration-300 transition-opacity"
                    />
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
        <div className="w-1/2 sm:w-full md:w-full">
          <h2 className="text-lg font-semibold">Products</h2>
          <div className=" gap-y-2 flex-col flex">
            {products.map((product, index) => (
              <motion.div
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.99 }}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  height: bundleProducts.find(
                    (bundleProduct) => bundleProduct.id === product.id
                  )
                    ? "0px"
                    : "auto",
                  display: bundleProducts.find(
                    (bundleProduct) => bundleProduct.id === product.id
                  )
                    ? "none"
                    : "block",
                }}
                transition={{
                  duration: 0.2,
                  delay: index * 0.05,
                  display: { delay: 0.2 },
                }}
                className="overflow-y-hidden"
              >
                <div
                  onClick={() => {
                    let index = bundleProducts.findIndex(
                      (bundleProduct) => bundleProduct.id === product.id
                    );
                    if (index === -1) {
                      setBundleProducts([...bundleProducts, product]);
                    } else {
                      let newBundleProducts = [...bundleProducts];
                      newBundleProducts.splice(index, 1);
                      setBundleProducts(newBundleProducts);
                    }
                  }}
                  className={
                    "group grid grid-cols-9 items-center gap-x-4 bg-white p-2 cursor-pointer"
                  }
                >
                  <img
                    className="w-full"
                    src={media_url + product.image.image}
                  />
                  <div className="col-span-7">
                    <p className="font-semibold">{product.title}</p>
                  </div>
                  <div className="px-4">
                    <img
                      src={plus}
                      className="ml-auto w-6 group-hover:opacity-100 opacity-0 ease-in-out duration-300 transition-opacity"
                    />
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </form>
  );
};

export default AdminBundle;

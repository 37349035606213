import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_url } from "../constants";
import ContactMessageCard from "../components/ContactMessageCard";
import { motion } from "framer-motion";
import { changePage } from "../store/adminPageSlice";
import { useDispatch } from "react-redux";
const AdminContacts = () => {
  const [readMessages, setReadMessages] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [showedReadMessages, setShowedReadMessages] = useState(10);
  const [showedUnreadMessages, setShowedUnreadMessages] = useState(10);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changePage("Contact messages"));
    axios
      .get(api_url + "/contacts/")
      .then((res) => {
        const messages = res.data;
        setReadMessages(messages.filter((message) => message.read != true));
        setUnreadMessages(messages.filter((message) => message.read != false));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="mt-8 px-24 pb-20 lg:px-8 md:px-8 sm:px-4 xl:px-12">
      <h2 className="font-semibold">Unread messages</h2>
      <div className="grid grid-cols-4 px-4 mt-1">
        <span>Name</span>
        <span>Email</span>
        <span>Phone</span>
        <span>Received at</span>
      </div>
      <motion.div className="flex flex-col gap-y-4">
        {readMessages.slice(0, showedReadMessages).map((message, index) => (
          <ContactMessageCard index={index} message={message} />
        ))}
        {showedReadMessages < readMessages.length && (
          <button
            onClick={() => setShowedReadMessages(showedReadMessages + 10)}
            className="bg-black/10 w-full py-4 text-black font-semibold"
          >
            Show More
          </button>
        )}
      </motion.div>
      <hr className="h-1 w-full bg-black my-8" />
      <h2 className="font-semibold">Read messages</h2>
      <div className="grid grid-cols-4 px-4 mt-1">
        <span>Name</span>
        <span>Email</span>
        <span>Phone</span>
        <span>Received at</span>
      </div>
      <div className="flex flex-col gap-y-4">
        {unreadMessages.slice(0, showedUnreadMessages).map((message, index) => (
          <ContactMessageCard message={message} index={index} />
        ))}
        {showedUnreadMessages < unreadMessages.length && (
          <button
            onClick={() => setShowedUnreadMessages(showedReadMessages + 10)}
            className="bg-black/10 w-full py-4 text-black font-semibold"
          >
            Show More
          </button>
        )}
      </div>
    </div>
  );
};

export default AdminContacts;

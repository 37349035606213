import React, { useState } from "react";
import { motion } from "framer-motion";
import edit_2 from "../static/images/edit_2.svg";
import trashcan from "../static/images/trashcan.svg";
import expand from "../static/images/expand.svg";
import check from "../static/images/check.svg";
import {
  setGlobalNotificationMessage,
  setGlobalErrorMessage,
} from "../store/errorMessageSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { api_url } from "../constants";
const AdminFAQCard = ({ faq, faqs, setFaqs }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [question, setQuestion] = useState(faq.question);
  const [answer, setAnswer] = useState(faq.answer);
  const dispatch = useDispatch();
  function SaveChanges() {
    axios
      .put(api_url + "/frequently-asked-question/" + faq.id + "/", {
        question,
        answer,
      })
      .then((res) => {
        dispatch(setGlobalNotificationMessage("FAQ Updated Successfully"));
        setIsEditing(false);
      })
      .catch((err) => {
        dispatch(setGlobalErrorMessage("Error Updating FAQ"));
        setIsEditing(false);
        setQuestion(faq.question);
        setAnswer(faq.answer);
      });
  }

  function DeleteFAQ() {
    axios
      .delete(api_url + "/frequently-asked-question/" + faq.id + "/")
      .then((res) => {
        dispatch(setGlobalNotificationMessage("FAQ Deleted Successfully"));
        setFaqs(faqs.filter((item) => item.id !== faq.id));
      })
      .catch((err) => {
        dispatch(setGlobalErrorMessage("Error Deleting FAQ"));
      });
  }

  return (
    <div className="bg-white p-4 rounded-md ">
      <div className="flex justify-between items-center">
        <div
          onClick={() => {
            !isEditing && setIsExpanded(!isExpanded);
          }}
          className="flex gap-x-3 flex-1 items-center cursor-pointer"
        >
          <motion.img
            initial={{ rotate: 0 }}
            animate={{ rotate: isExpanded ? 180 : 0 }}
            transition={{ duration: 0.2 }}
            whileHover={{ scale: 1.05 }}
            src={expand}
            className="w-4"
          />
          {isEditing ? (
            <input
              className="admin-input py-0.5 w-3/4"
              placeholder="Question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          ) : (
            <h2 className="font-semibold">{question}</h2>
          )}
        </div>
        <div className="flex gap-x-6">
          {!isEditing && (
            <motion.img
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              src={edit_2}
              alt="edit"
              className="cursor-pointer w-5"
              onClick={() => {
                setIsEditing(true);
                setIsExpanded(true);
              }}
            />
          )}
          {!isEditing && (
            <motion.img
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              src={trashcan}
              alt="delete"
              className="cursor-pointer w-5"
              onClick={DeleteFAQ}
            />
          )}
          {isEditing && (
            <motion.img
              src={check}
              className="w-5 cursor-pointer"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={SaveChanges}
            />
          )}
        </div>
      </div>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: isExpanded ? "auto" : 0,
          opacity: isExpanded ? 1 : 0,
        }}
        transition={{ duration: 0.2 }}
        className="overflow-hidden mt-2"
      >
        {isEditing ? (
          <textarea
            className="admin-input py-1 w-full min-h-[5rem]"
            placeholder="Answer"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          ></textarea>
        ) : (
          <div className="">
            {answer.length > 0 &&
              answer
                .split("\n")
                .map((item, index) => <p key={index}>{item}</p>)}
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default AdminFAQCard;

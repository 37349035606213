import React, { useEffect, useState } from "react";
import axios from "axios";
import { api_url } from "../constants";
import { AnimatePresence, motion } from "framer-motion";
import confirm from "../static/images/confirm.svg";
import failed from "../static/images/failed.svg";
import { ProgressBar } from "react-loader-spinner";
import LoadingSpinner from "./LoadingSpinner";
import { setGlobalErrorMessage } from "../store/errorMessageSlice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const NewsletterCard = () => {
  const dispatch = useDispatch();
  const [showEmail, setShowEmail] = useState(true);
  const [showName, setShowName] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    setShowEmail(true);
    setShowName(false);
    setShowConfirmation(false);
    setShowError(false);
    setEmail("");
    setName("");
    setIsLoading(false);
  }, [path]);

  function NewsletterSubscribe(e) {
    e.preventDefault();

    if (name.length === 0) {
      dispatch(setGlobalErrorMessage("Please enter your name."));
      return;
    }

    setIsLoading(true);
    setShowEmail(false);
    setShowName(false);

    axios
      .post(api_url + "/newsletter/subscribe/", {
        email: email,
        name: name,
      })
      .then((res) => {
        setShowConfirmation(true);
        setShowError(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setShowConfirmation(false);
        setShowError(true);
        setIsLoading(false);
        console.log(err);
      });
  }

  return (
    <div className="w-2/3 lg:w-full md:mr-auto md:ml-0 sm:w-full md:w-1/2 min-w-[25rem] sm:min-w-[12rem] ml-auto overflow-y-auto scrollbar-none flex flex-col gap-y-2 relative">
      <motion.h3
        initial={{ opacity: 1, y: 0 }}
        animate={{
          opacity: showConfirmation ? 0 : 1,
          y: showConfirmation ? -10 : 0,
        }}
        className="text-xl font-semibold flex flex-col"
      >
        Join our newsletter{" "}
        {isLoading && (
          <div className="z-50 mx-auto w-fit scale-75 -translate-y-1/3">
            <LoadingSpinner />
          </div>
        )}
      </motion.h3>
      <AnimatePresence mode="wait" initial={false}>
        <motion.form
          initial={{ opacity: 0, display: "none" }}
          animate={{
            opacity: showEmail ? 1 : 0,
            display: showEmail ? "flex" : "none",
          }}
          transition={{
            duration: 0.3,
            ease: "easeInOut",
            display: {
              delay: 0.3,
            },
          }}
          onSubmit={(e) => {
            e.preventDefault();
            const email = e.target.email.value;
            if (email.length === 0 || !email.includes("@")) {
              dispatch(
                setGlobalErrorMessage("Please enter a valid email address.")
              );
              return;
            }
            setShowName(true);
            setShowEmail(false);
            setEmail(e.target.email.value);
            //reset form
            e.target.email.value = "";
          }}
          className="rounded-xl w-full min-w-[12rem] relative  sm:flex-col gap-y-3"
        >
          <input
            name="email"
            type="email"
            placeholder="Email Address"
            className="bg-white w-full rounded-xl outline-none px-4 py-2"
          />

          <button
            className="primary-button rounded-xl px-8 text-sm font-semibold h-full
          absolute right-0 top-0 sm:static sm:py-3 sm:w-full"
          >
            {" "}
            Submit
          </button>
        </motion.form>
        <motion.form
          initial={{ opacity: 0, display: "none" }}
          animate={{
            opacity: showName ? 1 : 0,
            display: showName ? "flex" : "none",
          }}
          transition={{
            duration: 0.3,
            ease: "easeInOut",
            opacity: {
              delay: 0.3,
            },
          }}
          onSubmit={NewsletterSubscribe}
          className="rounded-xl w-full min-w-[12rem] relative sm:flex sm:flex-col gap-y-3"
        >
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="name"
            type="text"
            placeholder="Full Name"
            className="bg-white rounded-xl outline-none px-4 py-2 w-full"
          />

          <button
            type="submit"
            className="primary-button rounded-xl px-8 text-sm font-semibold h-full
          absolute right-0 top-0 sm:static sm:py-3 sm:w-full"
          >
            {" "}
            Submit
          </button>
        </motion.form>
        <motion.div
          initial={{ opacity: 0, display: "none" }}
          animate={{
            opacity: showConfirmation || showError ? 1 : 0,
            display: showConfirmation || showError ? "flex" : "none",
          }}
          transition={{
            duration: 0.3,
            ease: "easeInOut",
            opacity: {
              delay: 0.3,
            },
          }}
          onSubmit={NewsletterSubscribe}
          className="rounded-xl w-full sm:w-screen -translate-y-10 sm:-translate-y-0 min-w-[10rem] relative text-sm font-bold bg-white p-4 text-center  text-primary"
        >
          <img
            src={showError ? failed : confirm}
            className="w-8 mx-auto h-auto"
          />
          <p className="mt-2">
            {showError
              ? "There was an error subscribing to our newsletter."
              : "We sent you a confirmation email. Please check your inbox."}
          </p>
        </motion.div>
      </AnimatePresence>{" "}
    </div>
  );
};

export default NewsletterCard;

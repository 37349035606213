import React from "react";
import { Link } from "react-router-dom";

const WhatDoWeDo = ({ link, image, header, iconsAndText }) => {
  return (
    <div className="drop-shadow-lg relative flex  md:flex-col sm:flex-col">
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-white/80 -z-10 rounded-3xl"></div>
      <img
        src={image}
        className="w-1/3 lg:w-2/5 rounded-l-3xl md:w-full md:h-64 sm:w-full sm:h-64 sm:object-cover md:object-cover"
      />

      <div className="p-5 lg:w-3/5 lg:pb-12 md:pb-20 sm:pb-20 md:w-full sm:w-full">
        <h1 className="text-3xl font-bold">{header}</h1>
        <div className="flex flex-col gap-y-4 mt-4  w-2/3 lg:w-full md:w-full sm:w-full">
          {iconsAndText.map((iconAndText, index) => {
            return (
              <div key={index} className="flex gap-x-6 sm:flex-col">
                <img src={iconAndText.icon} className="w-14 sm:w-10" />
                <div className="">
                  <h3 className="text-lg font-semibold">
                    {iconAndText.header}
                  </h3>
                  <p>{iconAndText.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {link ? (
        <Link to={link}>
          <div className="absolute bottom-5 right-10 font-semibold">
            Browse Categories {">"}
          </div>
        </Link>
      ) : (
        <div className="absolute bottom-5 right-10 font-semibold">
          Browse Categories {">"} Coming Soon
        </div>
      )}
    </div>
  );
};

export default WhatDoWeDo;

import React, { useState, useEffect } from "react";
import expand from "../static/images/expand.svg";
import { motion } from "framer-motion";
import OptionTip from "./OptionTip";
import info from "../static/images/info.svg";
import useMediaQuery from "../hooks/useMediaQuery";

const DropdownOption = ({ option, selectedOptions, setSelectedOptions }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const isSM = useMediaQuery("(max-width: 640px)");
  useEffect(() => {
    if (selectedVariant == null) return;
    const index = selectedOptions.findIndex(
      (item) => item.question == option.question
    );
    if (index != -1) {
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions.splice(index, 1);
      setSelectedOptions([
        ...newSelectedOptions,
        {
          type: "dropdown_options",
          id: option.id,
          question: option.question,
          answer: selectedVariant.id,
          price_99: selectedVariant.price_99,
          price_499: selectedVariant.price_499,
          price_999: selectedVariant.price_999,
          price_4999: selectedVariant.price_4999,
          price_9999: selectedVariant.price_9999,
        },
      ]);
    } else {
      setSelectedOptions([
        ...selectedOptions,
        {
          type: "dropdown_options",
          id: option.id,
          question: option.question,
          answer: selectedVariant.id,
          price_99: selectedVariant.price_99,
          price_499: selectedVariant.price_499,
          price_999: selectedVariant.price_999,
          price_4999: selectedVariant.price_4999,
          price_9999: selectedVariant.price_9999,
        },
      ]);
    }
  }, [selectedVariant]);
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="flex flex-col gap-y-1 w-fit">
      <div className="flex gap-x-2 group">
        <div className="relative w-fit">
          <div className="rounded-full min-w-[10rem] px-3 cursor-pointer py-0.5 text-sm font-medium w-fit border-2 border-solid border-black bg-white">
            <span
              onClick={() => setIsExpanded(!isExpanded)}
              className="flex gap-x-2 w-full justify-between items-center"
            >
              {selectedVariant ? selectedVariant.text : option.question}
              <motion.img
                initial={{ rotate: 0 }}
                animate={isExpanded ? { rotate: 180 } : { rotate: 0 }}
                src={expand}
                className="w-4"
              />
            </span>
          </div>
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={
              isExpanded
                ? { height: "auto", opacity: 1 }
                : { height: 0, opacity: 0 }
            }
            className="mt-2 translate-y-full text-sm bg-white rounded-lg w-full py overflow-y-auto scrollbar-none absolute bottom-0"
          >
            <div className="py-1 px-2">
              {option.variants.map((variant) => (
                <div
                  className="py-1 cursor-pointer group-tip"
                  onClick={() => {
                    setSelectedVariant(variant);
                    setIsExpanded(false);
                  }}
                >
                  {variant.text}
                  <div className="h-[0.075rem] bg-primary w-0 transition-all ease-in-out duration-200 group-tip-hover:w-full"></div>
                </div>
              ))}
            </div>
          </motion.div>
        </div>

        <div
          className="w-fit flex gap-x-2 items-center sm:cursor-pointer"
          onClick={() => setExpanded(!expanded)}
        >
          {option.tip && option.tip.length > 0 && (
            <img src={info} className="w-4" />
          )}
          {!isSM && option.tip && option.tip.length > 0 && (
            <OptionTip expanded={expanded}>{option.tip}</OptionTip>
          )}
        </div>
      </div>
      {isSM && option.tip && option.tip.length > 0 && (
        <OptionTip expanded={expanded}>{option.tip}</OptionTip>
      )}
    </div>
  );
};

export default DropdownOption;

import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import showLoginReducer from "./showLoginSlice";
import adminPageReducer from "./adminPageSlice";
import cartReducer from "./cartSlice";
import userPageReducer from "./userPageSlice";
import errorMessageReducer from "./errorMessageSlice";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    cart: cartReducer,
    showLogin: showLoginReducer,
    adminPage: adminPageReducer,
    userPage: userPageReducer,
    errorMessage: errorMessageReducer,
  },
});

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refresh: localStorage.getItem("refresh"),
  access: localStorage.getItem("access"),
  isAuthenticated: null,
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      localStorage.setItem("refresh", action.payload.refresh);
      localStorage.setItem("access", action.payload.access);
      state.refresh = action.payload.refresh;
      state.access = action.payload.access;
      state.isAuthenticated = true;
    },
    loginFail(state) {
      localStorage.removeItem("refresh");
      localStorage.removeItem("access");
      state.refresh = null;
      state.access = null;
      state.isAuthenticated = false;
    },
    userLoaded(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    userSocialLoaded(state, action) {
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
      state.isAuthenticated = true;
    },
    userUpdated(state, action) {
      const user = state.user;
      user.first_name = action.payload.first_name;
      user.last_name = action.payload.last_name;
      return user;
    },
    userLoading(state) {
      state.isAuthenticated = false;
    },
    logout(state) {
      localStorage.removeItem("refresh");
      localStorage.removeItem("access");
      state.refresh = null;
      state.access = null;
      state.isAuthenticated = false;
      state.user = null;
      return state;
    },
  },
});

export const { loginSuccess, loginFail, userLoaded, userLoading, logout } =
  authSlice.actions;

export default authSlice.reducer;

import React, { useEffect, useState } from "react";
import OptionTip from "./OptionTip";
import info from "../static/images/info.svg";

const RadioButtonOption = ({ option, selectedOptions, setSelectedOptions }) => {
  const [selectedVariant, setSelectedVariant] = useState(option.variants[0]);

  useEffect(() => {
    const index = selectedOptions.findIndex(
      (item) => item.question == option.question
    );
    if (index != -1) {
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions.splice(index, 1);
      setSelectedOptions([
        ...newSelectedOptions,
        {
          type: "radio_buttons_options",
          id: option.id,
          question: option.question,
          answer: selectedVariant.id,
          price_99: selectedVariant.price_99,
          price_499: selectedVariant.price_499,
          price_999: selectedVariant.price_999,
          price_4999: selectedVariant.price_4999,
          price_9999: selectedVariant.price_9999,
        },
      ]);
    } else {
      setSelectedOptions([
        ...selectedOptions,
        {
          type: "radio_buttons_options",
          id: option.id,
          question: option.question,
          answer: selectedVariant.id,
          price_99: selectedVariant.price_99,
          price_499: selectedVariant.price_499,
          price_999: selectedVariant.price_999,
          price_4999: selectedVariant.price_4999,
          price_9999: selectedVariant.price_9999,
        },
      ]);
    }
  }, [selectedVariant]);
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <h3 className="text-lg font-bold flex group sm:flex-col gap-y-2">
        <span className="flex items-center gap-x-2 sm:flex-col sm:items-start">
          <div
            onClick={() => setExpanded(!expanded)}
            className="flex items-center gap-x-2 sm:cursor-pointer"
          >
            {option.tip && option.tip.length > 0 && (
              <img src={info} className="3" />
            )}
            {option.question}
          </div>
          {option.tip && option.tip.length > 0 && (
            <OptionTip expanded={expanded}>{option.tip}</OptionTip>
          )}
        </span>
      </h3>
      <div className="flex gap-x-8 flex-wrap mt-1">
        {option.variants.map((variant) => (
          <div
            onClick={() => setSelectedVariant(variant)}
            className="flex gap-x-2 items-center cursor-pointer"
          >
            <div className="h-5 w-5 p-0.5 border-solid border-black border-2 rounded-full">
              {selectedVariant.id == variant.id && (
                <div className="w-full h-full bg-black rounded-full"></div>
              )}
            </div>
            <div className="text-sm font-medium">{variant.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioButtonOption;

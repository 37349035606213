import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_url, media_url } from "../constants";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const UserOrderItemCard = ({ item }) => {
  const [photo, setPhoto] = useState({});
  const [price, setPrice] = useState(0);

  useEffect(() => {
    let item_price = item.product.starting_price;
    item.options.map((option) => {
      item_price += option.price;
    });
    setPrice(item_price);
    axios
      .get(`${api_url}/product/${item.product.id}/photos`)
      .then((res) => {
        setPhoto(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="grid grid-cols-12 sm:flex-col sm:flex gap-y-4 gap-x-6">
      <img
        src={media_url + photo.image}
        className="w-full sm:w-1/2 sm:min-w-[10rem] sm:mx-auto col-span-2"
      />
      <div className="flex flex-col col-span-8 pt-2">
        <Link to={"/product/" + item.product.slug}>
          <h3 className="font-bold text-black/80 text-xl">
            {item.product.title}
          </h3>
        </Link>
        <div className="flex text-sm flex-col mt-2 gap-y-1">
          {item.options.map((option) => (
            <div className="flex">
              <div className="">{option.question}</div>
              <div className="ml-2 font-semibold">{option.answer}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="col-span-2 ml-auto my-auto">
        <div className="flex flex-col text-center">
          <div className="text-sm">Price:</div>
          <div className="font-semibold -translate-y-1.5">
            {item.price.toFixed(2)} €
          </div>
        </div>
        <div className="flex flex-col text-center">
          <div className="text-sm">Quantity:</div>
          <div className="font-semibold -translate-y-1.5">{item.quantity}</div>
        </div>
      </div>
    </div>
  );
};

export default UserOrderItemCard;

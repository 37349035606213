import React, { useState, useEffect } from "react";
import OptionTip from "./OptionTip";
import info from "../static/images/info.svg";

const TextFieldOption = ({ option, selectedOptions, setSelectedOptions }) => {
  const [text, setText] = useState("");

  useEffect(() => {
    const index = selectedOptions.findIndex(
      (item) => item.question == option.question
    );
    if (index != -1) {
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions.splice(index, 1);
      setSelectedOptions([
        ...newSelectedOptions,
        {
          type: "text_field_options",
          id: option.id,
          question: option.question,
          answer: text,
          price: 0,
          price_per_unit: 0,
        },
      ]);
    } else {
      setSelectedOptions([
        ...selectedOptions,
        {
          type: "text_field_options",
          id: option.id,
          question: option.question,
          answer: text,
          price: 0,
          price_per_unit: 0,
        },
      ]);
    }
  }, [text]);

  return (
    <div>
      <h3 className="text-lg font-bold flex group">
        <span className="flex items-center gap-x-2">
          {option.tip && option.tip.length > 0 && (
            <img src={info} className="3" />
          )}{" "}
          {option.question}
          {option.tip && option.tip.length > 0 && (
            <OptionTip>{option.tip}</OptionTip>
          )}
        </span>
      </h3>
      <input
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="user-input bg-white border-black rounded-full"
        placeholder="Type your answer here..."
      />
    </div>
  );
};

export default TextFieldOption;

import React, { useEffect, useState } from "react";
import convertPythonDateTimetoDateTime from "../utils/ConvertPythonDateTimeToDateTime";
import { motion } from "framer-motion";
import axios from "axios";
import expand from "../static/images/expand.svg";
import { api_url } from "../constants";
const ContactMessageCard = ({ index, message }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isExpanded && !message.read) {
      axios
        .put(api_url + "/contact/" + message.id + "/", {
          read: true,
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isExpanded]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 10, height: 0 }}
      animate={{ opacity: 1, y: 0, height: "auto" }}
      transition={{ duration: 0.2, delay: (index % 10) * 0.05 }}
    >
      <div className="grid grid-cols-4 px-4 py-4 bg-white">
        <span>{message.name}</span>
        <span>{message.email}</span>
        <span>{message.phone}</span>
        <span className="flex justify-between items-center">
          {convertPythonDateTimetoDateTime(message.created_at)}
          <motion.img
            onClick={() => setIsExpanded(!isExpanded)}
            animate={{ rotate: isExpanded ? 180 : 0 }}
            transition={{ duration: 0.2 }}
            src={expand}
            className="w-5 h-5 cursor-pointer"
          />
        </span>
      </div>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: isExpanded ? "auto" : 0,
          opacity: isExpanded ? 1 : 0,
        }}
        transition={{ duration: 0.2 }}
        className="overflow-hidden"
      >
        {message.message &&
          message.message
            .split("\n")
            .map((line) => <div className="px-4 py-1 bg-gray-100">{line}</div>)}
      </motion.div>
    </motion.div>
  );
};

export default ContactMessageCard;

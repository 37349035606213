import React, { useEffect, useState } from "react";
import axios from "axios";
import { api_url, media_url } from "../constants";
import trashcan from "../static/images/trashcan.svg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { decrement } from "../store/cartSlice";
import useMediaQuery from "../hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
const CartItem = ({ item, items, setItems, disabled = false }) => {
  const [photo, setPhoto] = useState({});
  const auth = useSelector((state) => state.auth);
  const isSM = useMediaQuery("(max-width: 640px)");
  useEffect(() => {
    console.log(item);
    axios
      .get(`${api_url}/product/${item.product.id}/photos`)
      .then((res) => {
        setPhoto(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const dispatch = useDispatch();
  function DeleteCartItem() {
    axios
      .delete(`${api_url}/user/cart/item/${item.id}/`, {
        params: {
          token: auth.access,
        },
      })
      .then((res) => {
        console.log(res.data);
        dispatch(decrement());
        setItems(items.filter((i) => i.id != item.id));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const quantity_and_price = (
    <div className="flex col-span-2 ml-auto sm:mr-auto flex-col sm:text-left items-center sm:w-full  gap-y-4 sm:gap-y-2 sm:flex sm:flex-wrap sm:flex-row-reverse sm:justify-between sm:items-center pt-4">
      {!disabled && (
        <div
          onClick={DeleteCartItem}
          className="w-10 p-2 bg-gray-200 h-fit hover:scale-105 ease-in-out duration-200 transition-transform cursor-pointer rounded-full"
        >
          <img src={trashcan} className=" " />
        </div>
      )}
      <div className="flex flex-col text-center sm:flex-row gap-x-2 flex-wrap">
        <div className="text-sm">Quantity {isSM && ": "}</div>
        <div className="font-semibold -translate-y-1.5">{item.quantity}</div>
      </div>
      <div className="flex flex-col text-center sm:flex-row gap-x-2 flex-wrap">
        <div className="text-sm">Price {isSM && ": "}</div>

        <div className="font-semibold -translate-y-1.5">
          {item.price.toFixed(2)} €
        </div>
      </div>
    </div>
  );

  const options = (
    <div className="flex text-sm flex-col mt-2 gap-y-1">
      {item.options.map((option) => (
        <div className="flex">
          <div className="">{option.question}</div>
          <div className="ml-2 font-semibold">{option.answer}</div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="grid grid-cols-12 gap-x-6 sm:flex-col sm:flex flex-wrap">
      <img
        src={media_url + photo.image}
        className="w-full h-min col-span-3 sm:w-full"
      />
      <div className="col-span-7 pt-4 sm:w-full sm:text-left sm:flex sm:flex-col">
        <Link to={"/product/" + item.product.slug}>
          <h3 className="font-bold text-black/80 text-xl text-left">
            {item.product.title}
          </h3>
        </Link>
        {!isSM ? options : quantity_and_price}
      </div>
      {!isSM ? quantity_and_price : options}
    </div>
  );
};

export default CartItem;

import React, { useState } from "react";
import trashcan_white from "../static/images/trashcan-white.svg";
import axios from "axios";
import { api_url } from "../constants";

const AdminToggleButtonCard = ({
  option,
  index,
  DeleteOption,
  fetchOptions,
  onDragStart,
  onDragEnter,
  onDragEnd,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  function SaveChanges(e) {
    e.preventDefault();
    const question = e.target["toggle-text-" + index].value;
    const tip = e.target["toggle-tip-" + index].value;
    const price_499 = e.target["toggle-price-499-" + index].value;
    const price_999 = e.target["toggle-price-999-" + index].value;
    const price_4999 = e.target["toggle-price-4999-" + index].value;
    const price_9999 = e.target["toggle-price-9999-" + index].value;
    const weight = e.target["toggle-weight-" + index].value;
    const formData = new FormData();
    formData.append("text", question);
    formData.append("tip", tip);
    formData.append("price_499", price_499);
    formData.append("price_999", price_999);
    formData.append("price_4999", price_4999);
    formData.append("price_9999", price_9999);
    formData.append("weight", weight);
    formData.append("type", "toggle_button");
    axios
      .put(
        api_url + "/product/" + option.product + "/option/" + option.id + "/",
        formData
      )
      .then((res) => {
        setIsEditing(false);
        fetchOptions();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function CancelEditing() {
    setIsEditing(false);
    fetchOptions();
  }

  return (
    <form
      draggable={!isEditing}
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
      onDragOver={(e) => e.preventDefault()}
      onSubmit={SaveChanges}
      className="bg-white rounded-lg p-4 drop-shadow-md"
    >
      <div className="flex justify-between items-center gap-y-2 sm:flex-col-reverse sm:items-start">
        <h3 className="font-semibold">Option {index + 1} (Toggle Button):</h3>
        <div className="flex gap-x-4 sm:ml-auto sm:w-fit">
          {isEditing ? (
            <div
              type="button"
              onClick={CancelEditing}
              className="h-fit block px-6 bg-black text-white font-semibold cursor-pointer"
            >
              CANCEL
            </div>
          ) : (
            <div
              type="button"
              onClick={() => DeleteOption(option.id, "toggle_button_options")}
              className="h-fit block px-6 bg-black text-white font-semibold cursor-pointer"
            >
              DELETE
            </div>
          )}
          {!isEditing ? (
            <div
              type="button"
              onClick={() => setIsEditing(true)}
              className="primary-button font-semibold rounded-none px-6 cursor-pointer"
            >
              EDIT
            </div>
          ) : (
            <button
              type="submit"
              className="primary-button font-semibold rounded-none px-6"
            >
              SAVE CHANGES
            </button>
          )}
        </div>
      </div>
      {isEditing ? (
        <div className="w-fit flex flex-col gap-y-2">
          <input
            name={"toggle-text-" + index}
            className="admin-input"
            placeholder="Toggle Text"
            defaultValue={option.question}
          />
          <input
            name={"toggle-tip-" + index}
            className="admin-input"
            placeholder="Toggle Tip"
            defaultValue={option.tip}
          />

          <input
            type="number"
            defaultValue={option.price_499}
            name={"toggle-price-499-" + index}
            placeholder="price 100-499 units if ON"
            className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
            step="0.01"
          />
          <input
            type="number"
            defaultValue={option.price_999}
            name={"toggle-price-999-" + index}
            placeholder="price 500-999 units if ON"
            className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
            step="0.01"
          />
          <input
            type="number"
            defaultValue={option.price_4999}
            name={"toggle-price-4999-" + index}
            placeholder="price 1000-4999 units if ON"
            className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
            step="0.01"
          />
          <input
            type="number"
            defaultValue={option.price_9999}
            name={"toggle-price-9999-" + index}
            placeholder="price 5000-9999 units if ON"
            className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
            step="0.01"
          />
          <input
            defaultValue={option.weight}
            type="number"
            name={"toggle-weight-" + index}
            placeholder="+weight if ON"
            className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
            step="0.001"
          />
        </div>
      ) : (
        <div>
          <h4>
            <span className="font-medium">Question:</span> {option.question}
          </h4>
          <p>
            <span className="font-medium">Tip:</span> {option.tip}
          </p>
          <p>
            <span className="font-medium">Price 100-499 units if ON: </span>
            {option.price_499} $
          </p>
          <p>
            <span className="font-medium">Price 500-999 units if ON: </span>
            {option.price_999} $
          </p>
          <p>
            <span className="font-medium">Price 1000-4999 units if ON: </span>
            {option.price_4999} $
          </p>
          <p>
            <span className="font-medium">Price 5000-9999 units if ON: </span>
            {option.price_9999} $
          </p>
          <p>
            <span className="font-medium">Weight if ON: </span>
            {option.weight} kg
          </p>
        </div>
      )}
    </form>
  );
};

export default AdminToggleButtonCard;

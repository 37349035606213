import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { api_url, media_url } from "../constants";
const ProductCard = ({ product, index, sm = false }) => {
  return (
    <Link to={"/product/" + product.slug}>
      <motion.div
        whileHover={{ scale: 1.02 }}
        initial={{ opacity: 0, y: "5%" }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.2,
          ease: "easeInOut",
          delay: 0.1 * index,
        }}
        className="relative bg-white  rounded-2xl drop-shadow-hard"
      >
        <img
          className="w-full h-full rounded-t-lg "
          src={media_url + product.thumbnail}
        />
        <div className="p-4 bg-white rounded-b-2xl w-full">
          <h3 className="font-semibold ">{product.title}</h3>
          <p className={"mt-1 " + (sm == true ? "text-xs" : "text-sm")}>
            {product.description.length > 100
              ? product.description.substring(0, 100) + "..."
              : product.description}
          </p>
          <div
            className={
              "w-fit ml-auto font-light mt-4 " +
              (sm == true ? "text-sm" : "text-lg")
            }
          >
            As low as {product.price_9999.toFixed(2)} €
          </div>
        </div>
      </motion.div>
    </Link>
  );
};

export default ProductCard;

import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import logo_light from "../static/images/logo-light.png";
import box from "../static/images/box.svg";
import categories from "../static/images/categories.svg";
import products from "../static/images/products.svg";
import orders from "../static/images/orders.svg";
import statistics from "../static/images/statistics.svg";
import newsletter from "../static/images/newsletter.svg";
import settings from "../static/images/settings.svg";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import bundle from "../static/images/bundle.svg";
import countries from "../static/images/countries.svg";
import seo from "../static/images/seo.svg";
import faq from "../static/images/faq.svg";
import burger_white from "../static/images/burger-white.svg";
import { motion } from "framer-motion";
import useMediaQuery from "../hooks/useMediaQuery";
import axios from "axios";
import { api_url } from "../constants";
import exit from "../static/images/exit.svg";
const AdminLayout = () => {
  const isSM = useMediaQuery("(max-width: 640px)");
  const isMD = useMediaQuery("(max-width: 1023px)");
  const location = useLocation();
  const navigate = useNavigate();
  const pageName = useSelector((state) => state.adminPage);
  const [accessGranted, setAccessGranted] = useState(false);

  useEffect(() => {
    if (location.pathname === "/admin") {
      navigate("/admin/services");
    }
    document.title = "Admin Panel";
  }, []);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function fetchAccess(code) {
    axios
      .post(`${api_url}/verify/`, { code })
      .then((res) => {
        setAccessGranted(true);
        localStorage.setItem("code", code);
      })
      .catch((e) => {
        setAccessGranted(false);
        localStorage.removeItem("code");
      });
  }

  useEffect(() => {
    const code = localStorage.getItem("code");
    if (code) {
      fetchAccess(code);
    }
  }, []);

  return !accessGranted ? (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        fetchAccess(e.target.code.value);
      }}
      className="flex flex-col w-[20rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
    >
      <input
        type="text"
        placeholder="Code"
        className="admin-input"
        name="code"
      />
      <button className="admin-button py-2 px-4 mt-4 font-semibold">
        {" "}
        Submit access
      </button>
    </form>
  ) : (
    <div className="flex">
      <div
        className={
          "w-56 md:w-64 relative sm:fixed sm:w-full py-8 min-h-[100vh] bg-secondary transition-transform ease-in-out duration-300 md:fixed  z-50 " +
          (isMenuOpen
            ? "md:translate-x-0 sm:translate-x-0"
            : "md:-translate-x-full sm:-translate-x-full")
        }
      >
        {isMD && (
          <img
            src={burger_white}
            className="w-5 absolute top-4 right-4 cursor-pointer"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
        )}
        <img className="w-36 mx-auto" src={logo_light} />
        <ul className="text-white flex flex-col mt-6 text-lg">
          <Link
            onClick={() => isMD && setIsMenuOpen(false)}
            to={"/admin/services"}
            className={
              "w-fit py-2 px-4  flex items-center gap-x-3" +
              (pageName === "services" ? " font-semibold" : " font-medium")
            }
          >
            <img src={box} className="w-5" />
            Services
          </Link>
          <Link
            onClick={() => isMD && setIsMenuOpen(false)}
            to={"/admin/categories"}
            className={
              "w-fit py-2 px-4 font-medium flex items-center gap-x-3 " +
              (pageName === "categories" ? "font-semibold" : "font-medium")
            }
          >
            <img src={categories} className="w-5" />
            Categories
          </Link>
          <Link
            onClick={() => isMD && setIsMenuOpen(false)}
            to={"/admin/products"}
            className={
              "w-fit py-2 px-4  flex items-center gap-x-3" +
              (pageName === "products" ? " font-semibold" : " font-medium")
            }
          >
            <img src={products} className="w-5" />
            Products
          </Link>
          <Link
            onClick={() => isMD && setIsMenuOpen(false)}
            to="/admin/orders"
            className="w-fit py-2 px-4 font-medium flex items-center gap-x-3"
          >
            <img src={orders} className="w-5" />
            Orders
          </Link>
          <Link
            onClick={() => isMD && setIsMenuOpen(false)}
            to={"/admin/statistics"}
            className="w-fit py-2 px-4 font-medium flex items-center gap-x-3"
          >
            <img src={statistics} className="w-5" />
            Statistics
          </Link>
          <Link
            onClick={() => isMD && setIsMenuOpen(false)}
            to={"/admin/newsletter"}
            className="w-fit py-2 px-4 font-medium flex items-center gap-x-3"
          >
            <img src={newsletter} className="w-5" />
            Newsletter
          </Link>
          <Link
            onClick={() => isMD && setIsMenuOpen(false)}
            to={"/admin/shipping"}
            className="w-fit py-2 px-4 font-medium flex items-center gap-x-3"
          >
            <img src={countries} className="w-5" />
            Shipping
          </Link>
          <Link
            onClick={() => isMD && setIsMenuOpen(false)}
            to={"/admin/bundles"}
            className="w-fit py-2 px-4 font-medium flex items-center gap-x-3"
          >
            <img src={bundle} className="w-5" />
            Bundles
          </Link>
          <Link
            onClick={() => isMD && setIsMenuOpen(false)}
            to={"/admin/seo"}
            className="w-fit py-2 px-4 font-medium flex items-center gap-x-3"
          >
            <img src={seo} className="w-5" />
            SEO Tools
          </Link>
          <Link
            onClick={() => isMD && setIsMenuOpen(false)}
            to={"/admin/faq"}
            className="w-fit py-2 px-4 font-medium flex items-center gap-x-3"
          >
            <img src={faq} className="w-5" />
            FAQ settings
          </Link>
        </ul>
      </div>
      <div className="w-[calc(100%-12rem)] md:w-full sm:w-full relative">
        <div className="w-full h-10 bg-primary flex items-center px-6 text-white relative uppercase font-semibold text-lg">
          {isMD && (
            <img
              src={burger_white}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="mr-4 cursor-pointer w-4"
            />
          )}
          {pageName}
          <img
            src={exit}
            onClick={() => {
              localStorage.removeItem("code");
              setAccessGranted(false);
            }}
            className="w-5 absolute top-1/2 right-6 -translate-y-1/2 cursor-pointer"
          />
        </div>
        <Outlet />
        {isMenuOpen && isMD && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            onClick={() => setIsMenuOpen(false)}
            className="fixed bottom-0 top-0 left-0 right-0 bg-black/20 z-10"
          ></motion.div>
        )}
      </div>
    </div>
  );
};

export default AdminLayout;

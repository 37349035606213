import React from "react";
import home_header from "../static/images/home-header.jpg";
import presenting_packaging from "../static/images/presenting-packaging.png";
import presenting_design from "../static/images/presenting-design.png";
import presenting_printing from "../static/images/presenting-printing.png";
import HomeFeature from "../components/HomeFeature";
import leaves from "../static/images/leaves.svg";
import quality from "../static/images/quality.svg";
import process from "../static/images/process.svg";
import bulb from "../static/images/bulb.svg";

import WhyChoose from "../components/WhyChoose";
import WhatDoWeDo from "../components/WhatDoWeDo";
import { Helmet } from "react-helmet-async";
import { what_do_we_do } from "../constants";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const Home = () => {
  const userPage = useSelector((state) => state.userPage);

  return (
    <div className="">
      <Helmet>
        <title>{userPage.title}</title>
        <meta name="description" content={userPage.description} />
        <meta name="keywords" content={userPage.keywords} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Apakz" />
        <link rel="canonical" href={"https://apakz.com" + userPage.slug} />
      </Helmet>
      <div className="mt-16 flex gap-x-40 px-52 lg:gap-x-20 sm:mt-8 gap-y-10 md:flex-col-reverse md:mt-8 sm:flex-col-reverse xl:px-40 lg:px-12 md:px-6 sm:px-6 w-full">
        <div className="w-1/2 md:w-full sm:w-full">
          <h1 className="text-5xl font-bold sm:text-4xl">
            Everything your business needs to stand out
          </h1>
          <p className="mt-6">
            We take pride in offering a comprehensive range of services to meet
            all your packaging, printing and design needs. As a one-stop
            solution, we are committed to providing exceptional quality,
            innovative designs, and reliable services to elevate your brand to
            new heights.
          </p>
          <div className="mt-8 flex gap-x-4 items-center sm:items-start sm:flex-col gap-y-2 sm:mr-auto w-fit">
            <Link to={"/contact"}>
              <button className="primary-button px-8 py-2 font-semibold">
                Contact Us
              </button>
            </Link>
            <p className="font-bold">or Explore Our Services Below:</p>
          </div>
        </div>
        <div className="w-1/2 md:w-full sm:w-full">
          <img src={home_header} className="w-full rounded-xl drop-shadow-sm" />
        </div>
      </div>
      <div className="px-52 grid grid-cols-3 mt-12 gap-x-6 xl:px-40 lg:px-12 md:px-6 sm:px-6 md:grid-cols-2 gap-y-6 sm:grid-cols-1 sm:mt-6 md:mt-8">
        <HomeFeature
          link={"/service/packaging"}
          image={presenting_packaging}
          header={"Packaging"}
          paragraph={"Elevate Your Brand with Stunning Custom Paper Boxes"}
          index={0}
        />
        <HomeFeature
          link={"/service/design"}
          image={presenting_design}
          header={"Design"}
          paragraph={
            "Our designers create impactful visuals that reflect your brand's identity"
          }
          index={1}
        />
        <HomeFeature
          link={"/service/printing"}
          image={presenting_printing}
          header={"Printing"}
          paragraph={
            "Exceptional printing quality, from business cards & flyers to books & magazines"
          }
          index={2}
        />
      </div>
      <h2 className="text-center mix-blend-multiply bg-primary py-2 text-xl text-white font-semibold my-12  w-full">
        Why choose us?
      </h2>
      <div className="px-52 grid grid-cols-4 xl:px-40 lg:px-12 md:px-6 sm:px-6 md:grid-cols-2 sm:grid-cols-1">
        <WhyChoose
          image={bulb}
          header={"Creativity & Innovation"}
          paragraph={
            "We stay ahead of industry trends to offer you cutting-edge, impactful packaging solutions."
          }
        />
        <WhyChoose
          image={process}
          header={"Personalized Approach"}
          paragraph={
            "We believe in personalized service, closely aligning with your brand vision for tailored solutions."
          }
        />
        <WhyChoose
          image={quality}
          header={"Quality Assurance"}
          paragraph={
            "Quality first. We thoroughly inspect every product to meet our high standards."
          }
        />
        <WhyChoose
          image={leaves}
          header={"Sustainability"}
          paragraph={
            "We care about the environment, offering eco-friendly packaging  for you. Join us in our commitment!"
          }
          last={true}
        />
      </div>
      <h2 className="text-center mix-blend-multiply bg-primary py-2 text-xl text-white font-semibold my-12  w-full">
        What do we do?
      </h2>
      <div className="flex flex-col px-52 gap-y-12 xl:px-40 lg:px-12 md:px-6 sm:px-6">
        {what_do_we_do.map((whatDoWeDo, index) => {
          return (
            <WhatDoWeDo
              link={whatDoWeDo.link ? whatDoWeDo.link : null}
              key={index}
              image={whatDoWeDo.image}
              header={whatDoWeDo.header}
              iconsAndText={whatDoWeDo.iconsAndText}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Home;

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import x from "../static/images/x.svg";
import google from "../static/images/google.svg";
import axios from "axios";
import { auth_url, api_url } from "../constants";
import confirm from "../static/images/confirm.svg";
import { useSelector, useDispatch } from "react-redux";
import { loginSuccess } from "../store/authSlice";
import { show } from "../store/showLoginSlice";
import { userLoaded } from "../store/authSlice";

const LoginForm = () => {
  const showLogin = useSelector((state) => state.showLogin);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [showUserRegister, setShowUserRegister] = useState(false);
  const [registeredSuccessfully, setRegisteredSuccessfully] = useState(false);
  const [showPasswordForgotten, setShowPasswordForgotten] = useState(false);
  const [resetSentSuccessfully, setResetSentSuccessfully] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflow = showLogin ? "hidden" : "auto";
  }, [showLogin]);

  function RegisterUser(e) {
    e.preventDefault();
    const form = e.target;
    const data = {
      name: form.name.value,
      phone: form.phone.value,
      email: form.email.value,
      password: form.password.value,
      re_password: form.re_password.value,
    };
    axios
      .post(`${auth_url}/users/`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        axios
          .post(api_url + "/user-data/", {
            username: form.name.value,
            phone: form.phone.value,
            email: form.email.value,
            password: form.password.value,
          })
          .then((res) => {
            setRegisteredSuccessfully(true);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function LoginUser(e) {
    e.preventDefault();
    const form = e.target;
    const data = {
      email: form.email.value,
      password: form.password.value,
    };
    axios
      .post(`${auth_url}/jwt/create/`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch(loginSuccess(res.data));
        localStorage.setItem("refresh", res.data.refresh);
        localStorage.setItem("access", res.data.access);
        dispatch(show(false));
        axios
          .get(api_url + "/user/", {
            params: {
              token: res.data.access,
            },
          })
          .then((res) => {
            dispatch(userLoaded(res.data));
            navigate("/account");
          })
          .catch((err) => {});
      })
      .catch((err) => {
        setErrorMessage("Email or password is incorrect");

        console.log(err);
      });
  }

  function CloseLogin() {
    dispatch(show(false));
    const timout = setTimeout(() => {
      setShowUserRegister(false);
      setRegisteredSuccessfully(false);
      setShowPasswordForgotten(false);
      setResetSentSuccessfully(false);
      clearTimeout(timout);
    }, 200);
  }

  function ResetPassword(e) {
    e.preventDefault();
    const form = e.target;
    const data = {
      email: form.email.value,
    };
    axios
      .post(`${auth_url}/users/reset_password/`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setResetSentSuccessfully(true);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function GoogleLogin() {
    axios
      .get(
        `${auth_url}/o/google-oauth2/?redirect_uri=${window.location.origin}`
      )
      .then((res) => {
        console.log(res);
        window.location.replace(res.data.authorization_url);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <motion.div
        initial={{ opacity: 0, display: "none" }}
        animate={{
          opacity: showLogin ? 1 : 0,
          display: showLogin ? "block" : "none",
        }}
        onClick={CloseLogin}
        transition={{
          duration: 0.2,
          ease: "easeInOut",
          display: { delay: showLogin ? 0 : 0.2 },
        }}
        className="fixed z-10 top-0 left-0 right-0 bottom-0 bg-black/25"
      ></motion.div>
      <motion.div
        initial={{ opacity: 0, y: "-50%%", x: "-50%", display: "none" }}
        animate={{
          opacity: showLogin ? 1 : 0,
          y: showLogin ? "-52%" : "-50%",
          display: showLogin ? "block" : "none",
        }}
        transition={{
          duration: 0.2,
          ease: "easeInOut",
          display: { delay: showLogin ? 0 : 0.2 },
        }}
        className="fixed z-20 w-1/3 min-w-[20rem] lg:w-2/5 md:w-[60%] sm:w-screen sm:h-screen top-1/2 bg-white rounded-md py-4 px-12 left-1/2"
      >
        <img
          onClick={CloseLogin}
          className="absolute cursor-pointer top-5 right-5 w-7 p-2 opacity-75 bg-black/20 rounded-full"
          src={x}
        />
        {resetSentSuccessfully || registeredSuccessfully ? (
          <div className="py-10 px-4">
            <img src={confirm} className="w-20 mx-auto mt-4" />
            <p className="text-sm text-center font-medium mt-4">
              {registeredSuccessfully
                ? "Thank you for registering with us. Please check your email to confirm your account."
                : resetSentSuccessfully
                ? "We have sent you an email with a link to reset your password. Please check your inbox."
                : ""}
            </p>
          </div>
        ) : (
          <div className="flex-col flex">
            <h2 className="text-3xl font-bold mt-12">
              {showPasswordForgotten
                ? "Password Reset"
                : showUserRegister
                ? "Sign Up"
                : "Sign in"}
            </h2>
            <motion.p
              initial={{ opacity: 0, y: "1rem" }}
              animate={{
                opacity: errorMessage.length > 0 ? 1 : 0,
                y: errorMessage.length > 0 ? "0" : "1rem",
              }}
              transition={{
                duration: 0.3,
                ease: "easeInOut",
              }}
              className="text-sm text-danger mt-4"
            >
              {errorMessage}
            </motion.p>
            {showPasswordForgotten ? (
              <form className="mt-4 flex flex-col" onSubmit={ResetPassword}>
                <input
                  className="user-input"
                  placeholder="Email"
                  type="email"
                  name="email"
                />
                <button className="primary-button py-2 mt-6 font-semibold px-16 rounded-lg mx-auto w-fit">
                  Reset my password
                </button>
              </form>
            ) : showUserRegister ? (
              <form onSubmit={RegisterUser} className="mt-4 flex flex-col">
                <input
                  className="user-input"
                  placeholder="Full Name"
                  name="name"
                />
                <input
                  className="user-input mt-4"
                  placeholder="Email"
                  type="email"
                  name="email"
                />
                <input
                  className="user-input mt-4"
                  placeholder="Phone Number"
                  type="tel"
                  name="phone"
                />
                <input
                  className="user-input mt-4"
                  placeholder="Password"
                  type="password"
                  name="password"
                />
                <input
                  className="user-input mt-4"
                  placeholder="Confirm Password"
                  type="password"
                  name="re_password"
                />
                <button className="primary-button py-2 mt-6 font-semibold px-16 rounded-lg mx-auto w-fit">
                  Sign me up
                </button>
              </form>
            ) : (
              <form onSubmit={LoginUser} className="mt-4 flex flex-col">
                <input
                  className="user-input"
                  placeholder="Email"
                  type="email"
                  name="email"
                />
                <input
                  className="user-input mt-4"
                  placeholder="Password"
                  type="password"
                  name="password"
                />
                <Link
                  onClick={() => {
                    setShowPasswordForgotten(true);
                    setErrorMessage("");
                  }}
                  className="text-sm text-primary mt-1.5 font-semibold w-fit ml-auto"
                >
                  Forgot password?
                </Link>
                <button className="primary-button py-2 mt-6 font-semibold px-16 rounded-lg mx-auto w-fit">
                  Sign me in
                </button>
              </form>
            )}
            {!showPasswordForgotten && (
              <div className="relative text-center mt-8">
                <hr className="absolute w-full h-0.5 bg-black/25 top-1/2" />
                <span className="bg-white px-4 absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%]">
                  or
                </span>
              </div>
            )}
            {!showPasswordForgotten && (
              <div
                onClick={GoogleLogin}
                className="border-2 cursor-pointer flex items-center gap-x-4 mt-12 py-1.5 rounded-lg justify-center px-4 border-black/10 border-solid"
              >
                <img src={google} className="w-9" />
                <span className="font-medium">
                  {showUserRegister
                    ? "Sign up with Google"
                    : "Sign in with Google"}
                </span>
              </div>
            )}
            <div
              className="underline cursor-pointer text-center mt-8 block"
              onClick={() => {
                if (showPasswordForgotten) {
                  setShowPasswordForgotten(false);
                  setErrorMessage("");
                } else if (showUserRegister) {
                  setShowUserRegister(false);
                  setErrorMessage("");
                } else {
                  setShowUserRegister(true);
                  setErrorMessage("");
                }
              }}
            >
              {showPasswordForgotten
                ? "Back to Sign in"
                : showUserRegister
                ? "Already have an account? Sign in"
                : "Don’t have an account? Sign up"}
            </div>
            <div className="text-black/50 text-xs text-center mt-12">
              By creating an account, you accept our Terms of Use and Privacy
              Policy
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default LoginForm;

import React from "react";
import { Link } from "react-router-dom";
import home from "../static/images/home.svg";
import arrow from "../static/images/arrow.svg";
import innovation from "../static/images/innovation.svg";
import outsource from "../static/images/outsource.svg";
import bio from "../static/images/bio.svg";
import creative from "../static/images/creative.svg";
import arrow_white from "../static/images/arrow_white.svg";

const About = () => {
  const about_cards = [
    {
      title: "Leading Through Innovation",
      description:
        "We are not just packaging experts; we are pioneers in our industry. Apakz continuously invests in cutting-edge printing and production technology, ensuring unparalleled quality, competitive pricing, and swift delivery. Our dedication to innovation extends to our workforce - Apakz boasts the best specialists in the country, shaping the future of paper packaging.",
      icon: innovation,
    },
    {
      title: "Client-Centric Approach",
      description:
        "Collaboration is key at Apakz. We work closely and amicably with our clients, understanding their unique needs to provide tailored packaging solutions. Our friendly collaboration has led to success stories, from igniting packaging design competitions among sushi companies to doubling sales for a meat-producing company through modern, attractive packaging.",
      icon: outsource,
    },
    {
      title: "Sustainability at Our Core",
      description:
        "As guardians of the environment, Apakz actively contributes to sustainability. We recycle our paper waste and utilize eco-friendly ink for printing, minimizing our ecological footprint and preserving the beauty of our planet for generations to come.",
      icon: bio,
    },
    {
      title: "Personalized Solutions",
      description:
        "Apakz believes that exceptional packaging shouldn't come at an exorbitant cost. With competitive pricing and a commitment to personalized packaging, we cater to the needs of both large enterprises and those with smaller orders, ensuring everyone benefits from our expertise.",
      icon: creative,
    },
  ];

  return (
    <div className="mx-52 mt-8 sm:mt-4 xl:mx-40 lg:mx-12 md:mx-6 sm:mx-6">
      <div className="flex items-center gap-x-3">
        <Link
          to="/"
          className="flex gap-x-2 font-medium text-sm items-start opacity-90"
        >
          <img src={home} className="w-4 mb-0.5" />
          Home
        </Link>
        <img src={arrow} className="w-3" />
        <p className="font-medium text-sm items-start opacity-90">About Us</p>
      </div>
      <div className="">
        <h1 className="font-karla text-5xl mt-4 font-semibold ">About Us</h1>
        <div className="grid grid-cols-3 md:grid-cols-1 sm:grid-cols-1 gap-y-8 md:gap-x-10 gap-x-20">
          <div className="col-span-2 sm:col-span-1 md:col-span-1 flex flex-col gap-y-4 mt-8">
            <div>
              <h2 className="font-karla text-2xl font-medium">
                Welcome to Apakz,{" "}
              </h2>
              <p className="font-karla leading-7 mt-2">
                where a legacy of craftsmanship meets a commitment to
                innovation. Founded in 1999 as a family business in the
                picturesque Republic of Moldova, Apakz has evolved into a
                dynamic force, with a new generation now steering the helm and
                continuing the family's dedication to excellence. Our roots run
                deep, and our commitment to sustainable paper packaging
                excellence extends across Europe and beyond.
              </p>
            </div>
            <div>
              <h2 className="font-karla text-2xl font-medium">
                Unleashing Creativity in Sustainable Packaging
              </h2>
              <p className="font-karla leading-7 mt-2">
                where a legacy of craftsmanship meets a commitment to
                innovation. Founded in 1999 as a family business in the
                picturesque Republic of Moldova, Apakz has evolved into a
                dynamic force, with a new generation now steering the helm and
                continuing the family's dedication to excellence. Our roots run
                deep, and our commitment to sustainable paper packaging
                excellence extends across Europe and beyond.
              </p>
            </div>
          </div>
          <div className="bg-white flex md:justify-evenly flex-col gap-y-4 rounded-xl drop-shadow-hard p-8">
            <div>
              <div className="text-primary text-4xl font-semibold font-karla">
                25+
              </div>
              <p className="mt-2 font-karla">
                Years of industry experience and unwavering dedication to
                quality
              </p>
            </div>
            <div>
              <div className="text-primary text-4xl font-semibold font-karla">
                1 350+
              </div>
              <p className="mt-2 font-karla">
                <span className="font-medium font-karla">
                  Satisfied Clients
                </span>{" "}
                that garnered the trust by our commitment to excellence
              </p>
            </div>
            <div>
              <div className="text-primary text-4xl font-semibold font-karla">
                9 000 000+
              </div>
              <p className="mt-2 font-karla">
                <span className="font-medium font-karla">
                  Paper Packaging Produced
                </span>{" "}
                with precision and dedication, each telling a unique story.
              </p>
            </div>
          </div>
        </div>
        <h2 className="w-full mix-blend-multiply bg-primary py-4 font-karla text-2xl font-semibold text-white rounded-2xl text-center mt-8">
          Why our clients choose us?
        </h2>
        <div className="grid grid-cols-2 gap-x-20 md:gap-x-8 sm:grid-cols-1">
          {about_cards.map((card, index) => (
            <div className="p-8 bg-white rounded-xl drop-shadow-hard mt-8">
              <h3 className="text-xl font-karla font-medium flex gap-x-2 items-center">
                <img src={card.icon} className="w-8" alt={card.title} />
                {card.title}
              </h3>
              <p className="font-karla leading-7 mt-2">
                <span className="opacity-0 select-none">____</span>
                {card.description}
              </p>
            </div>
          ))}
        </div>
        <div className="bg-white rounded-xl drop-shadow-hard p-8 mt-8">
          <div className="font-bold text-primary text-4xl text-center font-karla">
            Join us
          </div>
          <p className="mt-2 leading-7 font-karla font-medium text-lg sm:text-base">
            at Apakz, where every package tells a story, and sustainability
            meets creativity. Together, let's redefine the art of paper
            packaging for a brighter, greener future.
          </p>
          <Link to="/service/packaging">
            <button className="flex gap-x-2 group bg-primary text-white font-karla font-medium text-lg px-8 py-4 rounded-xl mt-8 items-center w-full justify-center">
              Explore Categories{" "}
              <img
                src={arrow_white}
                className="w-4 group-hover:translate-x-1 ease-in-out duration-150 transition-transform translate-y-0.5"
              />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_url } from "../constants";
import { useSelector } from "react-redux";
import LoadingSpinner from "../components/LoadingSpinner";
import { motion } from "framer-motion";

import UserOrderCard from "../components/UserOrderCard";
const UserAccountOrderHistory = () => {
  const [orders, setOrders] = useState([]);

  const auth = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${api_url}/user/orders/`, {
        params: {
          token: auth.access,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setOrders(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <div className="flex flex-col gap-y-6">
      {(isLoading && (
        <div className="w-fit mx-auto mt-4">
          <LoadingSpinner />
        </div>
      )) ||
        (orders.length == 0 && (
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-stone-700/25 text-center text-xl py-2 font-bold font-karla"
          >
            No Orders Found
          </motion.h1>
        ))}
      {orders.map((order) => (
        <UserOrderCard order={order} />
      ))}
    </div>
  );
};

export default UserAccountOrderHistory;

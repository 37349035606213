import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { api_url, statuses } from "../constants";
import convertPythonDateTimetoDateTime from "../utils/ConvertPythonDateTimeToDateTime";
import GetProductPrice from "../utils/GetProductPrice";
import UserOrderItemCard from "../components/UserOrderItemCard";
import { changePage } from "../store/adminPageSlice";
import { useDispatch } from "react-redux";
const AdminOrder = () => {
  const [order, setOrder] = useState({});
  const [status, setStatus] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  function CalculateTotalPrice() {
    let total_price = 0;
    order.items &&
      order.items.map((item) => {
        total_price += item.price;
      });
    return total_price;
  }

  function fetchOrder() {
    axios
      .get(`${api_url}/order/${id}/`)
      .then((res) => {
        setOrder(res.data);
        dispatch(changePage(`Order ${res.data.order_number}`));
        const new_status = statuses.find(
          (status) => status.status === res.data.status
        );
        setStatus(new_status);
        setTrackingNumber(res.data.tracking_number);
        setSelectedStatus(res.data.status);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    fetchOrder();
  }, []);
  const navigate = useNavigate();

  function DeleteOrder() {
    const confirm = window.confirm("Are you sure you want to delete this?");
    if (!confirm) return;

    axios
      .delete(`${api_url}/order/${id}/`)
      .then((res) => {
        navigate("/admin/orders");
      })
      .catch((err) => console.log(err));
  }

  function ArchiveOrder() {
    const confirm = window.confirm(
      order.archived
        ? "Are you sure you want to unarchive this?"
        : "Are you sure you want to archive this?"
    );
    if (!confirm) return;

    axios
      .put(`${api_url}/order/${id}/`, { archived: !order.archived })
      .then((res) => {
        fetchOrder();
      })
      .catch((err) => console.log(err));
  }
  const [selectedStatus, setSelectedStatus] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");

  function SaveChanges() {
    axios
      .put(`${api_url}/order/${id}/`, {
        status: selectedStatus,
        tracking_number: trackingNumber,
      })
      .then((res) => {
        fetchOrder();
        setIsEditing(false);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="mt-8 px-24 pb-20 relative lg:px-8 md:px-8 sm:px-4 xl:px-12">
      <div className="ml-auto flex gap-x-4 w-fit sm:flex-col sm:w-full gap-y-2">
        {!isEditing && (
          <button
            onClick={ArchiveOrder}
            className="text-white bg-black rounded-none px-4 h-fit sm:w-full block py-1 font-semibold"
          >
            {order.archived ? "Unarchive Order" : "Archive Order"}
          </button>
        )}
        {!isEditing && (
          <button
            onClick={() => setIsEditing(true)}
            className=" primary-button rounded-none px-4 h-fit block py-1 sm:w-full font-semibold"
          >
            Edit Order
          </button>
        )}
        {isEditing && (
          <button
            onClick={() => SaveChanges()}
            className=" primary-button rounded-none px-4 h-fit block py-1 sm:w-full font-semibold"
          >
            Save Changes
          </button>
        )}
        {!isEditing && (
          <button
            onClick={DeleteOrder}
            className=" secondary-button rounded-none px-4 h-fit block py-1 sm:w-full font-semibold"
          >
            Delete Order
          </button>
        )}
      </div>
      <div className="grid grid-cols-3 gap-y-6 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 mt-2 gap-x-4 w-fit">
        <div className=" gap-y-2 flex flex-col">
          <div>
            <div className="font-semibold">Order Number: </div>
            <div>{order.order_number}</div>
          </div>
          <div>
            <div className="font-semibold">Order Date: </div>
            <div>
              {order.created_at &&
                convertPythonDateTimetoDateTime(order.created_at)}
            </div>
          </div>
          <div>
            <div className="font-semibold">Order Status: </div>
            {isEditing ? (
              <select
                className="admin-input py-0.5 px-1"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                {statuses.map((status) => (
                  <option value={status.status}>{status.status}</option>
                ))}
              </select>
            ) : (
              <div className="flex gap-x-2 items-center">
                <div
                  className={
                    "w-4 h-4 rounded-full " + (status.color ? status.color : "")
                  }
                ></div>
                {order.status}
              </div>
            )}
          </div>
          <div>
            <div className="font-semibold">Tracking: </div>
            {isEditing ? (
              <input
                className="admin-input py-0.5 px-1"
                name="tracking_number"
                defaultValue={order.tracking_number}
                onChange={(e) => setTrackingNumber(e.target.value)}
                value={trackingNumber}
              />
            ) : (
              <div>
                {order.tracking_number ? order.tracking_number : "Empty"}
              </div>
            )}
          </div>
        </div>
        <div className=" gap-y-2 flex flex-col">
          <div>
            <div className="font-semibold">Customer Email: </div>
            <div>{order.user && order.user.email}</div>
          </div>
          <div>
            <div className="font-semibold">Phone: </div>
            <div>{order.user && order.user.phone}</div>
          </div>
          <div>
            <div className="font-semibold">Shipping Name & Phone: </div>
            {order.shipping_address && (
              <div>
                {order.shipping_address.name}, {order.shipping_address.phone}
              </div>
            )}
          </div>
          <div>
            <div className="font-semibold">Shipping Address: </div>
            {order.shipping_address && (
              <div>
                {order.shipping_address.country.name},{" "}
                {order.shipping_address.city}, {order.shipping_address.address}
              </div>
            )}
          </div>
        </div>
        <div className=" gap-y-2 flex flex-col">
          <div>
            <div className="font-semibold">Company name: </div>
            <div>{order.company && order.company.name}</div>
          </div>
          <div>
            <div className="font-semibold">Registration number: : </div>
            <div>{order.company && order.company.registration_number}</div>
          </div>
          <div>
            <div className="font-semibold">VAT number: </div>
            <div>{order.company && order.company.vat_number}</div>
          </div>
          <div>
            <div className="font-semibold">Adress: </div>
            <div>{order.company && order.company.address}</div>
          </div>
        </div>
      </div>
      <div className="mt-4 text-xl font-semibold">
        {CalculateTotalPrice() + order.shipping_price} €
      </div>
      <hr className="h-0.5 bg-black" />
      <div className="flex overflow-hidden flex-col gap-y-4 mt-4">
        {order.items &&
          order.items.length > 0 &&
          order.items.map((item) => (
            <div className="flex flex-col gap-y-2">
              <UserOrderItemCard item={item} />{" "}
              <hr className="h-0.5 bg-black w-full" />
            </div>
          ))}
      </div>
    </div>
  );
};

export default AdminOrder;

import React, { useState } from "react";
import edit_2 from "../static/images/edit_2.svg";
import check from "../static/images/check.svg";
import expand from "../static/images/expand.svg";
import trashcan from "../static/images/trashcan.svg";
import { useDispatch } from "react-redux";
import {
  setGlobalErrorMessage,
  setGlobalNotificationMessage,
} from "../store/errorMessageSlice";
import axios from "axios";
import { api_url } from "../constants";
import { motion } from "framer-motion";
const AdminSEOCard = ({ page, pages, setPages }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [title, setTitle] = useState(page.title);
  const [slug, setSlug] = useState(page.slug);
  const [keywords, setKeywords] = useState(page.keywords);
  const [description, setDescription] = useState(page.description);
  const dispatch = useDispatch();

  function SaveChanges() {
    axios
      .put(api_url + "/page/seo/" + page.id + "/", {
        title,
        slug,
        keywords,
        description,
      })
      .then((res) => {
        dispatch(setGlobalNotificationMessage("SEO Updated Successfully"));
        setIsEditing(false);
      })
      .catch((err) => {
        dispatch(setGlobalErrorMessage("Error Updating SEO"));
        setIsEditing(false);
        setTitle(page.title);
        setSlug(page.slug);
        setKeywords(page.keywords);
        setDescription(page.description);
      });
  }

  function DeleteSEO() {
    axios
      .delete(api_url + "/page/seos/" + page.id + "/")
      .then((res) => {
        dispatch(setGlobalNotificationMessage("SEO Deleted Successfully"));
        setPages(pages.filter((item) => item.id !== page.id));
      })
      .catch((err) => {
        dispatch(setGlobalErrorMessage("Error Deleting SEO"));
      });
  }

  return (
    <motion.div className="bg-white group relative drop-shadow-sm px-4 py-2">
      <div className="grid grid-cols-4 sm:flex sm:flex-col gap-y-1 md:grid-cols-2 gap-x-8 cursor-pointer ">
        <div
          onClick={() => {
            !isEditing && setIsExpanded(!isExpanded);
          }}
          className="flex gap-x-3 flex-1 items-center cursor-pointer"
        >
          <motion.img
            initial={{ rotate: 0 }}
            animate={{ rotate: isExpanded ? 180 : 0 }}
            transition={{ duration: 0.2 }}
            whileHover={{ scale: 1.05 }}
            src={expand}
            className="w-4"
          />{" "}
          {isEditing ? (
            <input
              className="admin-input py-0.5"
              value={title}
              placeholder="Title"
              onChange={(e) => setTitle(e.target.value)}
            />
          ) : (
            <span className="flex gap-x-1">
              {" "}
              <span className="font-semibold md:block sm:block hidden">
                Title:
              </span>
              {title}
            </span>
          )}
        </div>
        <div>
          {isEditing ? (
            <input
              className="admin-input py-0.5"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
            />
          ) : (
            <span className="flex gap-x-1">
              {" "}
              <span className="font-semibold md:block sm:block hidden">
                Slug:
              </span>
              {slug}
            </span>
          )}
        </div>
        <div className="col-span-2 flex justify-between gap-x-4">
          {isEditing ? (
            <input
              className="admin-input py-0.5 w-3/4"
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
            />
          ) : (
            <span className="flex gap-x-1">
              {" "}
              <span className="font-semibold md:block sm:block hidden">
                Keywords:
              </span>
              {keywords}
            </span>
          )}{" "}
          <div className="flex gap-x-6">
            {!isEditing && (
              <motion.img
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                src={edit_2}
                alt="edit"
                className="cursor-pointer w-5"
                onClick={() => {
                  setIsEditing(true);
                  setIsExpanded(true);
                }}
              />
            )}
            {!isEditing && (
              <motion.img
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                src={trashcan}
                alt="delete"
                className="cursor-pointer w-5"
                onClick={DeleteSEO}
              />
            )}
            {isEditing && (
              <motion.img
                src={check}
                className="w-5 cursor-pointer"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={SaveChanges}
              />
            )}
          </div>
        </div>
      </div>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: isExpanded ? "auto" : 0,
          opacity: isExpanded ? 1 : 0,
        }}
        transition={{ duration: 0.2 }}
        className="overflow-hidden"
      >
        {isEditing ? (
          <textarea
            className="w-full admin-input mt-3 min-h-[5rem] py-0.5"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        ) : (
          <div className=" mt-2">
            {description.length > 0 &&
              description
                .split("\n")
                .map((item, index) => <p key={index}>{item}</p>)}
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

export default AdminSEOCard;

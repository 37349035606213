import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { api_url } from "../constants";
import CartItem from "../components/CartItem";
import GetProductPrice from "../utils/GetProductPrice";
import confirm from "../static/images/confirm.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import group from "../static/images/group.svg";
import account from "../static/images/account.svg";
import check from "../static/images/check.svg";
import bank from "../static/images/bank.svg";
import credit from "../static/images/credit.svg";
import { setGlobalErrorMessage } from "../store/errorMessageSlice";
import { Helmet } from "react-helmet-async";
import { clear } from "../store/cartSlice";
const UserCart = () => {
  const userPage = useSelector((state) => state.userPage);

  const cart = useSelector((state) => state.cart);
  const [cartItems, setCartItems] = useState([]);
  const auth = useSelector((state) => state.auth);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedShippingAddress, setSelectedShippingAddress] = useState({});
  const [shippingTypes, setShippingTypes] = useState([]);
  const [selectedShippingType, setSelectedShippingType] = useState({});
  const [subtotal, setSubtotal] = useState(0);
  const [shippingTotal, setShippingTotal] = useState(0);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [checkout, setCheckout] = useState(false);
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    axios
      .get(`${api_url}/user/cart/items/`, {
        params: {
          token: auth.access,
        },
      })
      .then((res) => {
        setCartItems(res.data);

        axios
          .get(`${api_url}/user/shipping-addresses/`, {
            params: {
              token: auth.access,
            },
          })
          .then((res) => {
            setShippingAddresses(res.data);
            if (res.data.length > 0) {
              setSelectedShippingAddress(res.data[0]);
            }
            axios
              .get(`${api_url}/user/companies/`, {
                params: {
                  token: auth.access,
                },
              })
              .then((res) => {
                setCompanies(res.data);
                setSelectedCompany(res.data[0]);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let subtotal_price = 0;
    cartItems.map((cartItem) => {
      subtotal_price += cartItem.price;
    });
    setSubtotal(subtotal_price);
  }, [cartItems]);

  useEffect(() => {
    const country = selectedShippingAddress.country;
    if (country) {
      const shipping_types = [];
      if (country.price_standard > 0) {
        shipping_types.push("Standard Shipping");
      }
      if (country.price_express > 0) {
        shipping_types.push("Express Shipping");
      }
      if (country.price_economy > 0) {
        shipping_types.push("Economy Shipping");
      }
      setShippingTypes(shipping_types);
      setSelectedShippingType(shipping_types[0]);
    }
  }, [selectedShippingAddress]);

  useEffect(() => {
    const country = selectedShippingAddress.country;
    if (country) {
      let all_products_quantity = 0;
      let all_products_weight = 0;
      cartItems.map((item) => {
        let product_weight = 0;
        item.options.map((option) => {
          product_weight += option.weight;
        });
        console.log(product_weight);
        all_products_weight += product_weight * item.quantity;
      });
      if (selectedShippingType == "Standard Shipping") {
        setShippingTotal(
          country.price_standard +
            all_products_weight * country.price_per_unit_standard
        );
      } else if (selectedShippingType == "Express Shipping") {
        setShippingTotal(
          country.price_express +
            all_products_weight * country.price_per_unit_express
        );
      } else if (selectedShippingType == "Economy Shipping") {
        setShippingTotal(
          country.price_economy +
            all_products_weight * country.price_per_unit_economy
        );
      }
    }
  }, [selectedShippingType]);

  function Checkout() {
    if (!selectedCompany) {
      dispatch(setGlobalErrorMessage("Please select a company"));
      return;
    }
    if (!selectedShippingAddress) {
      dispatch(setGlobalErrorMessage("Please select a shipping address"));
      return;
    }
    if (cartItems.length == 0) {
      dispatch(setGlobalErrorMessage("Please add items to cart"));
      return;
    }
    setIsCheckingOut(true);
    axios
      .post(`${api_url}/user/order/`, {
        token: auth.access,
        company: selectedCompany.id,
        shipping_address: selectedShippingAddress.id,
        shipping_type: selectedShippingType,
      })
      .then((res) => {
        setCheckout(true);
        dispatch(clear());
        setIsCheckingOut(false);
      })
      .catch((err) => {
        setIsCheckingOut(false);
        dispatch(setGlobalErrorMessage("There was an error placing the order"));
        console.log(err);
      });
  }

  return (
    <div>
      <Helmet>
        <title>{userPage.title}</title>
        <meta name="description" content={userPage.description} />
        <meta name="keywords" content={userPage.keywords} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Apakz" />
        <link rel="canonical" href={"https://apakz.com" + userPage.slug} />
      </Helmet>
      {checkout ? (
        <div className="flex flex-col mt-12 items-center justify-center">
          <img src={confirm} alt="confirm" className="w-20" />
          <h1 className="text-2xl font-semibold mt-4">
            Your order has been placed successfully
          </h1>
          <p className="text-center mt-2 text-sm">
            You will receive an email with the order details.{" "}
            <Link
              to="/account/order-history"
              className="text-primary font-semibold"
            >
              Click here
            </Link>{" "}
            to view it in your account.
          </p>
        </div>
      ) : (
        <div className="mx-52 mt-10 xl:mx-40 lg:mx-12 md:mx-6 sm:mx-6">
          <div className="flex gap-x-4 md:flex-col gap-y-8 sm:flex-col">
            <div
              className={
                "bg-white p-6 rounded-xl flex flex-col gap-y-2 md:w-full sm:w-full" +
                (cartItems.length != 0 ? " w-2/3" : " w-full min-h-[10rem]")
              }
            >
              <h2 className="font-semibold text-lg">
                You got {cart} items in the cart
              </h2>
              <hr className="bg-secondary/50 h-0.5" />
              <div className="flex flex-col gap-y-4">
                {cartItems.map((item) => (
                  <div className="flex gap-y-4 flex-col">
                    <CartItem
                      disabled={orderPlaced}
                      key={item.id}
                      items={cartItems}
                      setItems={setCartItems}
                      item={item}
                    />{" "}
                    <hr className="bg-secondary/50 h-0.5" />
                  </div>
                ))}
              </div>
            </div>
            {cartItems.length != 0 && (
              <div className="bg-white p-6 rounded-xl flex flex-col gap-y-2 w-1/3 h-fit sm:w-full md:w-full">
                <h2 className="font-semibold text-lg">
                  Select a shipping address
                </h2>
                <div className="flex gap-x-4">
                  <select
                    disabled={shippingAddresses.length == 0 || orderPlaced}
                    value={selectedShippingAddress}
                    onChange={(e) =>
                      setSelectedShippingAddress(
                        shippingAddresses.find(
                          (address) => address.id == e.target.value
                        )
                      )
                    }
                    className="border border-gray-300 rounded-lg p-2 grow"
                  >
                    {shippingAddresses.length == 0 && (
                      <option value="">Set a least one shipping address</option>
                    )}
                    {shippingAddresses.map((address) => (
                      <option value={address.id}>
                        {address.country.name}, {address.city},{" "}
                        {address.address}
                      </option>
                    ))}
                  </select>{" "}
                  {shippingAddresses.length == 0 && (
                    <Link
                      to="/account/profile?scope=address"
                      className="bg-primary text-white rounded-lg py-2 px-2 font-medium w-fit"
                    >
                      Add address
                    </Link>
                  )}
                </div>

                <select
                  disabled={shippingAddresses.length == 0 || orderPlaced}
                  value={selectedShippingType}
                  onChange={(e) => setSelectedShippingType(e.target.value)}
                  className="border border-gray-300 rounded-lg p-2 "
                >
                  {shippingTypes.length == 0 && (
                    <option value="">No shipping types available</option>
                  )}
                  {shippingTypes.map((type) => (
                    <option value={type}>{type}</option>
                  ))}
                </select>

                <hr className="bg-secondary/50 h-0.5" />
                <div className="flex flex-col">
                  <div className="flex justify-between">
                    <div>Subtotal: {}</div>
                    <div className="font-semibold">{subtotal.toFixed(2)} €</div>
                  </div>
                  <div className="flex justify-between">
                    <div>Shipping: {}</div>
                    <div className="font-semibold">
                      {shippingTotal.toFixed(2)} €
                    </div>
                  </div>
                </div>
                <hr className="bg-secondary/50 h-0.5" />
                <div className="flex flex-col">
                  <div className="flex justify-between">
                    <div>Total: {}</div>
                    <div className="font-semibold">
                      {(subtotal + shippingTotal).toFixed(2)} €
                    </div>
                  </div>
                  <button
                    disabled={orderPlaced || shippingAddresses.length == 0}
                    onClick={(e) => {
                      setOrderPlaced(true);
                      //make the page scrooll to the bottom smooth
                      setTimeout(() => {
                        window.scrollTo({
                          top: document.body.scrollHeight,
                          behavior: "smooth",
                        });
                      }, 500);
                    }}
                    className={
                      "green-button transition-opacity ease-in-out duration-200 rounded-lg font-semibold py-2 block mt-2" +
                      (orderPlaced ? " opacity-75" : "")
                    }
                  >
                    PLACE ORDER{" "}
                  </button>
                </div>
              </div>
            )}
          </div>{" "}
          <motion.div
            initial={{
              height: 0,
            }}
            animate={{
              height: orderPlaced ? "auto" : 0,
            }}
            transition={{
              duration: 0.5,
            }}
            className="scrollbar-none mt-8 overflow-y-auto"
          >
            <h2 className="text-3xl font-bold ">Checkout</h2>
            <div className=" mt-4 justify-between flex gap-x-4 md:flex-col sm:flex-col gap-y-4">
              <div className="flex gap-x-4">
                <button className="flex shadow-inner gap-x-2 items-center bg-primary text-white rounded-xl py-2 px-12 sm:px-4 font-medium w-fit">
                  <img src={group} className="w-4" alt="company" />
                  Company
                </button>
                <button className="flex opacity-65 drop-shadow-hard gap-x-2 items-center bg-white text-black rounded-xl py-2 px-12 sm:px-4 font-medium w-fit">
                  <img src={account} className="w-4" alt="company" />
                  <div>
                    Individual <span className="text-xxs">(coming soon)</span>
                  </div>
                </button>
              </div>
              <div className="flex gap-x-4">
                <button className="flex shadow-inner gap-x-2 items-center bg-primary text-white rounded-xl py-2 px-12 sm:px-4 font-medium w-fit">
                  <img src={bank} className="w-4" alt="company" />
                  Bank Transfer
                </button>
                <button className="flex opacity-65 drop-shadow-hard gap-x-2 items-center bg-white text-black rounded-xl py-2 sm:px-4 px-12 font-medium w-fit">
                  <img src={credit} className="w-4" alt="company" />
                  <div>
                    Credit Card <span className="text-xxs">(coming soon)</span>
                  </div>
                </button>
              </div>
            </div>
            <div
              className={
                "grid gap-x-10 mt-4 md:grid-cols-2 sm:grid-cols-1" +
                (companies.length == 0 ? " grid-cols-1" : " grid-cols-3")
              }
            >
              {companies.length == 0 && (
                <Link
                  to="/account/profile"
                  className="bg-white p-4 font-semibold  drop-shadow-md  rounded-xl border-2"
                >
                  No companies available. Access the profile page to add a
                  company.
                </Link>
              )}
              {companies.map((company) => (
                <div
                  className={
                    "relative cursor-pointer drop-shadow-hard hover:drop-shadow-extraHard bg-white transition-all ease-in-out duration-200 rounded-xl border-2" +
                    (company == selectedCompany
                      ? " border-primary"
                      : " border-white")
                  }
                >
                  <div className="p-2 w-fit bg-primary absolute right-2 top-2 rounded-full">
                    <img src={check} className="w-2 invert" />
                  </div>
                  <div className="py-6 px-6">
                    <div className="font-semibold text-xl">{company.name}</div>
                    <div className="mt-4 flex flex-col">
                      <span className="user-input-label">
                        Registration number:{" "}
                      </span>
                      <span className="font-medium">
                        {company.registration_number}
                      </span>
                    </div>
                    <div className="mt-2 flex flex-col">
                      <span className="user-input-label">VAT number: </span>
                      <span className="font-medium">{company.vat_number}</span>
                    </div>
                    <div className="mt-2 flex flex-col">
                      <span className="user-input-label">Adress: </span>
                      <span className="font-medium">{company.address}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button
              disabled={
                !selectedCompany || !selectedShippingAddress || isCheckingOut
              }
              onClick={Checkout}
              className="green-button px-20 ml-auto rounded-lg font-semibold py-2 block mt-6 sm:w-full"
            >
              {isCheckingOut ? "PROCESSING..." : "CHECKOUT"}
            </button>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default UserCart;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { api_url, media_url } from "../constants";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import PageDoesNotExist from "./PageDoesNotExist";
import Skeleton from "react-loading-skeleton";
import useMediaQuery from "../hooks/useMediaQuery";

const Service = () => {
  const { slug } = useParams();
  const [service, setService] = useState({});
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const isSM = useMediaQuery("(max-width: 640px)");

  useEffect(() => {
    axios
      .get(api_url + "/service/", {
        params: {
          slug: slug,
        },
      })
      .then((res) => {
        setService(res.data);
        setIsLoading(false);
        axios
          .get(api_url + "/service/" + res.data.id + "/categories/")
          .then((res) => {
            setCategories(res.data);
          })
          .catch((err) => {});
        axios
          .get(api_url + "/service/" + res.data.id + "/products/")
          .then((res) => {
            setProducts(res.data);
            setFilteredProducts(res.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        setNotFound(true);
        setIsLoading(false);
      });
  }, [slug]);

  useEffect(() => {
    if (selectedCategory) {
      const filteredProducts = products.filter((product) =>
        product.categories.includes(selectedCategory.id)
      );
      setFilteredProducts(filteredProducts);
    } else {
      setFilteredProducts(products);
    }
  }, [selectedCategory]);

  return notFound ? (
    <PageDoesNotExist />
  ) : (
    <div className="mt-16 md:mt-0 sm:mt-0">
      <Helmet>
        <title>{service.seo_title}</title>
        <meta name="description" content={service.seo_description} />
        <meta name="keywords" content={service.seo_keywords} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Apakz" />
        <link rel="canonical" href={"https://apakz.com" + service.slug} />
      </Helmet>
      <div className="grid grid-cols-2 mx-52 xl:mx-40 lg:mx-12 md:mx-6 sm:mx-6 sm:grid-cols-1 md:grid-cols-1 gap-y-12 gap-x-36 xl:gap-x-24 lg:gap-x-16 ">
        <div className="mt-8">
          <h1 className="text-6xl font-bold md:text-5xl sm:text-4xl">
            {service.title || <Skeleton borderRadius={10} />}
          </h1>
          <div className="mt-6 flex flex-col gap-y-1">
            {(service.description &&
              service.description.split("\n").map((item, index) => {
                return (
                  <p key={index} className={index === 0 ? "font-semibold" : ""}>
                    {item}
                  </p>
                );
              })) || <Skeleton count={2} borderRadius={10} />}
          </div>
          <p className="mt-16 sm:mt-8">
            <span className="font-bold mr-2">Note:</span>
            <span>{service.note || <Skeleton count={3} />}</span>
          </p>
          <div className="flex sm:flex-col sm:items-start gap-y-2 gap-x-4 items-center mt-4">
            <Link
              to="/contact"
              className="primary-button whitespace-nowrap font-semibold px-8 py-1"
            >
              Contact Us
            </Link>
            <p className="font-bold text-sm">
              We will provide you the best and cheapest solution!
            </p>
          </div>
        </div>
        <div className="relative w-full h-full min-h-[20rem] md:w-2/3 md:mx-auto">
          <motion.img
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.25 }}
            className="w-full h-full md:mx-auto object-cover rounded-lg"
            src={media_url + service.image}
            onLoad={() => {
              setIsImageLoading(false);
            }}
          />
          {isImageLoading && (
            <div className="absolute top-0 left-0">
              <Skeleton height={isSM ? 300 : 425} width={isSM ? 400 : 500} />
            </div>
          )}
        </div>
      </div>

      <div className="bg-primary mix-blend-multiply flex px-52 py-2 mt-12 scrollbar-thumb-white xl:px-40 lg:px-12 md:px-6 sm:px-6 overflow-x-auto w-full">
        <ul className="flex w-full whitespace-nowrap">
          <li className="font-semibold text-white border-r-2 border-r-white border-r-solid pr-8">
            <div
              onClick={() => setSelectedCategory(null)}
              className="group relative cursor-pointer px-1"
            >
              All
              <div
                className={
                  "absolute bottom-0 left-0 transition-all ease-in-out duration-200 h-0.5 bg-white group-hover:w-full " +
                  (selectedCategory == null ? "w-full" : "w-0")
                }
              ></div>
            </div>
          </li>
          {categories.map((category) => (
            <li
              key={category.id}
              className="font-semibold text-white border-r-2 border-r-white border-r-solid px-8"
            >
              <div
                onClick={() => setSelectedCategory(category)}
                className="group relative cursor-pointer px-1"
              >
                {category.name}
                <div
                  className={
                    "absolute bottom-0 left-0 transition-all ease-in-out duration-200 h-0.5 bg-white group-hover:w-full " +
                    (selectedCategory && selectedCategory.id == category.id
                      ? "w-full"
                      : "w-0")
                  }
                ></div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {filteredProducts.length == 0 && (
        <motion.div
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.25 }}
          className=" bg-black/15 text-center mx-52 mt-12 xl:mx-40 lg:mx-12 md:mx-6 sm:mx-6 py-4 font-semibold"
        >
          No products found for this category
        </motion.div>
      )}
      <div className="grid grid-cols-3 px-52 mt-12 gap-16 xl:px-40 lg:px-12 md:px-6 sm:px-6 lg:gap-x-12 md:gap-x-8 gap-y-8 md:grid-cols-2 sm:grid-cols-1">
        {filteredProducts.map((product, index) => (
          <ProductCard key={product.id} product={product} index={index} />
        ))}
      </div>
    </div>
  );
};

export default Service;

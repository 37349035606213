import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_url, media_url } from "../constants";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";

const UserAccountFavourites = () => {
  const [products, setProducts] = useState([]);
  const auth = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${api_url}/user/products/favorites/`, {
        params: {
          token: auth.access,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setProducts(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      {(isLoading && (
        <div className="w-fit mx-auto mt-4">
          <LoadingSpinner />
        </div>
      )) ||
        (products.length == 0 && (
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-stone-700/25 text-center text-xl py-2 font-bold font-karla"
          >
            No products in your favourites
          </motion.h1>
        ))}
      <div className="grid grid-cols-3 md:grid-cols-2 gap-y-8 sm:grid-cols-1 gap-x-8">
        {products.map((product, index) => (
          <Link to={"/product/" + product.slug}>
            <motion.div
              whileHover={{ scale: 1.02 }}
              initial={{ opacity: 0, y: "5%" }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.2,
                ease: "easeInOut",
                delay: 0.1 * index,
              }}
              className="relative bg-white  rounded-3xl drop-shadow-hard"
            >
              <img
                className="w-full h-full object-cover rounded-3xl"
                src={media_url + "/" + product.images[0].image}
              />
              <div className="absolute bottom-0 p-4 bg-white rounded-b-3xl w-full">
                <h3 className="font-semibold ">{product.title}</h3>
                <p className="text-sm mt-1">
                  {product.description.length > 100
                    ? product.description.substring(0, 100) + "..."
                    : product.description}
                </p>
                <div className="text-lg w-fit ml-auto font-light mt-4">
                  As low as {product.base_price}€
                </div>
              </div>
            </motion.div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default UserAccountFavourites;

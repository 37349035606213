import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { auth_url } from '../constants'
import confirm from '../static/images/confirm.svg'
const ActivateAccount = () => {
    const {uid, token} = useParams()
    const [activated, setActivated] = useState(false)
    useEffect(() => {
        console.log({uid, token})
        axios.post(`${auth_url}/users/activation/`, {
            uid: uid,
            token: token,
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        },
        //add the csrf
        ).then(res=>{
            setActivated(true)
            console.log(res)
        }).catch(err=>{
            console.log(err)
        })
    }, [uid, token])

  return (
    <div>{activated ? 
        <div className="flex flex-col mt-16 items-center justify-center">
            <img src={confirm} alt="confirm" className="w-20" />
            <h1 className="text-2xl font-semibold mt-4">Account Activated</h1>
            <p className="text-center mt-2 text-sm">Your account has been activated successfully. You can now login.</p>
        </div>
        :
        <div className="flex flex-col mt-16 items-center justify-center">
            <h1 className="text-2xl font-semibold mt-4">Activating Account</h1>
            <p className="text-center mt-2 text-sm">Please wait while we activate your account.</p>
        </div>
    }</div>
  )
}

export default ActivateAccount
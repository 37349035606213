import React from "react";
import { Link } from "react-router-dom";
import duplicate from "../static/images/duplicate.svg";
import axios from "axios";
import { api_url } from "../constants";

const AdminProductCard2 = ({
  selectedProducts,
  setSelectedProducts,
  product,
  onDragStart,
  onDragEnter,
  onDragEnd,
}) => {
  function DuplicateProduct() {
    axios
      .post(api_url + "/product/" + product.id + "/duplicate/")
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div
      draggable
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
      className=" bg-white rounded-lg py-3 px-6 drop-shadow-md group"
    >
      <div className="font-medium flex gap-x-4 items-center">
        <input
          type="checkbox"
          className="w-5 h-5"
          onClick={() => {
            // add the product id to the selected products array, if it's not already there
            if (selectedProducts.includes(product.id)) {
              setSelectedProducts(
                selectedProducts.filter((id) => id !== product.id)
              );
            } else {
              setSelectedProducts([...selectedProducts, product.id]);
            }
          }}
          checked={selectedProducts.includes(product.id)}
        />
        <div className="flex justify-between w-full items-center">
          <Link to={"/admin/products/" + product.id}>{product.title}</Link>
          <img
            src={duplicate}
            onClick={DuplicateProduct}
            className="w-5 opacity-0 group-hover:opacity-100 -translate-x-2 cursor-pointer transition-all ease-in-out duration-200 group-hover:translate-x-0"
          />
        </div>
      </div>
    </div>
  );
};

export default AdminProductCard2;

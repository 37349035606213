import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import convertPythonDateTimetoDateTime from "../utils/ConvertPythonDateTimeToDateTime";
import GetProductPrice from "../utils/GetProductPrice";
import edit from "../static/images/edit.svg";
import { statuses } from "../constants";
const AdminOrderCard = ({ order, index }) => {
  const [status, setStatus] = useState({});
  useEffect(() => {
    const new_status = statuses.find(
      (status) => status.status === order.status
    );
    setStatus(new_status);
  }, []);

  function CalculateTotalPrice() {
    let total_price = 0;
    order.items.map((item) => {
      total_price += item.price;
    });
    return total_price;
  }
  return (
    <Link
      to={"/admin/orders/" + order.id}
      className="grid relative group items-center grid-cols-5 lg:grid-cols-4 md:grid-cols-4 bg-white sm:text-sm gap-y-1 sm:grid-cols-2 px-4 py-2"
    >
      <div>{order.order_number}</div>
      <div className="text-sm">
        {order.user.email.length > 16
          ? order.user.email.substring(0, 16) + "..."
          : order.user.email}
      </div>
      <div className="">
        {convertPythonDateTimetoDateTime(order.created_at)}
      </div>
      <div className="flex gap-x-2 items-center">
        <div
          className={
            "w-4 h-4 rounded-full " + (status.color ? status.color : "")
          }
        ></div>
        <span className="md:hidden">{order.status}</span>
      </div>
      <div className="lg:hidden md:hidden sm:hidden">
        {(CalculateTotalPrice() + order.shipping_price).toFixed()} €
      </div>
      <img
        src={edit}
        className="absolute w-4 top-1/2 -translate-y-1/2 right-4 opacity-0 ease-in-out duration-300 group-hover:opacity-100 transition-opacity"
      />
    </Link>
  );
};

export default AdminOrderCard;

//create a function that verifies if the password is strong
export default function isPasswordStrong(password) {
  //check if the password is at least 8 characters long
  if (password.length < 8) {
    return false;
  }
  //check if the password contains at least one uppercase letter
  if (password.search(/[A-Z]/) < 0) {
    return false;
  }
  //check if the password contains at least one lowercase letter
  if (password.search(/[a-z]/) < 0) {
    return false;
  }
  //check if the password contains at least one digit
  if (password.search(/[0-9]/) < 0) {
    return false;
  }
  //check if the password contains at least one special character
  if (password.search(/[!@#$%^&*]/) < 0) {
    return false;
  }
  return true;
}

import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { api_url } from "../constants";
import AdminCategoryCard from "../components/AdminCategoryCard";
import { changePage } from "../store/adminPageSlice";
import { useDispatch } from "react-redux";
const AdminCategories = () => {
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [toggleServices, setToggleServices] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changePage("Categories"));
    axios.get(api_url + "/categories/").then((res) => {
      setCategories(res.data);
    });
    axios.get(api_url + "/services/names/").then((res) => {
      setServices(res.data);
      console.log(res.data);
    });
  }, []);

  function CreateCategory(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name");
    if (!name || !selectedService) {
      alert("Completează toate câmpurile!");
      return;
    }
    formData.append("service", selectedService.id);
    axios
      .post(api_url + "/category/", formData)
      .then((res) => {
        setCategories([...categories, res.data]);
        e.target.reset();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function removeCategory(id) {
    setCategories(categories.filter((category) => category.id !== id));
  }

  return (
    <div className="mt-8 px-24 md:px-8 sm:px-4 xl:px-12 pb-20">
      <form
        className="flex gap-x-4 items-center flex-wrap gap-y-4"
        onSubmit={CreateCategory}
      >
        <button className="primary-button py-1 px-6 font-medium !rounded-none">
          Adaugă Categorie
        </button>
        <input
          name="name"
          className="admin-input py-1 md:min-w-[12rem]"
          placeholder="Nume Categorie"
        />
        <div
          className="relative cursor-pointer"
          onClick={() => setToggleServices(!toggleServices)}
        >
          <label className="admin-input cursor-pointer">
            {selectedService ? selectedService.title : "Alege serviciul"}
          </label>
          <motion.ul
            initial={{ opacity: 0, y: "95%", height: 0 }}
            animate={{
              opacity: toggleServices ? 1 : 0,
              y: toggleServices ? "120%" : "95%",
              height: toggleServices ? "auto" : 0,
            }}
            transition={{ duration: 0.2, ease: "easeInOut", delay: 0.1 }}
            className="absolute bg-white/50 bottom-0 overflow-y-auto scrollbar-none w-full"
          >
            <div className="flex flex-col gap-y-2 py-2 font-medium">
              {services.map((service) => (
                <li
                  onClick={() => setSelectedService(service)}
                  className="px-2"
                >
                  {service.title}
                </li>
              ))}
            </div>
          </motion.ul>
        </div>
      </form>
      <motion.div className="grid grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-8 mt-10 gap-y-4">
        {services.map((service) => (
          <div className="flex flex-col gap-y-4">
            <h2 className="text-xl font-semibold">{service.title}</h2>
            <div className="flex flex-col gap-y-4">
              {categories.map(
                (category) =>
                  category.service === service.id && (
                    <AdminCategoryCard
                      key={category.id}
                      removeCategory={removeCategory}
                      category={category}
                    />
                  )
              )}
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default AdminCategories;

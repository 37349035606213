import React, { useState, useEffect } from "react";
import { countries, api_url } from "../constants";
import Flag from "react-flagkit";
import axios from "axios";
import convertPythonDateTimetoDateTime from "../utils/ConvertPythonDateTimeToDateTime";
import { Link } from "react-router-dom";
import AdminOrderCard from "../components/AdminOrderCard";
import { changePage } from "../store/adminPageSlice";
import { useDispatch } from "react-redux";
const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  const [archived, setArchived] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    dispatch(changePage("Orders"));
    axios
      .get(`${api_url}/orders/?archived=` + archived)
      .then((res) => {
        setIsLoading(false);
        setOrders(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [archived]);
  return (
    <div className="mt-8 px-24 pb-20 lg:px-8 md:px-8 sm:px-4 xl:px-12">
      <button
        onClick={() => setArchived(!archived)}
        className={"primary-button block w-fit font-medium !rounded-none"}
      >
        <span className="px-8 py-1 block">
          {archived ? "View active orders" : "View archived orders"}
        </span>
      </button>
      {(!isLoading && orders.length === 0 && (
        <div className="text-center mt-2 text-lg font-medium bg-black/20 py-4">
          No orders found
        </div>
      )) || (
        <div className="grid grid-cols-5 bg-gray-200 mt-4 px-4 py-2 lg:grid-cols-4 md:grid-cols-4 sm:hidden">
          <div>Number</div>
          <div>User</div>
          <div>Date</div>
          <div>Status</div>
          <div className="lg:hidden md:hidden">Total</div>
        </div>
      )}
      <div className="flex flex-col gap-y-4 sm:mt-8">
        {orders.map((order, index) => (
          <AdminOrderCard order={order} index={index} />
        ))}
      </div>
    </div>
  );
};

export default AdminOrders;

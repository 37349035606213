import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import confirm from "../static/images/confirm.svg";
import axios from "axios";
import { auth_url, api_url } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { userLoaded } from "../store/authSlice";
import { Helmet } from "react-helmet-async";
const ResetEmail = () => {
  const auth = useSelector((state) => state.auth);
  const { uid, token } = useParams();
  const [isReset, setIsReset] = useState(false);
  const dispatch = useDispatch();
  const userPage = useSelector((state) => state.userPage);

  function ResetEmailHandler(e) {
    e.preventDefault();
    const form = e.target;
    const data = {
      new_email: form.email.value,
      re_new_email: form.email.value,
      uid: uid,
      token: token,
    };
    axios
      .post(
        `${auth_url}/users/reset_email_confirm/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
        //add the csrf
      )
      .then((res) => {
        setIsReset(true);
        axios
          .get(api_url + "/user/", {
            params: {
              token: auth.access,
            },
          })
          .then((res) => {
            console.log(res.data);
            dispatch(userLoaded(res.data));
          })
          .catch((err) => {});
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className=" px-52 py-8">
      <Helmet>
        <title>{userPage.title}</title>
        <meta name="description" content={userPage.description} />
        <meta name="keywords" content={userPage.keywords} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Apakz" />
        <link rel="canonical" href={"https://apakz.com" + userPage.slug} />
      </Helmet>
      <div className="bg-white py-16 px-8 rounded-xl">
        {isReset ? (
          <div className="flex flex-col items-center justify-center">
            <img src={confirm} alt="confirm" className="w-20" />
            <h1 className="text-2xl font-semibold mt-4">
              Your email has been reset
            </h1>
            <p className="text-center mt-2 text-sm">
              You can now login with your new email.
            </p>
          </div>
        ) : (
          <div>
            <h1 className="w-fit mx-auto text-center text-3xl font-bold">
              Reset your email
            </h1>
            <p className="w-fit mx-auto text-center text-sm font-medium mt-1">
              Re-enter your new email below. Check it twice to make sure it's
              just as you'd like.
            </p>
            <form
              className="mt-6 flex flex-col gap-y-4 w-1/3 mx-auto"
              onSubmit={ResetEmailHandler}
            >
              <input
                type="email"
                placeholder="Your new email"
                className="user-input"
                name="email"
              />
              <button className="primary-button py-2 font-semibold rounded-lg mx-auto w-fit px-12 mt-2">
                Reset Email
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetEmail;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  slug: "",
  description: "",
  keywords: "",
};

const userPageSlice = createSlice({
  name: "userPage",
  initialState,
  reducers: {
    setUserPage(state, action) {
      state.title = action.payload.title;
      state.slug = action.payload.slug;
      state.description = action.payload.description;
      state.keywords = action.payload.keywords;
      return state;
    },
  },
});

export const { setUserPage } = userPageSlice.actions;

export default userPageSlice.reducer;

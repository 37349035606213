import React, { useEffect, useState } from "react";
import axios from "axios";
import { api_url } from "../constants";
import { useParams } from "react-router-dom";

const AdminContact = () => {
  const { id } = useParams();
  const [message, setMessage] = useState({});
  useEffect(() => {
    axios
      .get(api_url + "/contact/" + id + "/")
      .then((res) => {
        setMessage(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return <div>AdminContact</div>;
};

export default AdminContact;

import React, { useState } from "react";
import edit_2 from "../static/images/edit_2.svg";
import trashcan from "../static/images/trashcan.svg";
import { motion } from "framer-motion";
import axios from "axios";
import { api_url } from "../constants";
import { useSelector, useDispatch } from "react-redux";
import { setGlobalErrorMessage } from "../store/errorMessageSlice";
const AddressCard = ({
  address,
  addresses,
  setAddresses,
  availableCountries,
}) => {
  const auth = useSelector((state) => state.auth);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  function ModifyAddress(e) {
    e.preventDefault();
    const name = e.target.name.value;
    const phone = e.target.phone.value;
    const country = e.target.country.value;
    const city = e.target.city.value;
    const postal_code = e.target.postal_code.value;
    const address = e.target.address.value;

    if (
      name.length < 3 ||
      phone.length < 3 ||
      country.length < 3 ||
      city.length < 3 ||
      postal_code < 3 ||
      address.length < 3
    ) {
      dispatch(
        setGlobalErrorMessage("The entered details are not in a valid format")
      );
      return;
    }
    axios
      .put(`${api_url}/user/shipping-address/${address.id}/`, {
        name,
        phone,
        country,
        city,
        postal_code,
        address,
        token: auth.access,
      })
      .then((res) => {
        setAddresses(
          addresses.map((c) => {
            if (c.id === address.id) {
              return res.data;
            } else {
              return c;
            }
          })
        );
        setIsEditing(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function DeleteAddress() {
    axios
      .delete(`${api_url}/user/shipping-address/${address.id}/`, {
        params: {
          token: auth.access,
        },
      })
      .then((res) => {
        setAddresses(addresses.filter((c) => c.id !== address.id));
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className="bg-white  rounded-3xl group drop-shadow-hard">
      {isEditing ? (
        <motion.form
          onSubmit={ModifyAddress}
          initial={{ opacity: 0, display: "none" }}
          animate={{
            opacity: isEditing ? 1 : 0,
            display: isEditing ? "block" : "none",
          }}
          transition={{
            duration: 0.2,
            ease: "easeInOut",
            display: { delay: isEditing ? 0 : 0.2 },
          }}
          className="py-8 px-6"
        >
          <input
            defaultValue={address.name}
            className="user-input"
            placeholder="Full name"
            name="name"
          />
          <div className="mt-2 flex flex-col">
            <label className="user-input-label">Phone Number::</label>
            <input
              defaultValue={address.phone}
              className="user-input w-4/5"
              placeholder="Phone number"
              name="phone"
            />
          </div>
          <div className="mt-1 flex flex-col">
            <label className="user-input-label">Country:</label>
            <select
              defaultValue={address.country.id}
              className="user-input w-4/5"
              placeholder="Country"
              name="country"
            >
              {availableCountries.map((country) => (
                <option value={country.id}>{country.name}</option>
              ))}
            </select>
          </div>
          <div className="flex gap-x-2 w-fit mt-1 justify-between">
            <div className="mt-1 flex flex-col">
              <label className="user-input-label">City:</label>
              <input
                defaultValue={address.city}
                className="user-input w-full"
                placeholder="City"
                name="city"
              />
            </div>
            <div className="mt-1 flex flex-col">
              <label className="user-input-label">Postcode:</label>
              <input
                defaultValue={address.postal_code}
                className="user-input w-full"
                placeholder="Postcode"
                name="postal_code"
              />
            </div>
          </div>
          <div className="mt-1 flex flex-col">
            <label className="user-input-label">Adress:</label>
            <input
              defaultValue={address.address}
              className="user-input w-4/5"
              placeholder="Address"
              name="address"
            />
          </div>
          <button className="primary-button px-4 py-1 mt-4 w-fit block ml-auto text-sm font-semibold">
            Apply changes
          </button>
        </motion.form>
      ) : (
        <div className="relative flex">
          <div className="w-4/5">
            <div className="py-6 px-6">
              <div className="font-semibold text-xl">{address.name}</div>
              <div className="mt-4 flex flex-col">
                <span className="user-input-label">Phone Number: </span>
                <span className="font-medium">{address.phone}</span>
              </div>
              <div className="mt-2 flex flex-col">
                <span className="user-input-label">Country: </span>
                <span className="font-medium">{address.country.name}</span>
              </div>
              <div className="flex mt-2 gap-x-8">
                <div className="flex flex-col">
                  <span className="user-input-label">City: </span>
                  <span className="font-medium">{address.city}</span>
                </div>
                <div className="flex flex-col">
                  <span className="user-input-label">Postcode: </span>
                  <span className="font-medium">{address.postal_code}</span>
                </div>
              </div>
              <div className="mt-2 flex flex-col">
                <span className="user-input-label">Adress: </span>
                <span className="font-medium">{address.address}</span>
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex flex-col gap-y-4 left-1/2  relative top-1/2 -translate-y-1/2">
              <div
                onClick={() => setIsEditing(true)}
                className="w-10 p-2 bg-gray-200 hover:scale-105 ease-in-out duration-200 transition-transform cursor-pointer rounded-full"
              >
                <img src={edit_2} className=" " />
              </div>
              <div
                onClick={DeleteAddress}
                className="w-10 p-2 bg-gray-200 hover:scale-105 ease-in-out duration-200 transition-transform cursor-pointer rounded-full"
              >
                <img src={trashcan} className=" " />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressCard;

import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import { api_url } from "../constants";
import ImageCropModal from "../components/ImageCropModal";
import change from "../static/images/change.svg";
import AdminServiceCard from "../components/AdminServiceCard";
import { changePage } from "../store/adminPageSlice";
import { useDispatch } from "react-redux";
const AdminServices = () => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [resultFile, setResultFile] = useState(null);
  const [toggleForm, setToggleForm] = useState(false);
  const [services, setServices] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changePage("Services"));
    axios
      .get(api_url + "/services/")
      .then((res) => {
        setServices(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function selectFile(e) {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setFileType(file.type);
      setFile(file);
      setShowModal(true);
      document.body.style.overflow = "hidden";
      e.target.value = null;
    } else {
      e.target.value = null;
      setShowModal(false);
      document.body.style.overflow = "auto";
    }
  }

  function exitModal() {
    setShowModal(false);
    document.body.style.overflow = "auto";
    setFile(null);
  }

  function CreateService(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("image", resultFile);
    formData.append("image_type", fileType.split("/")[1]);
    axios
      .post(api_url + "/service/", formData)
      .then((res) => {
        console.log(res.data);
        setToggleForm(false);
        e.target.reset();
        setResultImage(null);
        setResultFile(null);
        const newServices = [...services];
        newServices.push(res.data);
        setServices(newServices);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function RemoveService(id) {
    const newServices = [...services];
    newServices.splice(
      newServices.findIndex((service) => service.id === id),
      1
    );
    setServices(newServices);
  }

  function ChangeService(id, service) {
    const newServices = [...services];
    newServices[newServices.findIndex((service) => service.id === id)] =
      service;
    setServices(newServices);
  }

  return (
    <div className="mt-8 px-24 lg:px-8 md:px-8 sm:px-4 xl:px-12 pb-20">
      <motion.button
        initial={{ opacity: 100, height: "auto" }}
        animate={{
          opacity: toggleForm ? 0 : 100,
          height: toggleForm ? 0 : "auto",
        }}
        transition={{ duration: 0.3 }}
        className={
          "primary-button  font-medium !rounded-none " +
          (toggleForm ? "cursor-default" : "cursor-pointer")
        }
        onClick={() => setToggleForm(true)}
      >
        <span className="px-8 py-1 block">Add new service</span>
      </motion.button>
      <motion.form
        onSubmit={CreateService}
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: toggleForm ? "auto" : 0,
          opacity: toggleForm ? 1 : 0,
        }}
        exit={{ height: 0, opacity: 0 }}
        transition={{ duration: 0.3 }}
        className={"w-full overflow-auto scrollbar-none"}
      >
        <h2>Complete the for to add a new service</h2>
        <div className="grid grid-cols-3 lg:grid-cols-2 md:grid-cols-2 gap-y-8 sm:grid-cols-1 mt-1 w-full gap-x-8">
          {resultImage ? (
            <label className="w-full primary-border group relative cursor-pointer">
              <img src={resultImage} className="w-full" />
              <div className="absolute top-0 left-0 right-0 cursor-pointer transition-colors ease-in-out duration-300 bottom-0 group-hover:bg-black/25 bg-transparent"></div>
              <img
                src={change}
                className="w-10 absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%] group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition opacity-0 group-hover:opacity-100 scale-150"
              />
              <input
                ref={file}
                onChange={selectFile}
                id="service-graphics"
                type="file"
                className="hidden"
              />
            </label>
          ) : (
            <label
              for="service-graphics"
              className="cursor-pointer min-h-[20rem] group w-full bg-transparent primary-border flex items-center text-center justify-center"
            >
              <div className="w-fit mx-auto group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition-transform text-6xl font-regular scale-150 text-black">
                +
              </div>
              <input
                ref={file}
                onChange={selectFile}
                id="service-graphics"
                type="file"
                className="hidden"
              />
            </label>
          )}
          <div className="flex flex-col gap-y-4">
            <input
              name={"title"}
              placeholder="Header"
              className="admin-input w-full"
            />
            <textarea
              name={"description"}
              placeholder="Description"
              className="min-h-[10rem] admin-input w-full"
            ></textarea>
            <input
              name={"note"}
              placeholder="Note"
              className="admin-input w-full"
            />
          </div>
          <div className="flex flex-col gap-y-4">
            <input
              name="seo_title"
              placeholder="SEO Title"
              className="admin-input w-full"
            />
            <textarea
              name={"seo_description"}
              placeholder="SEO Description"
              className="min-h-[10rem] admin-input w-full"
            ></textarea>
            <input
              name={"seo_keywords"}
              placeholder="SEO Keywords"
              className="admin-input w-full"
            />
            <div className="w-fit ml-auto gap-x-4 flex sm:flex-col gap-y-2 sm:w-full">
              <button
                type={"button"}
                onClick={() => setToggleForm(false)}
                className="primary-button rounded-none px-10 py-1 bg-secondary sm:w-full block font-semibold"
              >
                Cancel
              </button>
              <button
                className="primary-button rounded-none px-10 py-1 font-semibold sm:w-full block"
                type="submit"
              >
                New Service
              </button>
            </div>
          </div>
        </div>
      </motion.form>
      <ImageCropModal
        setResultFile={setResultFile}
        setResultImage={setResultImage}
        file={file}
        showModal={showModal}
        exitModal={exitModal}
      />
      <div className="flex flex-col gap-y-10 mt-6">
        {services.map((service) => (
          <AdminServiceCard
            key={service.id}
            changeService={ChangeService}
            removeService={RemoveService}
            service={service}
          />
        ))}
      </div>
    </div>
  );
};

export default AdminServices;

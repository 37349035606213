import branding from "./static/images/branding.svg";
import finger_snap from "./static/images/finger-snap.svg";
import open_box from "./static/images/open-box.svg";
import phone_graphics from "./static/images/phone-graphics.svg";
import sketch from "./static/images/sketch.svg";
import versatility from "./static/images/versatility.svg";
import web_design from "./static/images/web-design.svg";
import solutions from "./static/images/solutions.svg";
import premium from "./static/images/premium.svg";
import what_do_we_do_design from "./static/images/what-do-we-do-design.png";
import what_do_we_do_packaging from "./static/images/what-do-we-do-packaging.png";
import what_do_we_do_printing from "./static/images/what-do-we-do-printing.png";
import leaves from "./static/images/leaves.svg";
import quality from "./static/images/quality.svg";

const debug = false;
const base_url = debug ? "http://localhost:8000" : "";
export const api_url = base_url + "/api";
export const media_url = debug ? base_url + "/api" : "/media";
export const auth_url = base_url + "/auth";
export const jwt_url = base_url + "/jwt";
export const shipping_types = [
  "Standard Shipping",
  "Express Shipping",
  "Economy Shipping",
];

export const statuses = [
  {
    status: "pending",
    color: "bg-yellow-500",
  },
  {
    status: "ongoing",
    color: "bg-blue-500",
  },
  {
    status: "completed",
    color: "bg-green-500",
  },
  {
    status: "cancelled",
    color: "bg-red-500",
  },
];

export const countries = [
  {
    name: "Moldova",
    code: "MD",
  },
  {
    name: "Romania",
    code: "RO",
  },
  {
    name: "Russia",
    code: "RU",
  },
  {
    name: "Ukraine",
    code: "UA",
  },
  {
    name: "United States",
    code: "US",
  },
  {
    name: "United Kingdom",
    code: "GB",
  },
  {
    name: "Germany",
    code: "DE",
  },
  {
    name: "France",
    code: "FR",
  },
  {
    name: "Italy",
    code: "IT",
  },
  {
    name: "Spain",
    code: "ES",
  },
  {
    name: "Poland",
    code: "PL",
  },
  {
    name: "Turkey",
    code: "TR",
  },
  {
    name: "Netherlands",
    code: "NL",
  },
  {
    name: "Belgium",
    code: "BE",
  },
  {
    name: "Czech Republic",
    code: "CZ",
  },
  {
    name: "Greece",
    code: "GR",
  },
  {
    name: "Portugal",
    code: "PT",
  },
  {
    name: "Sweden",
    code: "SE",
  },
  {
    name: "Hungary",
    code: "HU",
  },
  {
    name: "Belarus",
    code: "BY",
  },
  {
    name: "Austria",
    code: "AT",
  },
  {
    name: "Serbia",
    code: "RS",
  },
  {
    name: "Switzerland",
    code: "CH",
  },
  {
    name: "Bulgaria",
    code: "BG",
  },
  {
    name: "Denmark",
    code: "DK",
  },
  {
    name: "Finland",
    code: "FI",
  },
  {
    name: "Slovakia",
    code: "SK",
  },
  {
    name: "Norway",
    code: "NO",
  },
  {
    name: "Ireland",
    code: "IE",
  },
  {
    name: "Croatia",
    code: "HR",
  },
  {
    name: "Moldova",
    code: "MD",
  },
  {
    name: "Lithuania",
    code: "LT",
  },
  {
    name: "Slovenia",
    code: "SI",
  },
  {
    name: "Latvia",
    code: "LV",
  },
];

export const what_do_we_do = [
  {
    image: what_do_we_do_packaging,
    header: "Packaging",
    link: "/service/packaging",
    iconsAndText: [
      {
        icon: finger_snap,
        header: "Fast & Easy Order",
        text: "We design, we proof, we ship",
      },
      {
        icon: leaves,
        header: "Sustainable Materials",
        text: "Eco-Friendly",
      },
      {
        icon: quality,
        header: "Convent Prices, Best Quality",
        text: "Experience the perfect balance of affordable prices and superior quality with us",
      },
      {
        icon: sketch,
        header: "Custom Design & Size",
        text: "Get out of crowd with your unique design, created by our creative team",
      },
    ],
  },
  {
    image: what_do_we_do_design,
    header: "Design Services",
    link: "/service/design",

    iconsAndText: [
      {
        icon: open_box,
        header: "Packaging Design",
        text: "Visually striking packaging designs that stand out and capture attention",
      },
      {
        icon: phone_graphics,
        header: "Graphic Design",
        text: "Creative visuals that make a lasting impact",
      },
      {
        icon: branding,
        header: "Branding",
        text: "Crafting identities that resonate with your audience",
      },
      {
        icon: web_design,
        header: "Web Design",
        text: "Creating seamless digital experiences that impress and engage",
      },
    ],
  },
  {
    image: what_do_we_do_printing,
    header: "Printing",
    link: "/service/printing",

    iconsAndText: [
      {
        icon: quality,
        header: "Exceptional Quality",
        text: "Delivering top-notch quality with precision and accuracy",
      },
      {
        icon: versatility,
        header: "Versatility",
        text: "Printing a diverse range of items to meet your business needs",
      },
      {
        icon: solutions,
        header: "Personalized Solutions",
        text: "Providing customized solutions that align with your branding and objectives",
      },
      {
        icon: premium,
        header: "Premium Materials",
        text: "We use high-quality materials that add a touch of luxury to your brand",
      },
    ],
  },
];

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearErrorMessage, clearShowError } from "../store/errorMessageSlice";
import { motion } from "framer-motion";
const ErrorMessage = () => {
  const errorMessage = useSelector((state) => state.errorMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMessage.showError) {
      setTimeout(() => {
        dispatch(clearShowError());
        setTimeout(() => {
          dispatch(clearErrorMessage());
        }, 500);
      }, 3000);
    }
  }, [errorMessage.showError]);

  return (
    <motion.div
      initial={{ x: "125%" }}
      animate={{ x: errorMessage.showError ? 0 : "125%" }}
      exit={{ x: "125%" }}
      transition={{ duration: 0.5 }}
      className={
        " px-8 fixed bottom-8 right-4 z-50 py-2 font-semibold drop-shadow-lg rounded-md " +
        (errorMessage.type === "error"
          ? "text-danger bg-gray-400"
          : "text-white bg-primary")
      }
    >
      {errorMessage.errorMessage}
    </motion.div>
  );
};

export default ErrorMessage;

import React from "react";
import { useNavigate } from "react-router-dom";
import search from "../static/images/search.svg";
const SearchBar = () => {
  const navigate = useNavigate();
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const q = formData.get("q");
        if (q) {
          navigate("/search?q=" + q);
        }
      }}
      className="relative sm:w-full"
    >
      <input
        name="q"
        placeholder="Search..."
        className="bg-transparent rounded-full border-2 border-black border-solid px-4 py-1 w-60 md:w-80 sm:w-full"
      />
      <button>
        <img
          src={search}
          className="absolute right-4 top-1/2 translate-y-[-50%] w-4"
        />
      </button>
    </form>
  );
};

export default SearchBar;

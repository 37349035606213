import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import OptionTip from "./OptionTip";
import info from "../static/images/info.svg";

const ToggleOption = ({ option, selectedOptions, setSelectedOptions }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    //check if the option already exists in the selectedOptions array
    const index = selectedOptions.findIndex(
      (item) => item.question == option.question
    );
    if (index != -1) {
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions.splice(index, 1);
      setSelectedOptions([
        ...newSelectedOptions,
        {
          type: "toggle_button_options",
          id: option.id,
          question: option.question,
          answer: selected,
          price_99: selected ? option.price_99 : 0,
          price_499: selected ? option.price_499 : 0,
          price_999: selected ? option.price_999 : 0,
          price_4999: selected ? option.price_4999 : 0,
          price_9999: selected ? option.price_9999 : 0,
        },
      ]);
    } else {
      setSelectedOptions([
        ...selectedOptions,
        {
          type: "toggle_button_options",
          question: option.question,
          answer: selected,
          price: selected ? option.price : 0,
          price_per_unit: selected ? option.price_per_unit : 0,
        },
      ]);
    }
  }, [selected]);
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="group flex sm:flex-col gap-x-2 w-fit items-center">
      <div className="flex gap-x-2">
        <div
          onClick={() => setSelected(!selected)}
          className="rounded-full p-0.5 border-solid cursor-pointer border-black border-2 w-14 h-7"
        >
          <motion.div
            initial={{ x: 0 }}
            animate={selected ? { x: 28 } : { x: 0 }}
            className={
              "h-full w-5 transition-colors ease-in-out duration-200 rounded-full p-[0.075rem] border-solid border-black border-2 " +
              (selected ? "bg-black" : "")
            }
          >
            <div className="w-full h-full bg-black rounded-full mx-auto my-auto"></div>
          </motion.div>
        </div>

        <h3
          onClick={() => setExpanded(!expanded)}
          className="text-lg sm:cursor-pointer whitespace-nowrap font-bold flex items-center gap-x-2"
        >
          {option.question}{" "}
          {option.tip && option.tip.length > 0 && (
            <img src={info} className="3" />
          )}
        </h3>
      </div>
      <div className="w-fit">
        {option.tip && option.tip.length > 0 && (
          <OptionTip expanded={expanded}>{option.tip}</OptionTip>
        )}
      </div>
    </div>
  );
};

export default ToggleOption;

import React, { useState } from "react";
import edit_2 from "../static/images/edit_2.svg";
import trashcan from "../static/images/trashcan.svg";
import { motion } from "framer-motion";
import axios from "axios";
import { api_url } from "../constants";
import { useSelector, useDispatch } from "react-redux";
import { setGlobalErrorMessage } from "../store/errorMessageSlice";
const CompanyCard = ({ company, companies, setCompanies }) => {
  const auth = useSelector((state) => state.auth);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  function ModifyCompany(e) {
    e.preventDefault();
    const name = e.target.name.value;
    const registration_number = e.target.registration_number.value;
    const vat_number = e.target.vat_number.value;
    const address = e.target.address.value;
    if (
      name.length < 3 ||
      registration_number.length < 3 ||
      vat_number.length < 3 ||
      address.length < 3
    ) {
      dispatch(
        setGlobalErrorMessage("The entered details are not in a valid format")
      );
      return;
    }

    axios
      .put(`${api_url}/user/company/${company.id}/`, {
        name,
        registration_number,
        vat_number,
        address,
        token: auth.access,
      })
      .then((res) => {
        setCompanies(
          companies.map((c) => {
            if (c.id === company.id) {
              return res.data;
            } else {
              return c;
            }
          })
        );
        setIsEditing(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function DeleteCompany() {
    axios
      .delete(`${api_url}/user/company/${company.id}/`, {
        params: {
          token: auth.access,
        },
      })
      .then((res) => {
        setCompanies(companies.filter((c) => c.id !== company.id));
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className="bg-white  rounded-3xl group drop-shadow-hard">
      {isEditing ? (
        <motion.form
          onSubmit={ModifyCompany}
          initial={{ opacity: 0, display: "none" }}
          animate={{
            opacity: isEditing ? 1 : 0,
            display: isEditing ? "block" : "none",
          }}
          transition={{
            duration: 0.2,
            ease: "easeInOut",
            display: { delay: isEditing ? 0 : 0.2 },
          }}
          className="py-8 px-6"
        >
          <input
            defaultValue={company.name}
            className="user-input"
            placeholder="Company name"
            name="name"
          />
          <div className="mt-2 flex flex-col">
            <label className="user-input-label">Registration number:</label>
            <input
              defaultValue={company.registration_number}
              className="user-input w-4/5"
              placeholder="Registration number"
              name="registration_number"
            />
          </div>
          <div className="mt-1 flex flex-col">
            <label className="user-input-label">VAT number:</label>
            <input
              defaultValue={company.vat_number}
              className="user-input w-4/5"
              placeholder="VAT number"
              name="vat_number"
            />
          </div>
          <div className="mt-1 flex flex-col">
            <label className="user-input-label">Adress:</label>
            <input
              defaultValue={company.address}
              className="user-input w-4/5"
              placeholder="Address"
              name="address"
            />
          </div>
          <div className="flex justify-between">
            <button
              onClick={() => setIsEditing(false)}
              className="secondary-button px-4 py-1 mt-4 w-fit block text-sm font-semibold"
            >
              Cancel
            </button>
            <button className="primary-button px-4 py-1 mt-4 w-fit block ml-auto text-sm font-semibold">
              Apply changes
            </button>
          </div>
        </motion.form>
      ) : (
        <div className="relative flex">
          <div className="w-4/5">
            <div className="py-6 px-6">
              <div className="font-semibold text-xl">{company.name}</div>
              <div className="mt-4 flex flex-col">
                <span className="user-input-label">Registration number: </span>
                <span className="font-medium">
                  {company.registration_number}
                </span>
              </div>
              <div className="mt-2 flex flex-col">
                <span className="user-input-label">VAT number: </span>
                <span className="font-medium">{company.vat_number}</span>
              </div>
              <div className="mt-2 flex flex-col">
                <span className="user-input-label">Adress: </span>
                <span className="font-medium">{company.address}</span>
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex flex-col gap-y-4 left-1/2  relative top-1/2 -translate-y-1/2">
              <div
                onClick={() => setIsEditing(true)}
                className="w-10 p-2 bg-gray-200 hover:scale-105 ease-in-out duration-200 transition-transform cursor-pointer rounded-full"
              >
                <img src={edit_2} className=" " />
              </div>
              <div
                onClick={DeleteCompany}
                className="w-10 p-2 bg-gray-200 hover:scale-105 ease-in-out duration-200 transition-transform cursor-pointer rounded-full"
              >
                <img src={trashcan} className=" " />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyCard;

import React, { useEffect, useState } from "react";
import ImageCropModal from "./ImageCropModal";

const AddPhotoOption = ({ photoOptions, setPhotoOptions }) => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [resultFile, setResultFile] = useState(null);

  function selectFile(e) {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setFile(file);
      setShowModal(true);
      document.body.style.overflow = "hidden";
      e.target.value = null;
    } else {
      e.target.value = null;
      setShowModal(false);
      document.body.style.overflow = "auto";
    }
  }

  function exitModal() {
    setShowModal(false);
    document.body.style.overflow = "auto";
    setFile(null);
  }
  function imageSetter(image) {
    setResultImage(image);
  }
  function fileSetter(file) {
    setResultFile(file);
  }
  useEffect(() => {
    if (resultFile && resultImage) {
      console.log(resultFile);
      const newPhotoOptions = [
        ...photoOptions,
        {
          image: resultImage,
          file: resultFile,
        },
      ];
      setPhotoOptions(newPhotoOptions);
      setResultFile(null);
      setResultImage(null);
    } else {
      console.log("no file");
    }
  }, [resultFile, resultImage]);
  return (
    <div className="z-50">
      <label
        for={"photo-option-add"}
        className="h-[7.5rem] w-[7.5rem] group flex items-center text-center text-3xl primary-border "
      >
        <div className="w-fit mx-auto group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition-transform text-4xl font-regular scale-150 text-black">
          +
        </div>
        <input
          ref={file}
          onChange={selectFile}
          id={"photo-option-add"}
          type="file"
          className="hidden"
        />
      </label>
      <ImageCropModal
        showModal={showModal}
        exitModal={exitModal}
        file={file}
        setResultFile={fileSetter}
        setResultImage={imageSetter}
      />
    </div>
  );
};

export default AddPhotoOption;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { api_url } from "../constants";
import { motion } from "framer-motion";
import edit from "../static/images/edit.svg";
const AdminBundles = () => {
  const [bundles, setBundles] = useState([]);

  useEffect(() => {
    axios
      .get(api_url + "/bundles/")
      .then((res) => {
        setBundles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="mt-8 px-24 pb-20  lg:px-8 md:px-8 sm:px-4 xl:px-12">
      <Link
        to="/admin/bundles/new"
        className={"primary-button block w-fit font-medium !rounded-none"}
      >
        <span className="px-8 py-1 block">Add new bundle</span>
      </Link>
      <div className="grid grid-cols-2 sm:grid-cols-1
       mt-4 gap-y-4 gap-x-4">
        {bundles.map((bundle) => (
          <motion.div
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.99 }}
            transition={{ duration: 0.2 }}
            key={bundle.id}
            className="bg-white p-4 drop-shadow-sm"
          >
            <Link
              to={"/admin/bundle/" + bundle.id}
              className="flex group justify-between"
            >
              <div>
                <h2 className="font-semibold text-lg">{bundle.name}</h2>
                <p className="text-sm font-medium">
                  {bundle.products.length} products
                </p>
              </div>
              <img
                src={edit}
                className="w-5 group-hover:opacity-100 opacity-0 transition-opacity ease-in-out duration-200"
              />
            </Link>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default AdminBundles;

import React, { useEffect, useState } from "react";
import profile_selected from "../static/images/profile-selected.svg";
import profile_unselected from "../static/images/profile-unselected.svg";
import order_history_selected from "../static/images/order-history-selected.svg";
import order_history_unselected from "../static/images/order-history-unselected.svg";
import favourites_selected from "../static/images/favourites-selected.svg";
import favourites_unselected from "../static/images/favourites-unselected.svg";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { auth_url, api_url } from "../constants";
import { useSelector, useDispatch } from "react-redux";
import { show } from "../store/showLoginSlice";

const UserAccountLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const showLogin = useSelector((state) => state.showLogin);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(
    location.pathname.split("/")[2]
  );
  useEffect(() => {
    axios
      .post(
        `${auth_url}/jwt/verify/`,
        {
          token: auth.access,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {})
      .catch((err) => {
        dispatch(show(false));
        navigate("/");
        console.log(err);
      });
    if (location.pathname == "/account" || location.pathname == "/account/") {
      navigate("/account/profile");
    }
  }, []);

  const options = [
    {
      name: "Profile",
      url: "profile",
      selectedIcon: profile_selected,
      unselectedIcon: profile_unselected,
    },
    {
      name: "Order History",
      url: "order-history",
      selectedIcon: order_history_selected,
      unselectedIcon: order_history_unselected,
    },
    {
      name: "Favourites",
      url: "favourites",
      selectedIcon: favourites_selected,
      unselectedIcon: favourites_unselected,
    },
  ];

  return (
    <div className="mx-52 mt-10 md:mt-6 xl:mx-40 lg:mx-12 md:mx-6 sm:mx-6 sm:mt-6">
      <div className="grid grid-cols-4 gap-x-12 md:flex md:flex-col sm:flex sm:flex-col gap-y-8">
        <div className="bg-[#F8F8F8] h-fit rounded-xl p-4 md:min-w-[20rem] w-full md:w-1/2 md:mx-auto sm:w-full">
          <h1 className="text-xl font-bold">Account</h1>
          <div className="mt-2 flex flex-col gap-y-4">
            {options.map((option) => (
              <Link
                to={"/account/" + option.url}
                onClick={() => setSelectedOption(option.url)}
                className={
                  "account-option " +
                  (selectedOption == option.url
                    ? "bg-primary hover:bg-primary text-white font-bold shadow-inner"
                    : "")
                }
              >
                {selectedOption == option.url ? (
                  <img src={option.selectedIcon} className="w-5" />
                ) : (
                  <img src={option.unselectedIcon} className="w-5" />
                )}
                {option.name}
              </Link>
            ))}
          </div>
        </div>
        <div className="col-span-3">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default UserAccountLayout;

import React from "react";
import { motion } from "framer-motion";
import useMediaQuery from "./../hooks/useMediaQuery";
const OptionTip = ({ children, expanded = false }) => {
  const isSM = useMediaQuery("(max-width: 640px)");
  return (
    <motion.div
      initial={{ height: isSM ? 0 : "fit-content" }}
      animate={{
        height: isSM && expanded ? "fit-content" : isSM ? 0 : "fit-content",
      }}
      transition={{ duration: 0.3, ease: "linear" }}
      className={
        "rounded-lg overflow-y-auto sm:mt-1 scrollbar-none sm:w-full h-fit my-auto transition-all  ease-in-out duration-300 font-bold text-black/75 opacity-0 sm:opacity-100 group-hover:opacity-100 px-4 bg-white/80" +
        (!isSM ? " group-hover:translate-x-3" : "")
      }
    >
      <div className="py-0.5 text-xs">{children}</div>
    </motion.div>
  );
};

export default OptionTip;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errorMessage: "",
  showError: false,
  type: "error",
};

export const errorMessageSlice = createSlice({
  name: "errorMessage",
  initialState,
  reducers: {
    setGlobalErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
      state.showError = true;
      state.type = "error";
    },
    setGlobalNotificationMessage: (state, action) => {
      state.errorMessage = action.payload;
      state.showError = true;
      state.type = "notification";
    },
    clearErrorMessage: (state) => {
      state.errorMessage = "";
    },
    clearShowError: (state) => {
      state.showError = false;
    },
  },
});

export const {
  setGlobalErrorMessage,
  setGlobalNotificationMessage,
  clearErrorMessage,
  clearShowError,
} = errorMessageSlice.actions;

export default errorMessageSlice.reducer;

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { api_url } from "../constants";
import AdminFAQCard from "../components/AdminFAQCard";
import { changePage } from "../store/adminPageSlice";
import { useDispatch } from "react-redux";
const AdminFAQ = () => {
  const [toggleForm, setToggleForm] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changePage("Frequently Asked Questions"));
    axios.get(api_url + "/frequently-asked-questions/").then((res) => {
      setFaqs(res.data);
    });
  }, []);
  function CreateFAQ(e) {
    e.preventDefault();
    const question = e.target.question.value;
    const answer = e.target.answer.value;
    axios
      .post(api_url + "/frequently-asked-question/", {
        question,
        answer,
      })
      .then((res) => {
        console.log(res.data);
        setFaqs([...faqs, res.data]);
        setToggleForm(false);
      });
  }

  return (
    <div className="mt-8 px-24 md:px-8 sm:px-4 xl:px-12 pb-20">
      <motion.button
        initial={{ opacity: 100, height: "auto" }}
        animate={{
          opacity: toggleForm ? 0 : 100,
          height: toggleForm ? 0 : "auto",
        }}
        transition={{ duration: 0.3 }}
        className={
          "primary-button  font-medium !rounded-none " +
          (toggleForm ? "cursor-default" : "cursor-pointer")
        }
        onClick={() => setToggleForm(true)}
      >
        <span className="px-8 py-1 block">Add new FAQ</span>
      </motion.button>
      <motion.form
        onSubmit={CreateFAQ}
        initial={{ height: 0, opacity: 0, y: 0 }}
        animate={{
          height: toggleForm ? "auto" : 0,
          opacity: toggleForm ? 1 : 0,
          y: toggleForm ? -20 : 0,
        }}
        exit={{ height: 0, opacity: 0 }}
        transition={{ duration: 0.3 }}
        className={"w-full mt-4 overflow-auto scrollbar-none"}
      >
        <div className="flex justify-between items-center">
          <h2 className="font-semibold">New frequtent asked question form </h2>
          <button className="primary-button rounded-none !px-8 !py-1">
            Add FAQ
          </button>
        </div>
        <div className="flex flex-col mt-2 gap-y-2 w-1/2">
          <input
            type="text"
            placeholder="Question"
            name="question"
            className="admin-input"
          />
          <textarea
            placeholder="Answer"
            name="answer"
            rows={5}
            className="admin-input min-h-[10rem]"
          />
        </div>
      </motion.form>
      <div className="flex flex-col gap-y-2 mt-4">
        {faqs.map((faq) => (
          <AdminFAQCard faqs={faqs} setFaqs={setFaqs} key={faq.id} faq={faq} />
        ))}
      </div>
    </div>
  );
};

export default AdminFAQ;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { api_url } from "../constants";
import { useNavigate } from "react-router-dom";
import { changePage } from "../store/adminPageSlice";
import { useDispatch } from "react-redux";
const AdminSeoAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [keywords, setKeywords] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    dispatch(changePage("Add page SEO"));
  }, []);

  function CreatePageSEO(e) {
    e.preventDefault();
    const data = {
      title: title,
      slug: slug,
      keywords: keywords,
      description: description,
    };
    axios
      .post(`${api_url}/page/seo/`, data)
      .then((res) => {
        navigate("/admin/seo");
      })
      .catch((err) => console.log(err));
  }
  return (
    <form onSubmit={CreatePageSEO} className="mt-8 px-24 pb-20">
      <div className="grid grid-cols-2 w-fit gap-x-4">
        <textarea
          onChange={(e) => setDescription(e.target.value)}
          className="admin-input"
          placeholder="Description"
          value={description}
        />
        <div className="flex flex-col gap-y-2">
          <input
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            className="admin-input"
            placeholder="Page title"
          />
          <input
            onChange={(e) => setSlug(e.target.value)}
            value={slug}
            className="admin-input"
            placeholder="Slug"
          />
          <input
            onChange={(e) => setKeywords(e.target.value)}
            value={keywords}
            className="admin-input"
            placeholder="Keywords"
          />
        </div>
      </div>
      <button className="primary-button mt-4 w-fit font-medium !rounded-none">
        <span className="px-8 py-1 block">Add page</span>
      </button>
    </form>
  );
};

export default AdminSeoAdd;

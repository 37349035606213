import React, { useEffect, useRef, useState } from "react";
import { api_url, media_url } from "../constants";
import axios from "axios";
import { BarChart } from "@mui/x-charts/BarChart";
import { changePage } from "../store/adminPageSlice";
import { useDispatch } from "react-redux";
import useMediaQuery from "../hooks/useMediaQuery";
const AdminStatistics = () => {
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const isSM = useMediaQuery("(max-width: 640px)");
  const isMD = useMediaQuery("(max-width: 1023px)");
  const isLG = useMediaQuery("(max-width: 1279px)");
  const isXL = useMediaQuery("(max-width: 1535px)");

  function getDates(number_of_days) {
    const today = new Date();

    const dates = [];
    for (let i = 0; i < number_of_days; i++) {
      dates.push(
        new Date(today.getFullYear(), today.getMonth(), today.getDate() - i)
          .toISOString()
          .split("T")[0]
      );
    }
    return dates;
  }
  const [orderNumberOfDays, setOrderNumberOfDays] = useState(7);
  const [visitsNumberOfDays, setVisitsNumberOfDays] = useState(7);
  const [productsNumberOfDays, setProductsNumberOfDays] = useState(7);
  const [productsBoughtCount, setProductsBoughtCount] = useState(
    new Array(orderNumberOfDays).fill(0)
  );
  const [visitsCount, setVisitsCount] = useState(
    new Array(visitsNumberOfDays).fill(0)
  );

  const [orderLastXDays, setOrderLastXDays] = useState(
    getDates(orderNumberOfDays)
  );
  const [visitsLastXDays, setVisitsLastXDays] = useState(
    getDates(visitsNumberOfDays)
  );
  const [chartContainerWidth, setChartContainerWidth] = useState(0);

  useEffect(() => {
    dispatch(changePage("Statistics"));
  }, []);

  useEffect(() => {
    axios
      .get(`${api_url}/statistics/products/${productsNumberOfDays}/`)
      .then((res) => {
        console.log(res.data);
        setProducts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [productsNumberOfDays]);

  useEffect(() => {
    axios
      .get(`${api_url}/statistics/products/bought/count/${orderNumberOfDays}/`)
      .then((res) => {
        setProductsBoughtCount(res.data.map((item) => item.count));
        setOrderLastXDays(res.data.map((item) => item.day));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [orderNumberOfDays]);

  useEffect(() => {
    axios
      .get(`${api_url}/statistics/visits/count/${visitsNumberOfDays}/`)
      .then((res) => {
        console.log(res.data);
        setVisitsCount(res.data.map((item) => Math.round(item.count / 2)));
        setVisitsLastXDays(res.data.map((item) => item.day));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [visitsNumberOfDays]);

  useEffect(() => {
    const chart_container = document.getElementById("chart-container");
    setChartContainerWidth(chart_container.offsetWidth);
  }, [window.innerWidth]);

  return (
    <div className="mt-8 px-16 pb-20 lg:px-8 md:px-8 sm:px-4 xl:px-12">
      <div className="grid grid-cols-2 gap-x-20 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1">
        <div>
          <div className="flex items-center justify-between px-4 sm:flex-col gap-y-2">
            <h2 className="text-xl font-semibold">
              Website visits in the last {visitsNumberOfDays} days
            </h2>
            <select
              className="border border-gray-300 rounded-md px-2 py-1 mt-2"
              onChange={(e) => setVisitsNumberOfDays(e.target.value)}
            >
              <option value={7}>7 days</option>
              <option value={14}>14 days</option>
              <option value={28}>28 days</option>
            </select>
          </div>
          <BarChart
            xAxis={[
              {
                id: "barCategories",
                data: visitsLastXDays,
                scaleType: "band",
              },
            ]}
            series={[
              {
                data: visitsCount,
                color: "#F43C3C",
              },
            ]}
            width={chartContainerWidth}
            height={300}
          />
        </div>
        <div id="chart-container" className="md:w-full lg:w-full sm:w-full">
          <div className="flex items-center justify-between px-4  sm:flex-col gap-y-2">
            <h2 className="text-xl font-semibold">
              Orders in the last {orderNumberOfDays} days
            </h2>
            <select
              className="border border-gray-300 rounded-md px-2 py-1 mt-2"
              onChange={(e) => setOrderNumberOfDays(e.target.value)}
            >
              <option value={7}>7 days</option>
              <option value={14}>14 days</option>
              <option value={28}>28 days</option>
            </select>
          </div>
          <BarChart
            xAxis={[
              {
                id: "barCategories",
                data: orderLastXDays,
                scaleType: "band",
              },
            ]}
            series={[
              {
                data: productsBoughtCount,
                color: "#F43C3C",
              },
            ]}
            width={chartContainerWidth}
            height={300}
          />
        </div>
      </div>
      <div className="flex items-center justify-between  sm:flex-col gap-y-2">
        <h2 className="text-xl font-semibold">
          Products in the last {productsNumberOfDays} days
        </h2>
        <select
          className="border border-gray-300 rounded-md px-2 py-1 mt-2"
          onChange={(e) => setProductsNumberOfDays(e.target.value)}
        >
          <option value={7}>7 days</option>
          <option value={14}>14 days</option>
          <option value={28}>28 days</option>
        </select>
      </div>
      <div className="grid grid-cols-12 font-medium mt-2 px-4 gap-x-4 md:hidden sm:hidden">
        <div className="col-span-3">Name</div>
        <div className="col-span-2">Number of Views</div>
        <div className="col-span-2">Favorites</div>
        <div className="col-span-2">Purchased</div>
        <div className="col-span-2">Revenue</div>
      </div>
      <div className="flex flex-col gap-y-3 sm:mt-4 md:mt-4">
        {products.map((product) => (
          <div className="grid xl:items-center 2xl:items-center lg:items-center md:flex sm:flex sm:text-left md:text-left sm:items-start md:items-start sm:justify-start md:justify-start flex-col gap-y-2 grid-cols-12 bg-white px-4 py-2 drop-shadow-md gap-x-4">
            <div className="col-span-3 items-center flex gap-x-4">
              <img
                src={media_url + product.product.image.image}
                className="w-12"
              />
              <div className=" text-lg font-semibold">
                {product.product.title}
              </div>
            </div>
            <div className="col-span-2">
              <span className="font-semibold hidden sm:inline-block md:inline-block">
                Number of Views:
              </span>{" "}
              {product.views}
            </div>
            <div className="col-span-2">
              <span className="font-semibold hidden sm:inline-block md:inline-block">
                Favorites:
              </span>{" "}
              {product.favorites}
            </div>
            <div className="col-span-2">
              <span className="font-semibold hidden sm:inline-block md:inline-block">
                Purchased:
              </span>{" "}
              {product.purchased}
            </div>
            <div className="col-span-2">
              <span className="font-semibold hidden sm:inline-block md:inline-block">
                Revenue:
              </span>{" "}
              {product.revenue} €
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminStatistics;

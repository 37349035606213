import React, { useState, useEffect } from "react";
import ImageCropModal from "./ImageCropModal";
import change from "../static/images/change.svg";

const AddProductImageCard = ({ setResultImages, resultImages, index }) => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);

  function selectFile(e) {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setFile(file);
      setShowModal(true);
      document.body.style.overflow = "hidden";
      e.target.value = null;
    } else {
      e.target.value = null;
      setShowModal(false);
      document.body.style.overflow = "auto";
    }
  }

  function setResultImage(image) {
    const newResultImages = [...resultImages];
    newResultImages[index].image = image;
    if (newResultImages[index].id) {
      newResultImages[index].id = null;
    }
    setResultImages(newResultImages);
  }

  function setResultFile(file) {
    const newResultImages = [...resultImages];
    newResultImages[index].file = file;
    setResultImages(newResultImages);
  }

  function exitModal() {
    setShowModal(false);
    document.body.style.overflow = "auto";
    setFile(null);
  }

  return (
    <span>
      {resultImages[index].image ? (
        <label
          htmlFor={"product-image-" + index}
          className="cursor-pointer group h-40 w-40 bg-transparent primary-border flex items-center relative"
        >
          <img src={resultImages[index].image} className="w-full" />
          <div className="absolute top-0 left-0 right-0 cursor-pointer transition-colors ease-in-out duration-300 bottom-0 group-hover:bg-black/25 bg-transparent"></div>
          <img
            src={change}
            className="w-6 absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%] group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition opacity-0 group-hover:opacity-100 scale-150"
          />
          <input
            ref={file}
            onChange={selectFile}
            id={"product-image-" + index}
            type="file"
            className="hidden"
          />
        </label>
      ) : (
        <label
          htmlFor={"product-image-" + index}
          className="cursor-pointer group h-40 w-40 bg-transparent primary-border flex items-center text-center justify-center"
        >
          <div className="w-fit mx-auto group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition-transform text-4xl font-regular scale-150 text-black">
            +
          </div>
          <input
            ref={file}
            onChange={selectFile}
            id={"product-image-" + index}
            type="file"
            className="hidden"
          />
        </label>
      )}
      <ImageCropModal
        showModal={showModal}
        exitModal={exitModal}
        file={file}
        setResultFile={setResultFile}
        setResultImage={setResultImage}
      />
    </span>
  );
};

export default AddProductImageCard;

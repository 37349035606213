import React, { useState, useEffect } from "react";
import axios from "axios";
import x from "../static/images/x.svg";
import { Link } from "react-router-dom";
import { api_url } from "../constants";
import convertPythonDateTimetoDateTime from "../utils/ConvertPythonDateTimeToDateTime";
import LoadingSpinner from "../components/LoadingSpinner";
import { changePage } from "../store/adminPageSlice";
import { useDispatch } from "react-redux";
import { setGlobalNotificationMessage } from "../store/errorMessageSlice";
const AdminNewsletter = () => {
  const [emails, setEmails] = useState([]);
  const [newsletterMessagesCount, setNewsletterMessagesCount] = useState(0);
  const [contactMessagesCount, setContactMessagesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changePage("Newsletter"));
    axios.get(api_url + "/contacts/count/").then((res) => {
      setContactMessagesCount(res.data);
    });
    axios
      .get(api_url + "/newsletter/messages/count/")
      .then((res) => {
        setNewsletterMessagesCount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${api_url}/newsletters/`)
      .then((res) => {
        setEmails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function DeleteEmail(id) {
    axios
      .delete(`${api_url}/newsletter/${id}/`)
      .then((res) => {
        setEmails(emails.filter((email) => email.id !== id));
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const [responseMessage, setResponseMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  function SendEmails(e) {
    e.preventDefault();
    const subject = e.target.subject.value;
    const message = e.target.message.value;
    setIsSending(true);
    axios
      .post(`${api_url}/newsletter/message/`, { subject, message })
      .then((res) => {
        setResponseMessage(res.data);
        setIsSending(false);
        dispatch(
          setGlobalNotificationMessage(
            res.data.emails_sent + " emails sent successfully!"
          )
        );
      })
      .catch((err) => {
        console.log(err);
        setIsSending(false);
      });
  }

  return (
    <div className="mt-8 px-24 pb-20 lg:px-8 md:px-8 sm:px-4 xl:px-12">
      <div className="flex justify-between mt-4 sm:flex-col-reverse gap-y-6">
        <div className="w-1/3 sm:w-full">
          {isSending ? (
            <div>
              <LoadingSpinner />
            </div>
          ) : responseMessage.length > 0 ? (
            <div className="font-semibold text-center">{responseMessage}</div>
          ) : (
            <form
              onSubmit={SendEmails}
              className="flex flex-col w-full gap-y-4"
            >
              <input
                className="admin-input"
                name="subject"
                placeholder="Subject"
              />
              <textarea
                className="admin-input !h-[14rem]"
                name="message"
                placeholder="Message"
              />
              <button className="admin-button font-semibold py-1.5 w-fit px-4">
                Send Emails
              </button>
            </form>
          )}
        </div>
        <div className="flex flex-col items-end gap-y-3">
          {/* <Link to="/admin/newsletter/messages">
            <div className="admin-button font-semibold py-1.5 w-fit px-4">
              View Messages ({newsletterMessagesCount})
            </div>
          </Link> */}
          <Link to="/admin/contacts">
            <div className="admin-button font-semibold py-1.5 w-fit px-4">
              View Contact Messages ({contactMessagesCount} unread)
            </div>
          </Link>
        </div>
      </div>
      <div className="flex flex-col mt-8">
        {emails.map((email) => (
          <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 bg-white drop-shadow-md py-4 px-4 rounded-lg relative">
            <div>{email.name}</div>
            <div>{email.email}</div>
            <div>
              Subscribed: {convertPythonDateTimetoDateTime(email.created_at)}
            </div>
            <img
              onClick={() => DeleteEmail(email.id)}
              src={x}
              className="absolute cursor-pointer w-4 top-1/2 right-4 -translate-y-1/2"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminNewsletter;

import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { changePage } from "../store/adminPageSlice";
import { api_url } from "../constants";

const AdminProductEdit = () => {
  const [product, setProduct] = useState({});
  const [options, setoptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [resultImages, setResultImages] = useState([
    {
      index: 0,
      image: null,
      file: null,
    },
    {
      index: 1,
      image: null,
      file: null,
    },
    {
      index: 2,
      image: null,
      file: null,
    },
    {
      index: 3,
      image: null,
      file: null,
    },
  ]);
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(api_url + "/product/" + id + "/")
      .then((res) => {
        setProduct(res.data);
        dispatch(changePage("Edit product " + res.data.title));
        console.log(res.data);
        
        axios.get(api_url + "/product/" + id + "/options").then((res) => {
          setoptions(res.data);
          console.log(res.data);
          axios.get(api_url + "/categories/").then((res) => {
            setCategories(res.data);
            console.log(res.data);
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="flex">
      <div className="grid grid-cols-2 w-96">
        <div></div>
      </div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default AdminProductEdit;

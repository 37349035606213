import React from "react";
import x from "../static/images/x.svg";
import axios from "axios";
import { api_url } from "../constants";
import { useSelector, useDispatch } from "react-redux";
import { decrement } from "../store/cartSlice";
const HoverProductItem = ({ item, items, setItems }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  function DeleteCartItem() {
    axios
      .delete(`${api_url}/user/cart/item/${item.id}/`, {
        params: {
          token: auth.access,
        },
      })
      .then((res) => {
        console.log(res.data);
        dispatch(decrement());
        setItems(items.filter((i) => i.id != item.id));
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className="flex gap-x-8 justify-between text-sm">
      <div className="font-semibold">
        {item.product.title} x {item.quantity}
      </div>
      <img src={x} className="w-2 cursor-pointer" onClick={DeleteCartItem} />
    </div>
  );
};

export default HoverProductItem;

import React from "react";

const PageDoesNotExist = () => {
  return (
    <div className=" mt-10 mx-52 xl:mx-40 lg:mx-12 md:mx-6 sm:mx-6">
      <h1 className="text-xl font-semibold text-center">Page does not exist</h1>
      <p className="text-center mt-2 bg-black/10 py-4">
        The page you are looking for does not exist
      </p>
    </div>
  );
};

export default PageDoesNotExist;

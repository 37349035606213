import React, { useState } from "react";
import axios from "axios";
import { auth_url } from "../constants";
import { useParams } from "react-router-dom";
import confirm from "../static/images/confirm.svg";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
const PasswordReset = () => {
  const { uid, token } = useParams();
  const [reset, setReset] = useState(false);
  const userPage = useSelector((state) => state.userPage);

  function ResetPasswordHandler(e) {
    e.preventDefault();
    const form = e.target;
    const data = {
      uid: uid,
      token: token,
      new_password: form.password.value,
      re_new_password: form.re_password.value,
    };
    axios
      .post(
        `${auth_url}/users/reset_password_confirm/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
        //add the csrf
      )
      .then((res) => {
        setReset(true);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className=" px-52 py-8">
      <Helmet>
        <title>{userPage.title}</title>
        <meta name="description" content={userPage.description} />
        <meta name="keywords" content={userPage.keywords} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Apakz" />
        <link rel="canonical" href={"https://apakz.com" + userPage.slug} />
      </Helmet>
      <div className="bg-white py-16 px-8 rounded-xl">
        {reset ? (
          <div className="flex flex-col items-center justify-center">
            <img src={confirm} alt="confirm" className="w-20" />
            <h1 className="text-2xl font-semibold mt-4">
              Your password has been reset
            </h1>
            <p className="text-center mt-2 text-sm">
              You can now login with your new password.
            </p>
          </div>
        ) : (
          <div>
            <h1 className="w-fit mx-auto text-center text-3xl font-bold">
              Reset your password
            </h1>
            <p className="w-fit mx-auto text-center text-sm font-medium mt-1">
              Enter your new password below. Check it twice to make sure it's
              just as you'd like.
            </p>
            <form
              className="mt-6 flex flex-col gap-y-4 w-1/3 mx-auto"
              onSubmit={ResetPasswordHandler}
            >
              <input
                type="password"
                placeholder="Password"
                className="user-input"
                name="password"
              />
              <input
                type="password"
                placeholder="Confirm Password"
                className="user-input"
                name="re_password"
              />
              <button className="primary-button py-2 font-semibold rounded-lg mx-auto w-fit px-12 mt-2">
                Reset Password
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;

import React, { useState, useEffect } from "react";
import profile_filled from "../static/images/profile-filled.svg";
import { useSelector, useDispatch } from "react-redux";
import edit from "../static/images/edit.svg";
import axios from "axios";
import { auth_url, api_url, media_url } from "../constants";
import { motion } from "framer-motion";
import x from "../static/images/x.svg";
import check from "../static/images/check.svg";
import CompanyCard from "../components/CompanyCard";
import { userLoaded } from "../store/authSlice";
import AddressCard from "../components/AddressCard";
import edit_2 from "../static/images/edit_2.svg";
import isPasswordStrong from "../utils/IsPasswordStrong";
import confirm from "../static/images/confirm.svg";
import ImageCropModal from "../components/ImageCropModal";
import exit from "../static/images/exit.svg";
import { logout } from "../store/authSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { setGlobalErrorMessage } from "../store/errorMessageSlice";
import { Helmet } from "react-helmet-async";
const UserAccountProfile = () => {
  const userPage = useSelector((state) => state.userPage);
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const scope = query.get("scope");

  const [availableCountries, setAvailableCountries] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [isAddressHighlighted, setIsAddressHighlighted] = useState(false);
  function selectFile(e) {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setFile(file);
      setShowModal(true);
      document.body.style.overflow = "hidden";
      e.target.value = null;
    } else {
      e.target.value = null;
      setShowModal(false);
      document.body.style.overflow = "auto";
    }
  }
  function exitModal() {
    setShowModal(false);
    document.body.style.overflow = "auto";
    setFile(null);
  }
  const user = useSelector((state) => state.auth.user);
  const [showProfileEdit, setShowProfileEdit] = useState(false);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isAddingCompany, setIsAddingCompany] = useState(false);
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [name, setName] = useState(user && user.name);
  const [phone, setPhone] = useState(user && user.phone);
  const [email, setEmail] = useState(user && user.email);
  const [isLoading, setIsLoading] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const [isNameValid, setIsNameValid] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
  const [resultFile, setResultFile] = useState(null);
  const [resultImage, setResultImage] = useState(null);

  function CloseProfileEdit() {
    if (showProfileEdit) {
      setShowProfileEdit(false);
      setIsNameValid(false);
      setIsPhoneValid(false);
      setIsEmailValid(false);
    }
    if (showEditPassword) {
      setShowEditPassword(false);
      setIsPasswordValid(false);
      setIsConfirmPasswordValid(false);
    }
    const timout = setTimeout(() => {
      setShowConfirmation(false);
    }, 200);
  }

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(api_url + "/user/shipping-addresses/", {
        params: {
          token: auth.access,
        },
      })
      .then((res) => {
        setAddresses(res.data);
        setIsLoading(false);
        if (addresses.length == 0 && scope == "address") {
          setTimeout(() => {
            setIsAddingAddress(true);
            setIsAddressHighlighted(true);
            document.getElementById("shipping-address").scrollIntoView();
          }, 250);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });

    axios
      .get(api_url + "/user/companies/", {
        params: {
          token: auth.access,
        },
      })
      .then((res) => {
        console.log(res.data);
        setCompanies(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(api_url + "/shipping/countries/")
      .then((res) => {
        setAvailableCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setPhone(user.phone);
      setEmail(user.email);
    }
  }, [user]);

  function VerifyName(e) {
    let value = e.target.value;
    if (value.length > 8 && value.trim().includes(" ")) {
      setIsNameValid(true);
    } else {
      setIsNameValid(false);
    }
    setName(value);
  }

  function VerifyPhone(e) {
    let value = e.target.value;
    if (value.length > 8 && value.includes("+")) {
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
    setPhone(value);
  }

  function VerifyEmail(e) {
    let value = e.target.value;
    if (
      value.length > 8 &&
      value.trim().includes("@") &&
      value.trim().includes(".")
    ) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
    setEmail(value);
  }

  function ChangeUserData(e) {
    e.preventDefault();
    if (user.email != email && isEmailValid) {
      axios
        .post(
          `${auth_url}/users/reset_email/`,
          { email: user.email },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setShowConfirmation(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (
      (user.phone != phone && isPhoneValid) ||
      (user.name != name && isNameValid)
    ) {
      axios
        .put(`${api_url}/user/`, {
          token: auth.access,
          phone: user.phone != phone && isPhoneValid ? phone : null,
          name: user.name != name && isNameValid ? name : null,
        })
        .then((res) => {
          axios
            .get(api_url + "/user/", {
              params: {
                token: auth.access,
              },
            })
            .then((res) => {
              console.log(res.data);
              dispatch(userLoaded(res.data));
              if (!(user.email != email && isEmailValid)) {
                CloseProfileEdit();
              }
            })
            .catch((err) => {});
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function ChangeUserPassword(e) {
    e.preventDefault();
    if (!isPasswordValid || !isConfirmPasswordValid) {
      return;
    }
    axios
      .put(`${api_url}/user/`, {
        token: auth.access,
        old_password: e.target.old_password.value,
        new_password: e.target.new_password.value,
        confirm_password: e.target.confirm_new_password.value,
      })
      .then((res) => {
        setShowConfirmation(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function AddCompany(e) {
    e.preventDefault();

    const name = e.target.name.value;
    const registration_number = e.target.registration_number.value;
    const vat_number = e.target.vat_number.value;
    const address = e.target.address.value;

    if (
      name.length < 3 ||
      registration_number.length < 3 ||
      vat_number.length < 3 ||
      address.length < 3
    ) {
      dispatch(
        setGlobalErrorMessage("The entered details are not in a valid format")
      );
      return;
    }

    axios
      .post(api_url + "/user/company/", {
        token: auth.access,
        name: name,
        registration_number: registration_number,
        vat_number: vat_number,
        address: address,
      })
      .then((res) => {
        console.log(res.data);
        setCompanies([...companies, res.data]);
        setIsAddingCompany(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function AddAddress(e) {
    e.preventDefault();

    const name = e.target.name.value;
    const phone = e.target.phone.value;
    const country = e.target.country.value;
    const city = e.target.city.value;
    const postal_code = e.target.postal_code.value;
    const address = e.target.address.value;

    if (
      name.length < 3 ||
      phone.length < 3 ||
      country.length < 1 ||
      postal_code < 3 ||
      address.length < 3
    ) {
      dispatch(
        setGlobalErrorMessage("The entered details are not in a valid format")
      );
      return;
    }

    axios
      .post(api_url + "/user/shipping-address/", {
        token: auth.access,
        name: name,
        phone: phone,
        country: country,
        city: city,
        postal_code: postal_code,
        address: address,
      })
      .then((res) => {
        if (addresses.length == 0) {
          setIsAddressHighlighted(false);
        }
        setAddresses([...addresses, res.data]);
        setIsAddingAddress(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function VerifyNewPassword(e) {
    let value = e.target.value;
    setNewPassword(value);
    if (isPasswordStrong(value)) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  }

  function VerifyConfirmNewPassword(e) {
    let value = e.target.value;
    if (value == newPassword) {
      setIsConfirmPasswordValid(true);
    } else {
      setIsConfirmPasswordValid(false);
    }
  }

  useEffect(() => {
    if (resultImage) {
      axios
        .post(
          api_url + "/user/avatar/",
          {
            token: auth.access,
            avatar: resultFile,
            type: resultFile.type,
          },
          {
            //make content type for file
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          dispatch(userLoaded(res.data));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [resultImage]);
  const navigate = useNavigate();
  function LogoutUser() {
    dispatch(logout());
    navigate("/");
  }

  return (
    <div>
      <Helmet>
        <title>{userPage.title}</title>
        <meta name="description" content={userPage.description} />
        <meta name="keywords" content={userPage.keywords} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Apakz" />
        <link rel="canonical" href={"https://apakz.com" + userPage.slug} />
      </Helmet>
      <div className="flex justify-between flex-wrap-reverse gap-y-4 ">
        <h2 className="font-bold text-xl">Personal Information</h2>
        <button
          onClick={LogoutUser}
          className="primary-button flex ml-auto uppercase gap-x-2 px-4 py-1 text-sm font-semibold"
        >
          Logout <img src={exit} className="w-4" />
        </button>
      </div>
      <div className="bg-white sm:flex-col gap-y-6 sm:py-8 flex justify-between items-center px-6 rounded-2xl drop-shadow-lg mt-3 py-4">
        <div className="flex items-center gap-x-4">
          <label
            htmlFor="avatar"
            className={
              "w-14 sm:w-16  bg-black/20 relative rounded-full group  cursor-pointer" +
              (resultImage || (user && user.avatar) ? "" : " p-4")
            }
          >
            <img
              className={
                "w-full opacity-60" +
                (resultImage || (user && user.avatar) ? " rounded-full" : "")
              }
              src={
                resultImage
                  ? resultImage
                  : user && user.avatar
                  ? media_url + user.avatar
                  : profile_filled
              }
            />
            <div className="overflow-y-auto transition-all ease-in-out duration-300 text-center group-hover:h-fit group-hover:opacity-100 opacity-0 h-0 absolute bottom-0 left-0 rounded-b-full w-full bg-white/50">
              <img src={edit_2} className="w-3 py-1 mx-auto" />
            </div>
            <input
              ref={file}
              onChange={selectFile}
              type="file"
              className="hidden"
              id="avatar"
            />
          </label>
          <div className="font-bold sm:text-lg">{user && user.name}</div>
        </div>
        <div className="flex gap-x-4">
          <div className="flex flex-col text-sm gap-y-1">
            <div>
              <span className="font-semibold">Phone Number: </span>
              <span>{user && user.phone}</span>
            </div>
            <div>
              <span className="font-semibold">Email: </span>
              <span>{user && user.email}</span>
            </div>
          </div>
          <img
            src={edit}
            onClick={() => setShowProfileEdit(true)}
            className="w-5 cursor-pointer"
          />
        </div>
      </div>
      {!isLoading && addresses.length == 0 && (
        <div className="bg-red-200 px-6 rounded-2xl drop-shadow-lg mt-3 py-4 font-semibold text-red-700">
          You need to add a shipping address to be able to place an order.{" "}
          <span
            className="font-bold cursor-pointer"
            onClick={() => {
              setIsAddingAddress(true);
              setIsAddressHighlighted(true);
              document.getElementById("shipping-address").scrollIntoView();
            }}
          >
            Click here.
          </span>
        </div>
      )}
      <h2 className="font-bold text-xl mt-8">Company</h2>
      <div className="grid grid-cols-2 mt-3 sm:grid-cols-1 gap-x-8 gap-y-6">
        {companies.map((company) => (
          <CompanyCard
            company={company}
            companies={companies}
            setCompanies={setCompanies}
          />
        ))}

        <motion.div
          initial={{ height: companies.length > 0 ? "auto" : "18rem" }}
          animate={{
            height: isAddingCompany
              ? "fit-content"
              : companies.length > 0
              ? "auto"
              : "18rem",
          }}
          transition={{
            duration: 0.2,
            ease: "easeInOut",
          }}
          onClick={() => !isAddingCompany && setIsAddingCompany(true)}
          className={
            "bg-white min-h-[18rem] rounded-3xl group relative drop-shadow-hard " +
            (!isAddingCompany && "cursor-pointer")
          }
        >
          <motion.form
            onSubmit={AddCompany}
            initial={{ opacity: 0, display: "none" }}
            animate={{
              opacity: isAddingCompany ? 1 : 0,
              display: isAddingCompany ? "block" : "none",
            }}
            transition={{
              duration: 0.2,
              ease: "easeInOut",
              display: { delay: isAddingCompany ? 0.2 : 0.2 },
            }}
            className="py-8 px-6"
          >
            <input
              className="user-input"
              placeholder="Company name"
              name="name"
            />
            <div className="mt-2 flex flex-col">
              <label className="user-input-label">Registration number:</label>
              <input
                className="user-input w-4/5"
                placeholder="Registration number"
                name="registration_number"
              />
            </div>
            <div className="mt-1 flex flex-col">
              <label className="user-input-label">VAT number:</label>
              <input
                className="user-input w-4/5"
                placeholder="VAT number"
                name="vat_number"
              />
            </div>
            <div className="mt-1 flex flex-col">
              <label className="user-input-label">Adress:</label>
              <input
                className="user-input w-4/5"
                placeholder="Address"
                name="address"
              />
            </div>
            <button className="primary-button px-4 py-1 mt-4 w-fit block ml-auto text-sm font-semibold">
              Add new company
            </button>
          </motion.form>
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: isAddingCompany ? 0 : 1 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className="absolute-center flex flex-col"
          >
            <div className="h-16 mx-auto relative group-hover:scale-105 linear duration-200 transition-transform w-16 text-xl bg-gray-200 rounded-full">
              <span className="absolute-center scale-[200%]">+</span>
            </div>
            <div className="text-center mt-2 font-semibold text-sm">
              Add a company
            </div>
          </motion.div>
        </motion.div>
      </div>
      <h2 id="shipping-address" className="font-bold text-xl mt-8">
        Shipping Adress
      </h2>
      <div className="grid grid-cols-2 sm:grid-cols-1 mt-3 gap-x-8 gap-y-6">
        {addresses.map((address) => (
          <AddressCard
            address={address}
            addresses={addresses}
            setAddresses={setAddresses}
            availableCountries={availableCountries}
          />
        ))}
        <motion.div
          initial={{ height: addresses.length > 0 ? "auto" : "18rem" }}
          animate={{
            height: isAddingAddress
              ? "fit-content"
              : addresses.length > 0
              ? "auto"
              : "18rem",
          }}
          transition={{
            duration: 0.2,
            ease: "easeInOut",
          }}
          onClick={() => !isAddingAddress && setIsAddingAddress(true)}
          className={
            "bg-white min-h-[18rem] rounded-3xl group border-solid  border-2 transition-colors ease-in-out duration-400 delay-400 relative drop-shadow-hard " +
            (!isAddingAddress && "cursor-pointer ") +
            (isAddressHighlighted ? "  border-red-500" : " border-white")
          }
        >
          <motion.form
            onSubmit={AddAddress}
            initial={{ opacity: 0, display: "none" }}
            animate={{
              opacity: isAddingAddress ? 1 : 0,
              display: isAddingAddress ? "block" : "none",
            }}
            transition={{
              duration: 0.2,
              ease: "easeInOut",
              display: { delay: isAddingAddress ? 0.2 : 0.2 },
            }}
            className="py-8 px-6"
          >
            <input className="user-input" placeholder="Full name" name="name" />
            <div className="mt-2 flex flex-col">
              <label className="user-input-label">Phone Number::</label>
              <input
                className="user-input w-4/5"
                placeholder="Phone number"
                name="phone"
              />
            </div>
            <div className="mt-1 flex flex-col">
              <label className="user-input-label">Country:</label>
              <select
                className="user-input w-4/5"
                placeholder="Country"
                name="country"
              >
                {availableCountries.map((country) => (
                  <option value={country.id}>{country.name}</option>
                ))}
              </select>
            </div>
            <div className="flex gap-x-2 w-fit mt-1 justify-between">
              <div className="mt-1 flex flex-col">
                <label className="user-input-label">City:</label>
                <input
                  className="user-input w-full"
                  placeholder="City"
                  name="city"
                />
              </div>
              <div className="mt-1 flex flex-col">
                <label className="user-input-label">Postcode:</label>
                <input
                  className="user-input w-full"
                  placeholder="Postcode"
                  name="postal_code"
                />
              </div>
            </div>
            <div className="mt-1 flex flex-col">
              <label className="user-input-label">Adress:</label>
              <input
                className="user-input w-4/5"
                placeholder="Address"
                name="address"
              />
            </div>
            <button className="primary-button px-4 py-1 mt-4 w-fit block ml-auto text-sm font-semibold">
              Add a new address
            </button>
          </motion.form>
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: isAddingAddress ? 0 : 1 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className="absolute-center flex flex-col"
          >
            <div className="h-16 mx-auto relative group-hover:scale-105 linear duration-200 transition-transform w-16 text-xl bg-gray-200 rounded-full">
              <span className="absolute-center scale-[200%]">+</span>
            </div>
            <div className="text-center mt-2 font-semibold text-sm">
              Add an address
            </div>
          </motion.div>
        </motion.div>
      </div>
      <h2 className="font-bold text-xl mt-8">Password</h2>
      <button
        onClick={() => setShowEditPassword(true)}
        className="mt-3 sm:w-full flex justify-between bg-white drop-shadow-hard rounded-xl px-8 py-4 w-fit gap-x-20 items-center"
      >
        <span className="font-semibold">Change Password</span>
        <div className="p-2 w-8 bg-slate-200 rounded-full">
          <img src={edit_2} className="w-full" />
        </div>
      </button>

      <motion.div
        initial={{ opacity: 0, display: "none" }}
        animate={{
          opacity: showProfileEdit || showEditPassword ? 1 : 0,
          display: showProfileEdit || showEditPassword ? "block" : "none",
        }}
        onClick={CloseProfileEdit}
        transition={{
          duration: 0.2,
          ease: "easeInOut",
          display: { delay: showProfileEdit || showEditPassword ? 0 : 0.2 },
        }}
        className="fixed z-10 top-0 left-0 right-0 bottom-0 bg-black/25"
      ></motion.div>

      <motion.div
        initial={{ opacity: 0, y: "-50%%", x: "-50%", display: "none" }}
        animate={{
          opacity: showEditPassword ? 1 : 0,
          y: showEditPassword ? "-52%" : "-50%",
          display: showEditPassword ? "block" : "none",
        }}
        transition={{
          duration: 0.2,
          ease: "easeInOut",
          display: { delay: showEditPassword ? 0 : 0.2 },
        }}
        className="fixed z-20 w-1/4 min-w-[20rem] top-1/2 bg-white rounded-md py-12 px-12 left-1/2"
      >
        <img
          onClick={CloseProfileEdit}
          className="absolute cursor-pointer top-5 right-5 w-7 p-2 opacity-75 bg-black/20 rounded-full"
          src={x}
        />
        <h3 className="font-bold text-xl text-center">
          {!showConfirmation ? "Change password" : ""}
        </h3>
        {!showConfirmation ? (
          <form
            onSubmit={ChangeUserPassword}
            className="mt-4 flex flex-col gap-y-2"
          >
            <div className="flex flex-col">
              <label htmlFor="old_password" className="text-sm font-semibold">
                Old password
              </label>
              <div className="relative">
                <input
                  id="old_password"
                  name="old_password"
                  className="user-input"
                  placeholder="Old password"
                  type="password"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label htmlFor="new_password" className="text-sm font-semibold">
                New passowrd
              </label>
              <div className="relative">
                <input
                  onChange={VerifyNewPassword}
                  id="new_password"
                  name="new_password"
                  className="user-input"
                  placeholder="New password"
                  type="password"
                />
                {isPasswordValid && (
                  <img
                    className="w-4 absolute right-2 top-1/2 -translate-y-1/2"
                    src={check}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="confirm_new_password"
                className="text-sm font-semibold"
              >
                Confirm new password
              </label>
              <div className="relative">
                <input
                  onChange={VerifyConfirmNewPassword}
                  id="confirm_new_password"
                  name="confirm_new_password"
                  className="user-input"
                  placeholder="Confirm new password"
                  type="password"
                />
                {isConfirmPasswordValid && (
                  <img
                    className="w-4 absolute right-2 top-1/2 -translate-y-1/2"
                    src={check}
                  />
                )}
              </div>
            </div>
            <button
              type="submit"
              className="primary-button px-8 rounded-xl py-2 w-fit block mt-6 font-semibold mx-auto"
            >
              Save Changes
            </button>
          </form>
        ) : (
          <div className="flex flex-col gap-y-2">
            <img src={confirm} className="w-16 mx-auto" />
            <p className="text-center mt-4 font-semibold">
              Your password has been changed successfully.{" "}
            </p>
          </div>
        )}
      </motion.div>

      <motion.form
        onSubmit={ChangeUserData}
        initial={{ opacity: 0, y: "-50%%", x: "-50%", display: "none" }}
        animate={{
          opacity: showProfileEdit ? 1 : 0,
          y: showProfileEdit ? "-52%" : "-50%",
          display: showProfileEdit ? "block" : "none",
        }}
        transition={{
          duration: 0.2,
          ease: "easeInOut",
          display: { delay: showProfileEdit ? 0 : 0.2 },
        }}
        className="fixed z-20 w-1/4 min-w-[20rem] top-1/2 bg-white rounded-md py-16 px-12 left-1/2"
      >
        <img
          onClick={CloseProfileEdit}
          className="absolute cursor-pointer top-5 right-5 w-7 p-2 opacity-75 bg-black/20 rounded-full"
          src={x}
        />
        <h3 className="font-bold text-xl text-center">
          {!showConfirmation ? "Personal information" : ""}
        </h3>
        {showConfirmation ? (
          <div>
            <img src={check} className="w-6 mx-auto" />
            <p className="text-center mt-4 font-semibold">
              We have sent you an email with a link to confirm your new email
              address.{" "}
            </p>
          </div>
        ) : (
          <div className="mt-8 flex flex-col gap-y-4">
            <div className="flex flex-col">
              <label htmlFor="name" className="text-sm font-semibold">
                Full Name<span className="text-red-600 font-bold">*</span>
              </label>
              <div className="relative">
                <input
                  id="name"
                  value={name}
                  onChange={VerifyName}
                  name="name"
                  className="user-input"
                  placeholder="John Doe"
                />
                {isNameValid && (
                  <img
                    className="w-4 absolute right-2 top-1/2 -translate-y-1/2"
                    src={check}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <label htmlFor="phone" className="text-sm font-semibold">
                Phone Number (with country code)
                <span className="text-red-600 font-bold">*</span>
              </label>
              <div className="relative">
                <input
                  id="phone"
                  value={phone}
                  onChange={VerifyPhone}
                  name="phone"
                  className="user-input"
                  placeholder="+49 1522 343333"
                />
                {isPhoneValid && (
                  <img
                    className="w-4 absolute right-2 top-1/2 -translate-y-1/2"
                    src={check}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <label htmlFor="email" className="text-sm font-semibold">
                Email<span className="text-red-600 font-bold">*</span>
              </label>
              <div className="relative">
                <input
                  id="email"
                  value={email}
                  onChange={VerifyEmail}
                  name="email"
                  className="user-input"
                  placeholder="john.doe@example.com"
                />
                {isEmailValid && (
                  <img
                    className="w-4 absolute right-2 top-1/2 -translate-y-1/2"
                    src={check}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {!showConfirmation && (
          <button
            type="submit"
            className="primary-button px-8 rounded-xl py-2 w-fit block mt-6 font-semibold mx-auto"
          >
            Save Changes
          </button>
        )}
      </motion.form>
      <ImageCropModal
        showModal={showModal}
        exitModal={exitModal}
        file={file}
        setResultFile={setResultFile}
        setResultImage={setResultImage}
      />
    </div>
  );
};

export default UserAccountProfile;

import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { api_url } from "../constants";
import axios from "axios";
import AddProductImageCard from "../components/AddProductImageCard";
import ProductOptionButton from "../components/ProductOptionButton";
import AddPhotoOption from "../components/AddPhotoOption";
import check from "../static/images/check.svg";
import AdminProductCard2 from "../components/AdminProductCard2";
import { Link } from "react-router-dom";
import { changePage } from "../store/adminPageSlice";
import { useDispatch } from "react-redux";
import {
  setGlobalNotificationMessage,
  setGlobalErrorMessage,
} from "../store/errorMessageSlice";
const AdminProducts = () => {
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const [selectedProducts, setSelectedProducts] = useState([]);
  useEffect(() => {
    dispatch(changePage("Products"));
    axios
      .get(api_url + "/products/")
      .then((res) => {
        const productsList = res.data;
        productsList.sort((a, b) => a.index - b.index);
        setProducts(productsList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const dragProduct = useRef(0);
  const dragOverProduct = useRef(0);

  function DeleteProducts() {
    axios
      .post(api_url + "/products/", {
        ids: selectedProducts,
      })
      .then((res) => {
        dispatch(
          setGlobalNotificationMessage(
            selectedProducts.length + " products deleted successfully!"
          )
        );
        setProducts(
          products.filter((product) => !selectedProducts.includes(product.id))
        );
        setSelectedProducts([]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function HandleSort() {
    const newProducts = [...products];
    const dragProductData = newProducts[dragProduct.current];
    newProducts.splice(dragProduct.current, 1);
    newProducts.splice(dragOverProduct.current, 0, dragProductData);
    newProducts.forEach((product, index) => {
      product.index = index;
    });
    setProducts(newProducts);
    axios
      .post(api_url + "/products/sort/", newProducts)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
        dispatch(setGlobalErrorMessage("Failed to delete products!"));
      });
  }

  return (
    <div className="mt-8 px-24 pb-20 lg:px-8 md:px-8 sm:px-4 xl:px-12">
      <Link
        to="/admin/products/new"
        className={"primary-button mb-6 block w-fit font-medium !rounded-none"}
      >
        <span className="px-8 py-1 block">Add new product</span>
      </Link>
      {selectedProducts.length > 0 && (
        <div className="flex gap-x-4 ml-auto w-fit items-center">
          <span className="font-medium">
            {selectedProducts.length} products selected
          </span>
          <button
            className="danger-button px-6 py-0.5"
            onClick={DeleteProducts}
          >
            DELETE THEM
          </button>
        </div>
      )}
      <div className="flex mt-4 flex-col gap-y-3">
        {products.map((product, index) => (
          <AdminProductCard2
            key={product.id}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            setProducts={setProducts}
            products={products}
            product={product}
            onDragStart={(e) => {
              dragProduct.current = index;
            }}
            onDragEnter={(e) => {
              dragOverProduct.current = index;
            }}
            onDragEnd={HandleSort}
          />
        ))}
      </div>
    </div>
  );
};

export default AdminProducts;

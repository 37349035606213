import React, { useEffect, useState } from "react";
import change from "../static/images/change.svg";
import ImageCropModal from "./ImageCropModal";
import { motion } from "framer-motion";
import axios from "axios";
import { api_url } from "../constants";

const AdminServiceCard = ({ changeService, removeService, service }) => {
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [resultFile, setResultFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [serviceChanged, setServiceChanged] = useState(false);
  const [isCardClosing, setIsCardClosing] = useState(false);

  function exitModal() {
    setShowModal(false);
    document.body.style.overflow = "auto";
    setFile(null);
  }

  useEffect(() => {
    if (resultImage) setServiceChanged(true);
  }, [resultImage]);

  function selectFile(e) {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setFileType(file.type);
      setFile(file);
      setShowModal(true);
      document.body.style.overflow = "hidden";
      e.target.defaultValue = null;
    } else {
      e.target.defaultValue = null;
      setShowModal(false);
      document.body.style.overflow = "auto";
    }
  }

  function DeleteService(e) {
    e.preventDefault();

    axios.delete(api_url + "/service/" + service.id + "/").then((res) => {
      setIsCardClosing(true);
      setTimeout(() => {
        removeService(service.id);
      }, 500);
    });
  }

  function UpdateService(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (resultFile) {
      formData.append("image", resultFile);
      formData.append("image_type", fileType.split("/")[1]);
    }
    axios
      .put(api_url + "/service/" + service.id + "/", formData)
      .then((res) => {
        setServiceChanged(false);
        changeService(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <span>
      <motion.form
        initial={{ height: "auto" }}
        animate={{ height: isCardClosing ? 0 : "auto" }}
        onSubmit={UpdateService}
        onChange={(e) => setServiceChanged(true)}
        className="bg-white w-full grid  grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 scrollbar-none overflow-auto drop-shadow-hard rounded-3xl"
      >
        <label
          htmlFor={"service-graphic-" + service.id}
          className="relative group mx-auto block lg:col-span-2 md:col-span-2"
        >
          <img
            src={
              resultImage
                ? resultImage
                : `data:image/png;base64,${service.image}`
            }
            alt={service.name}
            className="w-full relative rounded-l-3xl"
          />
          <div className="rounded-l-3xl sm:rounded-t-3xl lg:rounded-none md:rounded-none absolute top-0 left-0 right-0 cursor-pointer transition-colors ease-in-out duration-300 bottom-0 group-hover:bg-black/25 bg-transparent"></div>
          <img
            src={change}
            className="w-10 absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%] group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition opacity-0 group-hover:opacity-100 scale-150"
          />
          <input
            ref={file}
            onChange={selectFile}
            id={"service-graphic-" + service.id}
            type="file"
            className="hidden"
          />
        </label>
        <div className="col-span-2 py-6 px-8 gap-x-8 gap-y-6 grid grid-cols-2 sm:flex sm:flex-col sm:px-4 sm:col-span-1 w-full">
          <div className="flex flex-col gap-y-2">
            <div>
              <label className="text-sm font-bold">Title</label>
              <input
                name="title"
                type="text"
                defaultValue={service.title}
                className="w-full transparent-input"
              />
            </div>
            <div>
              <label className="text-sm font-bold">Description</label>
              <textarea
                name="description"
                className="scrollbar-none w-full min-h-[10rem] transparent-input"
                defaultValue={service.description}
              />
            </div>
            <div>
              <label className="text-sm font-bold">Note</label>
              <input
                type="text"
                name="note"
                defaultValue={service.note}
                className="w-full transparent-input"
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div>
              <label className="text-sm font-bold">SEO Title</label>
              <input
                name="seo_title"
                type="text"
                defaultValue={service.seo_title}
                className="w-full transparent-input"
              />
            </div>
            <div>
              <label className="text-sm font-bold">SEO Description</label>
              <textarea
                name="seo_description"
                className="scrollbar-none w-full min-h-[5rem] transparent-input"
                defaultValue={service.seo_description}
              />
            </div>
            <div>
              <label className="text-sm font-bold">SEO Keywords</label>

              <input
                type="text"
                name="seo_keywords"
                defaultValue={service.seo_keywords}
                className="w-full transparent-input"
              />
            </div>
          </div>
          <div className="w-full gap-x-4 col-span-2 flex mt-auto justify-between">
            <button
              onClick={DeleteService}
              type="button"
              className={
                "primary-button rounded-none px-10 py-1 bg-secondary font-semibold ml-auto"
              }
            >
              Delete Service
            </button>
            <motion.button
              disabled={!serviceChanged}
              initial={{ opacity: 0, width: 0, display: "none" }}
              animate={{
                opacity: serviceChanged ? 1 : 0,
                width: serviceChanged ? "auto" : 0,
                display: serviceChanged ? "block" : "none",
                transition: {
                  duration: 0.3,
                  display: { delay: serviceChanged ? 0 : 0.3 },
                },
              }}
              type="submit"
              className="bg-primary text-white rounded-none font-semibold text-center overflow-x-auto break-keep scrollbar-none whitespace-nowrap"
            >
              <span className="px-10 py-1">Apply Changes</span>
            </motion.button>
          </div>
        </div>
      </motion.form>
      <ImageCropModal
        setResultFile={setResultFile}
        setResultImage={setResultImage}
        file={file}
        showModal={showModal}
        exitModal={exitModal}
      />
    </span>
  );
};

export default AdminServiceCard;

import React, { useState } from "react";
import ConvertPythonDateTimetoDateTime from "../utils/ConvertPythonDateTimeToDateTime";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { statuses } from "../constants";
import GetProductPrice from "../utils/GetProductPrice";
import UserOrderItemCard from "./UserOrderItemCard";
import { api_url } from "../constants";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { set } from "../store/cartSlice";
const UserOrderCard = ({ order }) => {
  const status = statuses.find((status) => status.status === order.status);
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  function CalculateTotalPrice() {
    let total_price = 0;
    order.items.map((item) => {
      total_price += item.price;
    });
    return total_price;
  }

  function RepeatOrder() {
    setIsLoading(true);
    axios
      .post(
        api_url + "/order/" + order.id + "/repeat/",
        {},
        {
          params: {
            token: auth.access,
          },
        }
      )
      .then((res) => {
        dispatch(set(cart + order.items.length));
        setIsLoading(false);
        setIsError(false);
        setIsSuccess(true);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
        setIsSuccess(false);
      });
  }

  return (
    <div className="rounded-xl p-4 bg-gray-100">
      <div className="flex justify-between sm:flex-col gap-y-8">
        <div>{ConvertPythonDateTimetoDateTime(order.created_at)}</div>
        <div className="items-center flex gap-x-2">
          <div className={"w-5 h-5 rounded-full " + status.color}></div>
          <div className="font-semibold text-sm">
            {status.status.toUpperCase()}
          </div>
        </div>
      </div>
      <div className="flex justify-between sm:flex-col gap-y-2 mt-2 items-center">
        <div>Order Number {order.order_number}</div>
        {order.tracking_number ? (
          <div>Tracking Number {order.tracking_number}</div>
        ) : (
          <div>No Traking Number Yet</div>
        )}
        <div>
          Total Price{" "}
          {(CalculateTotalPrice() + order.shipping_price).toFixed(2)} €
        </div>
      </div>
      <div className="flex justify-between mt-4 flex-wrap gap-y-4 items-center">
        <div className="flex flex-col text-sm">
          <div>{order.company.name}</div>
          <div>
            {order.address.country.name}, {order.address.city},{" "}
            {order.address.address}
          </div>
        </div>
        <button
          onClick={RepeatOrder}
          className="primary-button ml-auto rounded-xl px-4 h-fit block py-1 font-semibold"
        >
          {isLoading
            ? "Loading..."
            : isSuccess
            ? "Order items added to cart"
            : isError
            ? "There was an error"
            : "Repeat Order"}
        </button>
      </div>
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: isExpanded ? "auto" : 0 }}
        className="flex overflow-hidden flex-col gap-y-4 mt-4"
      >
        {order.items.map((item) => (
          <div className="flex flex-col gap-y-2">
            <hr className="h-0.5 bg-black w-full" />
            <UserOrderItemCard item={item} />
          </div>
        ))}
      </motion.div>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="rounded-xl bg-gray-300 w-full px-4 h-fit block py-2 font-semibold mt-2"
      >
        {isExpanded ? "Hide" : "Show more"}
      </button>
    </div>
  );
};

export default UserOrderCard;

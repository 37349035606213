import React, { useEffect, useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { changePage } from "../store/adminPageSlice";
import { api_url, media_url } from "../constants";
import { motion } from "framer-motion";
import ProductOptionButton from "../components/ProductOptionButton";
import trashcan_white from "../static/images/trashcan-white.svg";
import AddPhotoOption from "../components/AddPhotoOption";
import AdminDropdownOptionCard from "../components/AdminDropdownOptionCard";
import AdminTextFieldOptionCard from "../components/AdminTextFieldOptionCard";
import AdminRadioButtonOptionsCard from "../components/AdminRadioButtonOptionsCard";
import AdminToggleButtonCard from "../components/AdminToggleButtonCard";
import AdminPhotoButtonCard from "../components/AdminPhotoButtonCard";
import AddProductImageCard from "../components/AddProductImageCard";
import check from "../static/images/check.svg";
import change from "../static/images/change.svg";
import ImageCropModal from "../components/ImageCropModal";
import { setGlobalErrorMessage } from "../store/errorMessageSlice";

const AdminProduct = () => {
  const [product, setProduct] = useState({});
  const [options, setoptions] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [radioButtons, setRadioButtons] = useState([]);
  const { id } = useParams();
  const [addOptionType, setAddOptionType] = useState("");
  const [toggleAddOption, setToggleAddOption] = useState(false);
  // const [productOptions, setProductOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [seoKeywords, setSeoKeywords] = useState("");
  const [price499, setPrice499] = useState("");
  const [price999, setPrice999] = useState("");
  const [price4999, setPrice4999] = useState("");
  const [price9999, setPrice9999] = useState("");
  const [dieline, setDieline] = useState(null);
  const [photoOptions, setPhotoOptions] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [thumbnail, setThumbnail] = useState({
    image: null,
    file: null,
  });

  const [resultImages, setResultImages] = useState([
    {
      index: 0,
      image: null,
      file: null,
    },
    {
      index: 1,
      image: null,
      file: null,
    },
    {
      index: 2,
      image: null,
      file: null,
    },
    {
      index: 3,
      image: null,
      file: null,
    },
  ]);

  function AddTextFieldHandler(e) {
    e.preventDefault();
    const text = e.target["text-field-text"].value;
    const type = "text_field";
    const formData = new FormData();
    formData.append("text", text);
    formData.append("type", type);
    axios
      .post(api_url + "/product/" + id + "/option", formData)
      .then((res) => {
        setAddOptionType(null);
        setToggleAddOption(false);
        fetchOptions();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);

  function selectFile(e) {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setFile(file);
      setShowModal(true);
      document.body.style.overflow = "hidden";
      e.target.value = null;
    } else {
      e.target.value = null;
      setShowModal(false);
      document.body.style.overflow = "auto";
    }
  }

  function setResultImage(image) {
    thumbnail.image = image;
    setThumbnail(thumbnail);
  }

  function setResultFile(file) {
    thumbnail.file = file;
    setThumbnail(thumbnail);
  }

  function exitModal() {
    setShowModal(false);
    document.body.style.overflow = "auto";
    setFile(null);
  }
  function CancelAddOptionHandler() {
    setDropdownOptions([]);
    setAddOptionType(null);
    setRadioButtons([]);
    setPhotoOptions([]);
  }
  function AddDropdownHandler(e) {
    e.preventDefault();
    const options = [];
    dropdownOptions.map((dropdownOption, index) => {
      const text = e.target["dropdown-text-" + index].value;
      const price_499 = e.target["dropdown-price-499-" + index].value;
      const price_999 = e.target["dropdown-price-999-" + index].value;
      const price_4999 = e.target["dropdown-price-4999-" + index].value;
      const price_9999 = e.target["dropdown-price-9999-" + index].value;
      const weight = e.target["dropdown-weight-" + index].value;
      options.push({
        text,
        price_499,
        price_999,
        price_4999,
        price_9999,
        weight,
      });
    });
    const question = e.target["question-text"].value;
    const tip = e.target["question-tip"].value;
    const type = "dropdown";

    const formData = new FormData();
    formData.append("question", question);
    formData.append("tip", tip);
    formData.append("type", type);
    formData.append("no_of_options", options.length);
    options.map((option, index) => {
      formData.append("option_" + index + "_text", option.text);
      formData.append("option_" + index + "_price_499", option.price_499);
      formData.append("option_" + index + "_price_999", option.price_999);
      formData.append("option_" + index + "_price_4999", option.price_4999);
      formData.append("option_" + index + "_price_9999", option.price_9999);
      formData.append("option_" + index + "_weight", option.weight);
    });
    axios
      .post(api_url + "/product/" + id + "/option", formData)
      .then((res) => {
        setAddOptionType(null);
        setToggleAddOption(false);
        fetchOptions();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function RemoveDropdownOption(index) {
    const newDropdownOptions = [...dropdownOptions];
    newDropdownOptions.splice(index, 1);
    setDropdownOptions(newDropdownOptions);
  }
  function RemoveRadioOption(index) {
    const newRadioButtons = [...radioButtons];
    newRadioButtons.splice(index, 1);
    setRadioButtons(newRadioButtons);
  }
  function AddRadioButtonsHandler(e) {
    e.preventDefault();
    const options = [];
    radioButtons.map((radioButton, index) => {
      const text = e.target["radio-button-text-" + index].value;
      const price_499 = e.target["radio-button-price-499-" + index].value;
      const price_999 = e.target["radio-button-price-999-" + index].value;
      const price_4999 = e.target["radio-button-price-4999-" + index].value;
      const price_9999 = e.target["radio-button-price-9999-" + index].value;
      const weight = e.target["radio-button-weight-" + index].value;
      options.push({
        text,
        weight,
        price_499,
        price_999,
        price_4999,
        price_9999,
      });
    });
    const question = e.target["question-text"].value;
    const tip = e.target["question-tip"].value;
    const type = "radio_buttons";
    const formData = new FormData();
    formData.append("question", question);
    formData.append("tip", tip);
    formData.append("type", type);
    formData.append("no_of_options", options.length);
    options.map((option, index) => {
      formData.append("option_" + index + "_text", option.text);
      formData.append("option_" + index + "_price_499", option.price_499);
      formData.append("option_" + index + "_price_999", option.price_999);
      formData.append("option_" + index + "_price_4999", option.price_4999);
      formData.append("option_" + index + "_price_9999", option.price_9999);
      formData.append("option_" + index + "_weight", option.weight);
    });
    axios
      .post(api_url + "/product/" + id + "/option", formData)
      .then((res) => {
        setAddOptionType(null);
        setRadioButtons([]);
        fetchOptions();
        setToggleAddOption(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function AddToggleButtonHandler(e) {
    e.preventDefault();
    const text = e.target["toggle-text"].value;
    const price_499 = e.target["toggle-price-499"].value;
    const price_999 = e.target["toggle-price-999"].value;
    const price_4999 = e.target["toggle-price-4999"].value;
    const price_9999 = e.target["toggle-price-9999"].value;
    const weight = e.target["toggle-weight"].value;
    const tip = e.target["toggle-tip"].value;
    const type = "toggle_button";
    const formData = new FormData();
    formData.append("text", text);
    formData.append("tip", tip);
    formData.append("price_499", price_499);
    formData.append("price_999", price_999);
    formData.append("price_4999", price_4999);
    formData.append("price_9999", price_9999);
    formData.append("weight", weight);
    formData.append("type", type);
    axios
      .post(api_url + "/product/" + id + "/option", formData)
      .then((res) => {
        setAddOptionType(null);
        setToggleAddOption(false);
        fetchOptions();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function AddPhotoOptionHandler(e) {
    e.preventDefault();
    const options = [];
    photoOptions.map((option, index) => {
      options.push({
        text: e.target["photo-option-name-" + index].value,
        price_499: e.target["photo-option-price-499-" + index].value,
        price_999: e.target["photo-option-price-999-" + index].value,
        price_4999: e.target["photo-option-price-4999-" + index].value,
        price_9999: e.target["photo-option-price-9999-" + index].value,
        weight: e.target["photo-option-weight-" + index].value,
        image: option.image,
        file: option.file,
      });
    });
    const question = e.target["question-text"].value;
    const tip = e.target["question-tip"].value;

    const type = "photo_options";
    const formData = new FormData();
    formData.append("question", question);
    formData.append("tip", tip);
    formData.append("type", type);
    formData.append("no_of_options", options.length);
    options.map((option, index) => {
      formData.append("option_" + index + "_text", option.text);
      formData.append("option_" + index + "_price_499", option.price_499);
      formData.append("option_" + index + "_price_999", option.price_999);
      formData.append("option_" + index + "_price_4999", option.price_4999);
      formData.append("option_" + index + "_price_9999", option.price_9999);
      formData.append("option_" + index + "_weight", option.weight);
      formData.append("option_" + index + "_image", option.file);

      formData.append(
        "option_" + index + "_image_type",
        option.file.type.split("/")[1]
      );
    });
    axios
      .post(api_url + "/product/" + id + "/option", formData)
      .then((res) => {
        setPhotoOptions([]);
        setAddOptionType(null);
        setToggleAddOption(false);
        fetchOptions();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const dispatch = useDispatch();

  function fetchOptions() {
    console.log("fetching options");
    setoptions([]);
    axios.get(api_url + "/product/" + id + "/options").then((res) => {
      setoptions(res.data);
    });
  }
  const dragOption = useRef(0);
  const dragOverOption = useRef(0);

  function handleSort() {
    const newOptions = [...options];
    const draggedOption = newOptions[dragOption.current];
    newOptions.splice(dragOption.current, 1);
    newOptions.splice(dragOverOption.current, 0, draggedOption);
    setoptions(newOptions);
    const data = [];
    newOptions.map((option, index) => {
      data.push({ id: option.id, type: option.type, index: index });
    });

    axios
      .post(api_url + "/product/" + id + "/options/sort/", data)
      .then((res) => {})
      .catch((err) => {
        dispatch(setGlobalErrorMessage("Failed to sort options"));
      });
  }

  function fetchData() {
    axios
      .get(api_url + "/product/" + id + "/")
      .then((res) => {
        setProduct(res.data);
        dispatch(changePage("Product " + res.data.title));
        fetchOptions();
        setTitle(res.data.title);
        setDescription(res.data.description);
        setSeoTitle(res.data.seo_title);
        setSeoDescription(res.data.seo_description);
        setSeoKeywords(res.data.seo_keywords);
        setPrice499(res.data.price_499);
        setPrice999(res.data.price_999);
        setPrice4999(res.data.price_4999);
        setPrice9999(res.data.price_9999);
        setDieline(res.data.dieline);
        const res_images = res.data.images;
        const newResultImages = [...resultImages];
        res_images.map((image, index) => {
          newResultImages[index].image = media_url + image.image;
          newResultImages[index].id = image.id;
        });
        setResultImages(newResultImages);
        const categories_ids = res.data.categories.map(
          (category) => category.id
        );
        axios.get(api_url + "/categories/").then((res) => {
          const categories = res.data;
          const unselected_categories = [];
          categories.map((category) => {
            unselected_categories.push({
              ...category,
              selected: categories_ids.includes(category.id),
            });
          });
          setCategories(unselected_categories);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  function setActive() {
    axios
      .post(api_url + "/product/" + id + "/active/")
      .then((res) => {
        setProduct({ ...product, is_active: !product.is_active });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function DeleteOption(id, type) {
    axios
      .post(api_url + "/product/" + product.id + "/option/" + id + "/delete/", {
        type: type,
      })
      .then((res) => {
        fetchOptions();
      });
  }

  function ModifyProductHandler() {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("seo_title", seoTitle);
    formData.append("seo_description", seoDescription);
    formData.append("seo_keywords", seoKeywords);
    formData.append("price_499", price499);
    formData.append("price_999", price999);
    formData.append("price_4999", price4999);
    formData.append("price_9999", price9999);
    //check if dieline is changed
    if (dieline != product.dieline) {
      formData.append("dieline", dieline);
      formData.append("dieline_image_type", dieline.type.split("/")[1]);
    }
    const res_categories = [];
    categories.map((category) => {
      if (category.selected) {
        res_categories.push(category.id);
      }
    });
    formData.append("categories", JSON.stringify(res_categories));
    const req_images = [];
    resultImages.map((image, index) => {
      if (image.file) {
        formData.append("image_" + (index + 1), image.file);
        formData.append(
          "image_" + (index + 1) + "_type",
          image.file.type.split("/")[1]
        );
      }
    });
    if (thumbnail.file) {
      formData.append("thumbnail", thumbnail.file);
      formData.append(
        "thumbnail_image_type",
        thumbnail.file.type.split("/")[1]
      );
    }
    axios
      .put(api_url + "/product/" + id + "/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        fetchData();
        setIsEditing(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function SetCategorySelected(id) {
    const newCategories = [...categories];
    newCategories.map((category) => {
      if (category.id == id) {
        category.selected = !category.selected;
      }
    });
    setCategories(newCategories);
  }

  return (
    <div className="mt-8 px-24 pb-20 lg:px-8 md:px-8 sm:px-4 xl:px-12">
      {isEditing ? (
        <div>
          <div className="flex ml-auto w-fit gap-x-4 sm:flex-col gap-y-1 items-center sm:items-end sm:w-full">
            <button
              onClick={() => {
                setIsEditing(false);
                fetchData();
              }}
              className="bg-black text-white px-6 font-semibold py-1 sm:w-full"
            >
              CANCEL
            </button>
            <button
              onClick={ModifyProductHandler}
              className="admin-button px-8 py-1 font-semibold w-fit sm:w-full block"
            >
              APPLY CHANGES
            </button>
          </div>
          <form className="grid-cols-[20rem,1fr,1fr,1fr] lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-y-6 mt-4 gap-x-8 grid">
            <div className="grid  gap-4 sm:gap-2 grid-cols-2">
              <div className="col-span-2">
                <label
                  htmlFor={"thumbnail"}
                  className="cursor-pointer group h-48 w-[102%] bg-transparent primary-border flex items-center relative"
                >
                  <img
                    src={
                      thumbnail.image
                        ? thumbnail.image
                        : media_url + product.thumbnail
                    }
                    className="w-full"
                  />
                  <div className="absolute top-0 left-0 right-0 cursor-pointer transition-colors ease-in-out duration-300 bottom-0 group-hover:bg-black/25 bg-transparent"></div>
                  <img
                    src={change}
                    className="w-6 absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%] group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition opacity-0 group-hover:opacity-100 scale-150"
                  />
                  <input
                    ref={file}
                    onChange={selectFile}
                    id={"thumbnail"}
                    type="file"
                    className="hidden"
                  />
                </label>
              </div>
              <div className="w-fit">
                <AddProductImageCard
                  setResultImages={setResultImages}
                  resultImages={resultImages}
                  index={0}
                />
              </div>{" "}
              <div className="lg:ml-auto md:ml-auto w-fit sm:ml-auto">
                <AddProductImageCard
                  setResultImages={setResultImages}
                  resultImages={resultImages}
                  index={1}
                />
              </div>
              <div className="w-fit">
                <AddProductImageCard
                  setResultImages={setResultImages}
                  resultImages={resultImages}
                  index={2}
                />
              </div>
              <div className="lg:ml-auto w-fit md:ml-auto sm:ml-auto">
                <AddProductImageCard
                  setResultImages={setResultImages}
                  resultImages={resultImages}
                  index={3}
                />
              </div>
            </div>
            <div className="flex flex-col gap-y-3">
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                name="title"
                className="admin-input"
                type="text"
                placeholder="Product name"
              />
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                name="description"
                className="admin-input h-40"
                type="text"
                placeholder="Description"
              />
              <input
                value={price499}
                onChange={(e) => setPrice499(e.target.value)}
                name="price_499"
                className="admin-input"
                type="text"
                placeholder="Price for 100-499 units (€)"
              />
              <input
                value={price999}
                onChange={(e) => setPrice999(e.target.value)}
                name="price_999"
                className="admin-input"
                type="text"
                placeholder="Price for 500-999 units (€)"
              />
              <input
                value={price4999}
                onChange={(e) => setPrice4999(e.target.value)}
                name="price_4999"
                className="admin-input"
                type="text"
                placeholder="Price for 1000-4999 units (€)"
              />
              <input
                value={price9999}
                onChange={(e) => setPrice9999(e.target.value)}
                name="price_9999"
                className="admin-input"
                type="text"
                placeholder="Price for 5000-9999 units (€)"
              />

              <label htmlFor="dieline" className="admin-input cursor-pointer">
                {dieline ? (
                  <span className="text-green-600 font-bold">
                    Dieline uploaded
                  </span>
                ) : (
                  "Upload dieline"
                )}
                <input
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setDieline(file);
                  }}
                  id="dieline"
                  type="file"
                  className="hidden"
                />
              </label>
            </div>
            <div className="flex flex-col gap-y-3">
              <input
                value={seoTitle}
                onChange={(e) => setSeoTitle(e.target.value)}
                name="seo_title"
                className="admin-input"
                type="text"
                placeholder="SEO Title"
              />
              <textarea
                value={seoDescription}
                onChange={(e) => setSeoDescription(e.target.value)}
                name="seo_description"
                className="admin-input h-40"
                type="text"
                placeholder="SEO Description"
              />
              <input
                value={seoKeywords}
                onChange={(e) => setSeoKeywords(e.target.value)}
                name="seo_keywords"
                className="admin-input"
                type="text"
                placeholder="SEO Keywords"
              />
            </div>
            <ul className="primary-border p-2 flex flex-col gap-y-2 overflow-y-auto h-80">
              {categories.map((category) => (
                <li
                  onClick={() => SetCategorySelected(category.id)}
                  className="px-2 py-1.5 flex justify-between group hover:bg-white transition-colors ease-in-out duration-300 cursor-pointer"
                >
                  <span>{category.name}</span>
                  <motion.img
                    initial={{ opacity: 0, x: -5 }}
                    animate={{
                      opacity: category.selected ? 1 : 0,
                      x: category.selected ? 0 : -5,
                    }}
                    src={check}
                    className="w-4"
                  />
                </li>
              ))}
            </ul>
          </form>
        </div>
      ) : (
        <div>
          <div className="flex justify-between items-center sm:flex-col gap-y-2">
            <h1 className="text-3xl font-bold md:text-2xl sm:text-2xl">
              {product.title}{" "}
            </h1>{" "}
            <div className="flex gap-x-4 items-center sm:justify-between sm:w-full ">
              <div
                onClick={setActive}
                className="rounded-full p-0.5 border-solid cursor-pointer border-black border-2 w-14 h-7"
              >
                <motion.div
                  initial={{ x: 0 }}
                  animate={product.is_active ? { x: 28 } : { x: 0 }}
                  className={
                    "h-full w-5 transition-colors ease-in-out duration-200 rounded-full p-[0.075rem] border-solid border-black border-2 " +
                    (product.is_active ? "bg-black" : "")
                  }
                >
                  <div className="w-full h-full bg-black rounded-full mx-auto my-auto"></div>
                </motion.div>
              </div>
              <button
                onClick={() => setIsEditing(!isEditing)}
                className="primary-button rounded-none px-8 py-1 font-medium"
              >
                Edit Product
              </button>
            </div>
          </div>
          {product.categories && (
            <div className="text-sm flex-wrap gap-y-2 sm:mt-2 text-white font-semibold mt-1 flex gap-x-2">
              {product.categories.map((category) => (
                <span className="bg-primary py-1 break-keep	whitespace-nowrap rounded-md px-2">
                  {category.name}
                </span>
              ))}
            </div>
          )}
          <div className="flex gap-x-8 sm:flex-col mt-4 gap-y-6">
            <div className="grid grid-cols-2 gap-4 w-1/3 sm:w-full md:w-1/2 h-fit">
              <div className="col-span-2">
                <div className=" group min-h-[12rem] bg-transparent primary-border flex rounded-lg items-center ">
                  <img
                    src={media_url + product.thumbnail}
                    className="w-full h-full"
                  />
                </div>
              </div>
              {product.images &&
                product.images.map((image) => (
                  <img
                    className="w-full border-black border-2 border-solid rounded-lg"
                    src={media_url + image.image}
                  />
                ))}
            </div>
            <div className="flex flex-col gap-y-2 w-2/3">
              <div>
                <div className="font-semibold">Description: </div>
                <div>{product.description}</div>
              </div>
              <div>
                <div className="font-semibold">SEO Title: </div>
                <div>{product.seo_title}</div>
              </div>
              <div>
                <div className="font-semibold">SEO Description: </div>
                <div>{product.seo_description}</div>
              </div>
              <div>
                <div className="font-semibold">SEO Keywords: </div>
                <div>{product.seo_keywords}</div>
              </div>
              <div>
                <div className="font-semibold">Slug: </div>
                <div>{product.slug}</div>
              </div>
              <div>
                <div className="font-semibold">Price 100-499 units: </div>
                <div>{product.price_499} €</div>
              </div>
              <div>
                <div className="font-semibold">Price 500-999 units: </div>
                <div>{product.price_999} €</div>
              </div>
              <div>
                <div className="font-semibold">Price 1000-4999 units: </div>
                <div>{product.price_4999} €</div>
              </div>
              <div>
                <div className="font-semibold">Price 5000-9999 units: </div>
                <div>{product.price_9999} €</div>
              </div>
              {product.dieline && (
                <button
                  onClick={() => {
                    axios
                      .get(api_url + "/product/" + product.id + "/dieline/", {
                        responseType: "blob",
                      })
                      .then((res) => {
                        const url = window.URL.createObjectURL(
                          new Blob([res.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                          "download",
                          "dieline." + res.headers["content-type"]
                        );
                        document.body.appendChild(link);
                        link.click();
                      });
                  }}
                  className="bg-black text-white px-6 py-1 font-bold text-sm w-fit block"
                >
                  DOWNLOAD DIELINE
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-between items-center sm:flex-col sm:items-start">
        <h2 className="mt-4 font-bold text-xl">Product Options</h2>
        {(!toggleAddOption && (
          <button
            onClick={() => setToggleAddOption(true)}
            className="primary-button rounded-none py-0.5 sm:w-full font-medium px-4 h-fit block"
          >
            Add new option
          </button>
        )) || (
          <button
            onClick={() => {
              setAddOptionType(null);
              setToggleAddOption(false);
            }}
            className="secondary-button rounded-none py-0.5 font-medium px-4 h-fit block"
          >
            Cancel
          </button>
        )}
      </div>
      <div>
        <motion.h2
          initial={{ opacity: 0, height: 0, display: "none" }}
          animate={{
            opacity: addOptionType ? 0 : toggleAddOption ? 1 : 0,
            height: addOptionType ? 0 : toggleAddOption ? "auto" : 0,
            display: addOptionType
              ? "none"
              : toggleAddOption
              ? "block"
              : "none",
          }}
          transition={{
            delay: 0.2,
            display: {
              delay: addOptionType ? 0.4 : toggleAddOption ? 0.2 : 0,
            },
          }}
          className="overflow-y-auto scrollbar-none"
        >
          {" "}
          Choose the option type that you want to add
        </motion.h2>

        <div className="grid grid-cols-5 gap-x-6 my-2 md:grid-cols-3 sm:grid-cols-1 gap-y-2">
          <ProductOptionButton
            key={1}
            type="photo_options"
            setType={setAddOptionType}
            addOptionType={addOptionType}
            toggleAddOption={toggleAddOption}
          >
            {" "}
            Photo Options
          </ProductOptionButton>
          <ProductOptionButton
            key={2}
            type="toggle_button"
            setType={setAddOptionType}
            addOptionType={addOptionType}
            toggleAddOption={toggleAddOption}
          >
            {" "}
            Toggle Button
          </ProductOptionButton>
          <ProductOptionButton
            key={3}
            type="radio_buttons"
            setType={setAddOptionType}
            addOptionType={addOptionType}
            toggleAddOption={toggleAddOption}
          >
            {" "}
            Radio Buttons
          </ProductOptionButton>
          <ProductOptionButton
            key={4}
            type="dropdown"
            setType={setAddOptionType}
            addOptionType={addOptionType}
            toggleAddOption={toggleAddOption}
          >
            {" "}
            Dropdown
          </ProductOptionButton>
          <ProductOptionButton
            key={5}
            type="text_field"
            setType={setAddOptionType}
            addOptionType={addOptionType}
            toggleAddOption={toggleAddOption}
          >
            {" "}
            Text Field
          </ProductOptionButton>
        </div>
      </div>
      <div>
        {addOptionType == "photo_options" && <h2>New Option: Photo Options</h2>}
        {addOptionType == "toggle_button" && <h2>New Option: Toggle Button</h2>}
        {addOptionType == "radio_buttons" && <h2>New Option: Radio Buttons</h2>}
        {addOptionType == "dropdown" && <h2>New Option: Dropdown Options</h2>}
        {addOptionType == "text_field" && <h2>New Option: Text Field</h2>}
        {addOptionType == "text_field" && (
          <form
            className="flex gap-x-2 items-center sm:flex-col gap-y-2 sm:items-start"
            onSubmit={AddTextFieldHandler}
          >
            <input
              name="text-field-text"
              className="admin-input py-1 sm:w-full"
              placeholder="Text Field Text"
            />
            <div className="flex gap-x-2 items-center ">
              <button
                type="button"
                onClick={CancelAddOptionHandler}
                className=" bg-black h-fit px-8 py-1 text-white "
              >
                Cancel
              </button>
              <button className="admin-button h-fit w-fit px-7 py-1">
                Add Text Field
              </button>
            </div>
          </form>
        )}
        {addOptionType == "dropdown" && (
          <form onSubmit={AddDropdownHandler} className="mt-2">
            <div className="flex gap-x-4 sm:flex-col gap-y-2">
              <input
                className="admin-input w-fit sm:w-full"
                placeholder="Question Text"
                name="question-text"
              />
              <input
                className="admin-input w-fit sm:w-full"
                placeholder="Question Tip"
                name="question-tip"
              />
            </div>
            <div className="grid grid-cols-4 mt-4 gap-x-4 gap-y-4 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
              {dropdownOptions.map((dropdownOption, index) => (
                <div className="flex flex-col gap-y-2" key={index}>
                  <input
                    value={dropdownOption.text}
                    onChange={(e) => {
                      const newDropdownOptions = [...dropdownOptions];
                      newDropdownOptions[index].text = e.target.value;
                      setDropdownOptions(newDropdownOptions);
                    }}
                    name={"dropdown-text-" + index}
                    className="admin-input min-w-[10rem]"
                    placeholder="Dropdown Option Text"
                  />

                  <input
                    value={dropdownOption.price_499}
                    onChange={(e) => {
                      const newDropdownOptions = [...dropdownOptions];
                      newDropdownOptions[index].price_499 = e.target.value;
                      setDropdownOptions(newDropdownOptions);
                    }}
                    type="number"
                    step={0.01}
                    name={"dropdown-price-499-" + index}
                    placeholder="price 100-499 units if selected"
                    className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  />

                  <input
                    value={dropdownOption.price_999}
                    onChange={(e) => {
                      const newDropdownOptions = [...dropdownOptions];
                      newDropdownOptions[index].price_999 = e.target.value;
                      setDropdownOptions(newDropdownOptions);
                    }}
                    type="number"
                    step={0.01}
                    name={"dropdown-price-999-" + index}
                    placeholder="price 500-999 units if selected"
                    className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  />

                  <input
                    value={dropdownOption.price_4999}
                    onChange={(e) => {
                      const newDropdownOptions = [...dropdownOptions];
                      newDropdownOptions[index].price_4999 = e.target.value;
                      setDropdownOptions(newDropdownOptions);
                    }}
                    type="number"
                    step={0.01}
                    name={"dropdown-price-4999-" + index}
                    placeholder="price 1000-4999 units if selected"
                    className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  />

                  <input
                    value={dropdownOption.price_9999}
                    onChange={(e) => {
                      const newDropdownOptions = [...dropdownOptions];
                      newDropdownOptions[index].price_9999 = e.target.value;
                      setDropdownOptions(newDropdownOptions);
                    }}
                    type="number"
                    step={0.01}
                    name={"dropdown-price-9999-" + index}
                    placeholder="price 5000-9999 units if selected"
                    className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  />

                  <input
                    value={dropdownOption.weight}
                    step="0.001"
                    onChange={(e) => {
                      const newDropdownOptions = [...dropdownOptions];
                      newDropdownOptions[index].weight = e.target.value;
                      setDropdownOptions(newDropdownOptions);
                    }}
                    type="number"
                    name={"dropdown-weight-" + index}
                    placeholder="+weight if selected"
                    className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  />

                  <button
                    type="button"
                    onClick={() => RemoveDropdownOption(index)}
                    className="text-white bg-secondary px-2 py-1"
                  >
                    <img
                      src={trashcan_white}
                      alt="trashcan"
                      className="w-5 h-5 mx-auto"
                    />
                  </button>
                </div>
              ))}
              <button
                onClick={() =>
                  setDropdownOptions([
                    ...dropdownOptions,
                    {
                      index: dropdownOptions.length - 1,
                      text: "",
                      price_499: "",
                      price_999: "",
                      price_4999: "",
                      price_9999: "",
                      weight: "",
                    },
                  ])
                }
                className="admin-button w-fit h-fit px-6 py-1"
                type="button"
              >
                Add Button
              </button>
            </div>

            <div className="flex w-fit sm:w-full mt-4 gap-x-4 ml-auto sm:flex-col gap-y-2">
              <button
                type="button"
                onClick={CancelAddOptionHandler}
                className="bg-black px-8 py-1 text-white sm:w-full"
              >
                Cancel
              </button>
              {dropdownOptions.length > 0 && (
                <button className="admin-button w-fit px-7 py-1 sm:w-full">
                  Add Dropdown Options
                </button>
              )}
            </div>
          </form>
        )}
        {addOptionType == "radio_buttons" && (
          <form onSubmit={AddRadioButtonsHandler}>
            <div className="flex gap-x-4 sm:flex-col gap-y-2">
              <input
                className="admin-input w-fit sm:w-full"
                placeholder="Question Text"
                name="question-text"
              />
              <input
                className="admin-input w-fit sm:w-full"
                placeholder="Question Tip"
                name="question-tip"
              />
            </div>
            <div className="mt-4 grid grid-cols-4 sm:grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-4">
              {radioButtons.map((radioButton, index) => (
                <div className="flex flex-col gap-y-2">
                  <input
                    value={radioButton.text}
                    onChange={(e) => {
                      const newRadioButtons = [...radioButtons];
                      newRadioButtons[index].text = e.target.value;
                      setRadioButtons(newRadioButtons);
                    }}
                    name={"radio-button-text-" + index}
                    className="admin-input min-w-[10rem]"
                    placeholder="Radio Button Text"
                  />
                  <input
                    value={radioButton.price_499}
                    step="0.01"
                    onChange={(e) => {
                      const newRadioButtons = [...radioButtons];
                      newRadioButtons[index].price_499 = e.target.value;
                      setRadioButtons(newRadioButtons);
                    }}
                    type="number"
                    name={"radio-button-price-499-" + index}
                    placeholder="price 100-499 units if selected"
                    className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  />
                  <input
                    value={radioButton.price_999}
                    step="0.01"
                    onChange={(e) => {
                      const newRadioButtons = [...radioButtons];
                      newRadioButtons[index].price_999 = e.target.value;
                      setRadioButtons(newRadioButtons);
                    }}
                    type="number"
                    name={"radio-button-price-999-" + index}
                    placeholder="price 500-999 units if selected"
                    className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  />
                  <input
                    value={radioButton.price_4999}
                    step="0.01"
                    onChange={(e) => {
                      const newRadioButtons = [...radioButtons];
                      newRadioButtons[index].price_4999 = e.target.value;
                      setRadioButtons(newRadioButtons);
                    }}
                    type="number"
                    name={"radio-button-price-4999-" + index}
                    placeholder="price 1000-4999 units if selected"
                    className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  />
                  <input
                    value={radioButton.price_9999}
                    step="0.01"
                    onChange={(e) => {
                      const newRadioButtons = [...radioButtons];
                      newRadioButtons[index].price_9999 = e.target.value;
                      setRadioButtons(newRadioButtons);
                    }}
                    type="number"
                    name={"radio-button-price-9999-" + index}
                    placeholder="price 5000-9999 units if selected"
                    className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  />
                  <input
                    value={radioButton.weight}
                    step="0.001"
                    onChange={(e) => {
                      const newRadioButtons = [...radioButtons];
                      newRadioButtons[index].weight = e.target.value;
                      setRadioButtons(newRadioButtons);
                    }}
                    type="number"
                    name={"radio-button-weight-" + index}
                    placeholder="+weight if selected"
                    className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  />
                  <button
                    type="button"
                    onClick={() => RemoveRadioOption(index)}
                    className="text-white bg-secondary px-2 py-1"
                  >
                    <img
                      src={trashcan_white}
                      alt="trashcan"
                      className="w-5 h-5 mx-auto"
                    />
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() =>
                  setRadioButtons([
                    ...radioButtons,
                    {
                      index: radioButtons.length,
                      text: "",
                      price_499: "",
                      price_999: "",
                      price_4999: "",
                      price_9999: "",
                      weight: "",
                    },
                  ])
                }
                className="admin-button sm:w-full block w-fit mt-2 h-fit px-6 py-1"
              >
                Add Button
              </button>
            </div>

            <div className="flex w-fit mt-4 gap-x-4 ml-auto">
              <button
                type="button"
                onClick={CancelAddOptionHandler}
                className="bg-black px-8 py-1 text-white "
              >
                Cancel
              </button>
              {radioButtons.length > 0 && (
                <button className="admin-button w-fit px-7 py-1">
                  Add Radio Buttons
                </button>
              )}
            </div>
          </form>
        )}
        {addOptionType == "toggle_button" && (
          <form
            onSubmit={AddToggleButtonHandler}
            className="w-fit flex flex-col gap-y-2"
          >
            <input
              name="toggle-text"
              className="admin-input"
              placeholder="Toggle Text"
            />
            <input
              name="toggle-tip"
              className="admin-input"
              placeholder="Toggle Tip"
            />
            <input
              type="number"
              step="0.01"
              name="toggle-price-499"
              placeholder="price 100-499 units if ON"
              className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
            />
            <input
              type="number"
              step="0.01"
              name="toggle-price-999"
              placeholder="price 500-999 units if ON"
              className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
            />
            <input
              type="number"
              step="0.01"
              name="toggle-price-4999"
              placeholder="price 1000-4999 units if ON"
              className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
            />
            <input
              type="number"
              step="0.01"
              name="toggle-price-9999"
              placeholder="price 5000-9999 units if ON"
              className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
            />
            <input
              step={0.001}
              type="number"
              name="toggle-weight"
              placeholder="+weight if ON"
              className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
            />
            <div className="flex w-fit mt-4 gap-x-4 ml-auto">
              <button
                type="button"
                onClick={CancelAddOptionHandler}
                className="bg-black px-8 py-1 text-white "
              >
                Cancel
              </button>
              <button className="admin-button w-fit px-7 py-1">
                Add Toggle Option
              </button>
            </div>
          </form>
        )}
        {addOptionType == "photo_options" && (
          <div className="flex flex-col mt-2">
            <form
              className="gap-y-4 flex flex-col "
              onSubmit={AddPhotoOptionHandler}
            >
              <div className="flex gap-x-4 sm:flex-col gap-y-2">
                <input
                  className="admin-input w-fit sm:w-full"
                  placeholder="Question Text"
                  name="question-text"
                />
                <input
                  className="admin-input w-fit sm:w-full"
                  placeholder="Question Tip"
                  name="question-tip"
                />
              </div>
              <div className="flex flex-wrap  gap-y-4 gap-x-4">
                {photoOptions.map((photoOption, index) => (
                  <div>
                    <div className="flex gap-x-2">
                      <img
                        src={photoOption.image}
                        className="primary-border w-[7.5rem] h-min"
                      />
                      <div className="flex flex-col gap-y-3">
                        <input
                          name={"photo-option-name-" + index}
                          className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem]"
                          placeholder="name"
                        />
                        <input
                          name={"photo-option-price-499-" + index}
                          className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem] "
                          placeholder="price 100-499 units if selected"
                          type="number"
                          step={0.01}
                        />
                        <input
                          name={"photo-option-price-999-" + index}
                          className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem] "
                          placeholder="price 500-999 units if selected"
                          type="number"
                          step={0.01}
                        />
                        <input
                          name={"photo-option-price-4999-" + index}
                          className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem] "
                          placeholder="price 1000-4999 units if selected"
                          type="number"
                          step={0.01}
                        />
                        <input
                          name={"photo-option-price-9999-" + index}
                          className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem] "
                          placeholder="price 5000-9999 units if selected"
                          type="number"
                          step={0.01}
                        />
                        <input
                          name={"photo-option-weight-" + index}
                          className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem] "
                          placeholder="+weight if selected"
                          type="number"
                          step={0.001}
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        const newPhotoOptions = [...photoOptions];
                        newPhotoOptions.splice(index, 1);
                        setPhotoOptions(newPhotoOptions);
                      }}
                      className="text-white bg-secondary px-2 py-1 w-full mt-2 block"
                    >
                      <img
                        src={trashcan_white}
                        alt="trashcan"
                        className="w-5 h-5 mx-auto"
                      />
                    </button>
                  </div>
                ))}
                <AddPhotoOption
                  setPhotoOptions={setPhotoOptions}
                  photoOptions={photoOptions}
                />
              </div>

              {photoOptions && (
                <div className="w-fit gap-x-4  flex ml-auto">
                  <button
                    type="button"
                    onClick={CancelAddOptionHandler}
                    className="bg-black px-8 py-1 text-white "
                  >
                    Cancel
                  </button>
                  <button className=" admin-button px-8 py-1">
                    Add Photos Option
                  </button>
                </div>
              )}
            </form>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-y-3 mt-2">
        {options.map(
          (option, index) =>
            (option.type == "photo_options" && (
              <AdminPhotoButtonCard
                onDragStart={() => (dragOption.current = index)}
                onDragEnter={() => {
                  dragOverOption.current = index;
                }}
                onDragEnd={handleSort}
                // onDragOver={(e) => e.preventDefault()}
                fetchOptions={fetchOptions}
                DeleteOption={DeleteOption}
                option={option}
                index={index}
              />
            )) ||
            (option.type == "toggle_button" && (
              <AdminToggleButtonCard
                onDragStart={() => (dragOption.current = index)}
                onDragEnter={() => {
                  dragOverOption.current = index;
                }}
                onDragEnd={handleSort}
                fetchOptions={fetchOptions}
                DeleteOption={DeleteOption}
                option={option}
                index={index}
              />
            )) ||
            (option.type == "radio_buttons" && (
              <AdminRadioButtonOptionsCard
                onDragStart={() => (dragOption.current = index)}
                onDragEnter={() => {
                  dragOverOption.current = index;
                }}
                onDragEnd={handleSort}
                fetchOptions={fetchOptions}
                DeleteOption={DeleteOption}
                option={option}
                index={index}
              />
            )) ||
            (option.type == "dropdown" && (
              <AdminDropdownOptionCard
                onDragStart={() => (dragOption.current = index)}
                onDragEnter={() => {
                  dragOverOption.current = index;
                }}
                onDragEnd={handleSort}
                fetchOptions={fetchOptions}
                DeleteOption={DeleteOption}
                option={option}
                index={index}
              />
            )) ||
            (option.type == "text_field" && (
              <AdminTextFieldOptionCard
                onDragStart={() => (dragOption.current = index)}
                onDragEnter={() => {
                  dragOverOption.current = index;
                }}
                onDragEnd={handleSort}
                fetchOptions={fetchOptions}
                DeleteOption={DeleteOption}
                option={option}
                index={index}
              />
            ))
        )}
      </div>
      <ImageCropModal
        showModal={showModal}
        exitModal={exitModal}
        file={file}
        setResultFile={setResultFile}
        setResultImage={setResultImage}
        aspectRatio={16 / 9}
      />
    </div>
  );
};

export default AdminProduct;

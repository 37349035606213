import React from "react";
import { motion } from "framer-motion";

const ProductOptionButton = ({
  children,
  type,
  setType,
  addOptionType,
  toggleAddOption,
}) => {
  return (
    <motion.button
      onClick={() => setType(type)}
      initial={{ opacity: 0, height: 0, display: "none" }}
      animate={{
        opacity: addOptionType ? 0 : toggleAddOption ? 1 : 0,
        height: addOptionType ? 0 : toggleAddOption ? "auto" : 0,
        display: addOptionType ? "none" : toggleAddOption ? "block" : "none",
      }}
      transition={{
        delay: type == addOptionType ? 0.6 : 0.2,
        display: {
          delay:
            type == addOptionType
              ? 0.8
              : addOptionType
              ? 0.8
              : toggleAddOption
              ? 0.2
              : 0,
        },
      }}
      className="admin-button"
    >
      <span className="py-1 block px-8">{children}</span>
    </motion.button>
  );
};

export default ProductOptionButton;

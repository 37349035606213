import React from "react";
import { LineWave } from "react-loader-spinner";
const LoadingSpinner = () => {
  return (
    <LineWave
      height="125"
      width="125"
      color="#F43C3C"
      ariaLabel="line-wave"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      firstLineColor=""
      middleLineColor=""
      lastLineColor=""
    />
  );
};

export default LoadingSpinner;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { api_url } from "../constants";
import edit from "../static/images/edit.svg";
import { changePage } from "../store/adminPageSlice";
import { useDispatch } from "react-redux";
import AdminSEOCard from "../components/AdminSEOCard";
const AdminSeo = () => {
  const [pages, setPages] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changePage("SEO Optimization"));
    axios
      .get(`${api_url}/page/seos/`)
      .then((res) => {
        setPages(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="mt-8 px-24 pb-20  lg:px-8 md:px-8 sm:px-4 xl:px-12">
      <Link
        to={"/admin/seo/add"}
        className={"primary-button block w-fit font-medium !rounded-none"}
      >
        <span className="px-8 py-1 block">Add page SEO</span>
      </Link>
      <div className="grid grid-cols-4 sm:hidden md:hidden  gap-x-8 font-medium mt-6 px-4 py-2">
        <div>Title</div>
        <div>Slug</div>
        <div>Keywords</div>
        <div></div>
      </div>
      <div className="flex flex-col gap-y-2 sm:mt-6 md:mt-6">
        {pages.map((page) => (
          <AdminSEOCard
            pages={pages}
            setPages={setPages}
            key={page.id}
            page={page}
          />
        ))}
      </div>
    </div>
  );
};

export default AdminSeo;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { api_url, media_url } from "../constants";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import home from "../static/images/home.svg";
import arrow from "../static/images/arrow.svg";
import info from "../static/images/info.svg";
import ToogleOption from "../components/ToggleOption";
import OptionTip from "../components/OptionTip";
import PhotoOption from "../components/PhotoOption";
import RadioButtonOption from "../components/RadioButtonOption";
import DropdownOption from "../components/DropdownOption";
import TextFieldOption from "../components/TextFieldOption";
import download from "../static/images/download.svg";
import upload from "../static/images/upload.svg";
import cart_white from "../static/images/cart-white.svg";
import favourite_empty from "../static/images/favourite-empty.svg";
import favourite_filled from "../static/images/favourite-filled.svg";
import check from "../static/images/check.svg";
import { useSelector, useDispatch } from "react-redux";
import GetProductPrice from "../utils/GetProductPrice";
import { increment } from "../store/cartSlice";
import ProductCard from "../components/ProductCard";
import SmMdHidden from "../components/SmMdHidden";
import LoadingSpinner from "../components/LoadingSpinner";
import { setGlobalErrorMessage } from "../store/errorMessageSlice";
import CheckUserAuthentication from "../utils/CheckUserAuthentication";
import useMediaQuery from "../hooks/useMediaQuery";
import check_white from "../static/images/check-white.svg";
import { Helmet } from "react-helmet-async";

const Product = () => {
  const [bundle, setBundle] = useState({});
  const { slug } = useParams();
  const [product, setProduct] = useState({});
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [hoveringOverCateries, setHoveringOverCateries] = useState(false);
  const [options, setOptions] = useState([]);
  const [price, setPrice] = useState(0);
  const [quantityOption, setQuantityOption] = useState(0);
  const [selectedQuantity, setSelectedQuantity] = useState(100);
  const [isFavourite, setIsFavourite] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [artwork, setArtwork] = useState(null);
  const [comment, setComment] = useState("");
  const [productAdded, setProductAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isCartLoading, setIsCartLoading] = useState(false);
  const [isCartError, setIsCartError] = useState(false);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const isSM = useMediaQuery("(max-width: 640px)");
  const isMD = useMediaQuery("(max-width: 1023px)");
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(api_url + "/product/", {
        params: {
          slug: slug,
        },
      })
      .then((res) => {
        setProduct(res.data);

        axios
          .post(api_url + "/product/" + res.data.id + "/view/")
          .then((res) => {})
          .catch((err) => {});

        axios
          .get(api_url + "/product/" + res.data.id + "/bundle/")
          .then((res) => {
            setBundle(res.data);
          })
          .catch((err) => {
            console.log(err);
            setIsError(true);
            setIsLoading(false);
          });

        axios
          .get(api_url + "/product/" + res.data.id + "/favorite/", {
            params: {
              token: auth.access,
            },
          })
          .then((res) => {
            setIsFavourite(true);
          })
          .catch((err) => {
            setIsFavourite(false);
          });
        axios
          .get(api_url + "/product/" + res.data.id + "/options")
          .then((res) => {
            const options = res.data;
            setOptions(options);
            const default_selected = [];
            options.map((option) => {
              if (option.type == "photo_options") {
                default_selected.push({
                  type: "photo_options",
                  id: option.id,
                  question: option.question,
                  answer: option.variants[0].id,
                  price_499: option.variants[0].price_499,
                  price_999: option.variants[0].price_999,
                  price_4999: option.variants[0].price_4999,
                  price_9999: option.variants[0].price_9999,
                });
              } else if (option.type == "toggle_button") {
                default_selected.push({
                  type: "toggle_button_options",
                  id: option.id,
                  question: option.question,
                  answer: false,
                  price_499: 0,
                  price_999: 0,
                  price_4999: 0,
                  price_9999: 0,
                });
              } else if (option.type == "radio_buttons") {
                default_selected.push({
                  type: "radio_buttons_options",
                  id: option.id,
                  question: option.question,
                  answer: option.variants[0].id,
                  price_499: option.variants[0].price_499,
                  price_999: option.variants[0].price_999,
                  price_4999: option.variants[0].price_4999,
                  price_9999: option.variants[0].price_9999,
                });
              } else if (option.type == "dropdown") {
                default_selected.push({
                  type: "dropdown_options",
                  id: option.id,
                  question: option.question,
                  answer: option.variants[0].id,
                  price_499: option.variants[0].price_499,
                  price_999: option.variants[0].price_999,
                  price_4999: option.variants[0].price_4999,
                  price_9999: option.variants[0].price_9999,
                });
              } else if (option.type == "text_field") {
                default_selected.push({
                  type: "text_field_options",
                  id: option.id,
                  question: option.question,
                  answer: "",
                });
              }
            });

            setSelectedOptions(default_selected);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsError(true);
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
      });
  }, [slug]);

  const discount_prices = [
    {
      quantity: 100,
    },
    {
      quantity: 500,
    },
    {
      quantity: 1000,
    },
    {
      quantity: 5000,
    },
    {
      quantity: 10000,
    },
  ];

  function SetProductFavourite() {
    axios
      .post(api_url + "/product/" + product.id + "/favorite/", {
        token: auth.access,
      })
      .then((res) => {
        setIsFavourite(!isFavourite);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    // let total_price = 0;
    // let total_price_per_unit = 0;
    // if (selectedOptions.length > 0) {
    //   selectedOptions.map((selectedOption) => {
    //     total_price += selectedOption.price;
    //     total_price_per_unit += selectedOption.price_per_unit;
    //   });
    //   setPrice(total_price);
    //   setPricePerUnit(total_price_per_unit);
    // }
  }, [selectedOptions]);

  function AddItemToCart(checkout) {
    CheckUserAuthentication().then((res) => {
      if (res) {
        const quantity =
          quantityOption == discount_prices.length
            ? Number(selectedQuantity)
            : discount_prices[quantityOption].quantity;
        if (quantity <= 0) {
          dispatch(setGlobalErrorMessage("Quantity must be greater than 0"));
          return;
        }
        let show_error = false;
        selectedOptions.map((option) => {
          if (
            String(option.answer) == "" &&
            option.type != "text_field_options"
          ) {
            console.log(option.answer);
            console.log(option.type);
            show_error = true;
          }
        });
        if (show_error) {
          dispatch(
            setGlobalErrorMessage(
              "Please select all options and fill in all fields"
            )
          );
          return;
        }
        setIsCartLoading(true);
        axios
          .post(api_url + "/user/cart/item/", {
            token: auth.access,
            product_id: product.id,
            quantity: quantity,
            options: selectedOptions,
            comment: comment,
          })
          .then((res) => {
            if (artwork) {
              const data = new FormData();
              data.append("token", auth.access);
              data.append("artwork", artwork);
              data.append("type", artwork.type);
              axios
                .post(
                  api_url + "/user/cart/item/" + res.data.id + "/artwork/",
                  data,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((res) => {
                  dispatch(increment());
                  setProductAdded(true);
                  setIsCartLoading(false);
                  setIsCartError(false);

                  if (checkout) {
                    setTimeout(() => {
                      navigate("/cart");
                    }, 1000);
                  }
                  setIsCheckoutLoading(false);
                })
                .catch((err) => {
                  setIsCartLoading(false);
                  setIsCartError(true);
                  setIsCheckoutLoading(false);
                });
            } else {
              dispatch(increment());
              setProductAdded(true);
              setIsCartLoading(false);
              setIsCartError(false);
              setIsCheckoutLoading(false);
              if (checkout) {
                setTimeout(() => {
                  navigate("/cart");
                }, 1000);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setIsCartLoading(false);
            setIsCartError(true);
            setIsCheckoutLoading(false);
          });
      } else {
        dispatch(setGlobalErrorMessage("Please login to add items to cart"));

        setIsCheckoutLoading(false);
      }
    });
  }

  function DownloadDieline() {
    axios
      .get(api_url + "/product/" + product.id + "/dieline/", {
        responseType: "blob",
      })
      .then((res) => {
        console.log(res);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "dieline." + res.headers["content-type"]);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const title_component = (
    <h1 className="text-3xl font-bold">{product.title}</h1>
  );
  const favorite_empty_component = (
    <img
      src={favourite_empty}
      onClick={() => SetProductFavourite()}
      className="w-8 cursor-pointer absolute top-0 right-0"
    />
  );
  const favorite_filled_component = (
    <img
      src={favourite_filled}
      onClick={() => SetProductFavourite()}
      className={
        "w-8 cursor-pointer absolute top-0 right-0 transition-opacity ease-in-out duration-200 " +
        (isFavourite ? "opacity-100" : "opacity-0")
      }
    />
  );
  const description_component = (
    <div className="mt-2">
      {product.description
        ? product.description.split("\n").map((item, index) => {
            return (
              <p key={index} className={index === 0 ? "font-medium" : ""}>
                {item}
              </p>
            );
          })
        : ""}
    </div>
  );

  const categories_component = (
    <div className="mt-2 py-1 flex gap-x-4 items-center text-sm font-semibold text-white w-full overflow-x-auto whitespace-nowrap">
      {product.categories &&
        product.categories.slice(0, 5).map((category) => {
          return (
            <div
              key={category.id}
              className="inline-block whitespace-nowrap break-keep	 bg-primary rounded-full px-3 py-1 "
            >
              {category.name}
            </div>
          );
        })}
      {product.categories && product.categories.length > 5 && (
        <div>
          <div
            onMouseEnter={() => setHoveringOverCateries(true)}
            onMouseLeave={() => setHoveringOverCateries(false)}
            className="inline-block cursor-pointer bg-primary rounded-full px-3 py-1 "
          >
            +{product.categories.length - 5}
          </div>
          <ul
            className={
              "opacity-0 absolute transition-all ease-in-out duration-200 " +
              (hoveringOverCateries
                ? "opacity-100 translate-y-2"
                : "translate-y-0") +
              " bg-white rounded-lg px-4 py-2 drop-shadow-hard"
            }
          >
            {product.categories.slice(5).map((category) => (
              <div
                key={category.id}
                className="inline-block text-xs px-2 py-0.5 text-black/75 "
              >
                {category.name}
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  function calculatePrice(quantity) {
    let basePrice = 0;
    let options_price = 0;
    if (quantity < 500) {
      basePrice = product.price_499;
      selectedOptions.map((option) => {
        options_price += isNaN(option.price_499) ? 0 : option.price_499;
      });
    } else if (quantity < 1000) {
      basePrice = product.price_999;
      selectedOptions.map((option) => {
        options_price += isNaN(option.price_999) ? 0 : option.price_999;
      });
    } else if (quantity < 5000) {
      basePrice = product.price_4999;
      selectedOptions.map((option) => {
        options_price += isNaN(option.price_4999) ? 0 : option.price_4999;
      });
    } else {
      basePrice = product.price_9999;
      selectedOptions.map((option) => {
        options_price += isNaN(option.price_9999) ? 0 : option.price_9999;
      });
    }
    return basePrice + options_price;
  }

  const prices_form = (
    <div className="mt-6 md:mt-6 sm:mt-6">
      <span className="font-semibold block ml-auto w-fit text-sm ">
        Shipping calculated at checkout (VAT Not Included)
      </span>
      <div className="rounded-xl border-black border-2 border-solid mt-2">
        <div className="grid grid-cols-5 sm:grid-cols-3 px-2 py-2 gap-x-4 border-b-solid gap-y-2 border-b-black border-b-2">
          <span className="font-semibold text-sm text-center sm:border-b-solid sm:border-black sm:pb-1 sm:border-b-2">
            Select
          </span>
          <span className="font-semibold text-sm text-center sm:border-b-solid sm:border-black sm:pb-1 sm:border-b-2">
            Quantity
          </span>
          <span className="font-semibold text-sm text-center">Unit Price</span>
          <span className="font-semibold text-sm text-center">Discount</span>
          <span className="font-semibold text-sm text-center">Subtotal</span>
        </div>
        {discount_prices.map((discount_price, index) => (
          <div
            key={index}
            onClick={() => setQuantityOption(index)}
            className="grid bg-white items-center grid-cols-5 gap-y-2 cursor-pointer sm:grid-cols-3 px-2 py-2 gap-x-4 border-b-solid border-b-black border-b-2"
          >
            <span className="sm:border-black sm:pb-1 sm:border-b-2">
              <span
                className={
                  "w-6 h-6 transition-colors sm:border-b-solid  ease-in-out duration-150 rounded-md p-1 border-solid border-2 border-black mx-auto block" +
                  (index == quantityOption ? " bg-customGreen " : "")
                }
              >
                {index == quantityOption ? (
                  <motion.img
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    src={check_white}
                    className="w-full"
                  />
                ) : (
                  <div></div>
                )}
              </span>
            </span>
            <span className="font-semibold sm:border-b-solid sm:border-black sm:pb-1 font-inter sm:border-b-2 text-sm text-center">
              {discount_price.quantity}
            </span>
            <span className="font-semibold text-sm text-center font-inter">
              {calculatePrice(discount_price.quantity).toFixed(2)}€
            </span>
            <span className="font-semibold text-sm text-center font-inter">
              {(
                ((calculatePrice(discount_price.quantity) - calculatePrice(1)) /
                  calculatePrice(1)) *
                100 *
                -1
              ).toFixed(0)}
              %
            </span>
            <span className="font-semibold text-sm text-center font-inter">
              {(
                calculatePrice(discount_price.quantity) *
                discount_price.quantity
              ).toFixed(2)}
              €
            </span>
          </div>
        ))}
        <div
          onClick={() => setQuantityOption(discount_prices.length)}
          className="grid bg-white  items-center grid-cols-5 sm:grid-cols-3 cursor-pointer gap-y-2 px-2 py-2 gap-x-4 rounded-b-xl"
        >
          <span className="sm:border-black sm:pb-1 sm:border-b-2">
            <span
              className={
                "w-6 h-6 transition-colors ease-in-out duration-150 rounded-md p-1 border-solid border-2 border-black mx-auto block" +
                (discount_prices.length == quantityOption
                  ? " bg-customGreen "
                  : "")
              }
            >
              {discount_prices.length == quantityOption ? (
                <motion.img
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  src={check_white}
                  className="w-full"
                />
              ) : (
                <div></div>
              )}
            </span>
          </span>
          <input
            max={1000000}
            min={100}
            value={selectedQuantity}
            onChange={(e) => {
              const quantity = e.target.value;
              if (!quantity || quantity > 99999 || quantity < 100) {
                return;
              }
              setSelectedQuantity(quantity);
            }}
            placeholder="Custom"
            type="number"
            className=" sm:border-black mx-auto block w-fit font-inter sm:pb-1 sm:border-b-2 appearance-none outline-none bg-white text-sm font-semibold text-center"
          />
          <span className="font-semibold text-sm text-center font-inter">
            {calculatePrice(selectedQuantity).toFixed(2)}€
          </span>
          <span className="font-semibold text-sm text-center font-inter">
            {(
              ((calculatePrice(selectedQuantity) - calculatePrice(1)) /
                calculatePrice(1)) *
              100 *
              -1
            ).toFixed(0)}
            %
          </span>
          <span className="font-semibold text-sm text-center font-inter">
            {(calculatePrice(selectedQuantity) * selectedQuantity).toFixed(2)}€
          </span>
        </div>
      </div>

      <div className="flex mt-6 gap-x-8 ml-auto w-fit items-baseline">
        <button
          disabled={isCartLoading || isCheckoutLoading}
          onClick={() => {
            AddItemToCart(false);
          }}
          className="underline font-semibold"
        >
          {isCartLoading && !isCheckoutLoading
            ? "Adding to cart..."
            : isCartError
            ? "Something went wrong"
            : productAdded
            ? "Product added!"
            : "Add to cart"}
        </button>
        <button
          onClick={() => {
            setIsCheckoutLoading(true);
            AddItemToCart(true);
          }}
          disabled={isCartLoading || isCheckoutLoading}
          className="primary-button px-4 py-0.5 font-raleway font-semibold"
        >
          {isCheckoutLoading ? "Checking out..." : "Checkout"}
        </button>
      </div>
    </div>
  );

  return isLoading ? (
    <div className="absolute-center mt-20">
      <LoadingSpinner />
    </div>
  ) : (
    <div className="mx-52 mt-8 xl:mx-40 lg:mx-12 md:mx-6 sm:mx-6 relative">
      <Helmet>
        <title>{product.seo_title}</title>
        <meta name="description" content={product.seo_description} />
        <meta name="keywords" content={product.seo_keywords} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Apakz" />
        <link rel="canonical" href={"https://apakz.com" + product.slug} />
      </Helmet>
      <div className="flex items-center gap-x-3">
        <Link
          to="/"
          className="flex gap-x-2 font-medium text-sm items-start opacity-90"
        >
          <img src={home} className="w-4 mb-0.5" />
          Home
        </Link>
        <img src={arrow} className="w-3" />
        <Link
          to={"/service/" + product.service_slug}
          className="flex gap-x-2 font-medium text-sm items-start opacity-90"
        >
          {product.service_title}
        </Link>
        <img src={arrow} className="w-3" />
        <p className="font-medium text-sm items-start opacity-90">
          {product.title}
        </p>
      </div>
      <div className="relative mt-4 hidden sm:block md:block">
        {title_component}
        {auth.isAuthenticated && favorite_empty_component}
        {auth.isAuthenticated && favorite_filled_component}
        {description_component}
        {categories_component}
      </div>
      <div className="grid gap-x-12 lg:gap-x-6 grid-cols-5 mt-4 md:flex-col md:flex gap-y-8 sm:flex-col sm:flex">
        <div className="col-span-2 md:min-x-[30rem] md:w-2/3 md:mx-auto ">
          <img
            src={
              product.images &&
              media_url + product.images[selectedImageIndex].image
            }
            className="w-full object-cover rounded-lg drop-shadow"
          />
          <div className="grid-cols-4 grid gap-x-3 mt-1">
            {product.images &&
              product.images.map((image, index) => {
                return (
                  <img
                    key={index}
                    src={media_url + image.image}
                    className={
                      "w-full drop-shadow object-cover rounded-lg mt-2 ease-in-out duration-300 transition-colors cursor-pointer border-2 border-solid " +
                      (index === selectedImageIndex
                        ? " border-primary "
                        : "border-transparent")
                    }
                    onClick={() => setSelectedImageIndex(index)}
                  />
                );
              })}
          </div>
          {!isSM && !isMD && prices_form}
        </div>
        <div className="col-span-3 relative">
          <SmMdHidden>
            {title_component}
            {auth.isAuthenticated && favorite_empty_component}
            {auth.isAuthenticated && favorite_filled_component}

            {description_component}
            {categories_component}
          </SmMdHidden>
          <hr className="my-6 bg-black w-full h-1" />
          <h2 className="text-xl font-bold">Select Options:</h2>
          <p className="font-medium">
            *If you have trouble choosing the options, feel free to{" "}
            <Link to="/contact" className="underline">
              contact us!
            </Link>{" "}
          </p>
          <div className="flex flex-col gap-y-4 mt-4">
            {options &&
              options.map((option, index) =>
                option.type === "photo_options" ? (
                  <PhotoOption
                    key={index}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    option={option}
                  />
                ) : option.type == "toggle_button" ? (
                  <ToogleOption
                    key={index}
                    option={option}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                  />
                ) : option.type == "radio_buttons" ? (
                  <RadioButtonOption
                    key={index}
                    option={option}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                  />
                ) : option.type == "dropdown" ? (
                  <DropdownOption
                    key={index}
                    option={option}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                  />
                ) : option.type == "text_field" ? (
                  <TextFieldOption
                    key={index}
                    option={option}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                  />
                ) : (
                  <div></div>
                )
              )}
          </div>
          {(isSM || isMD) && prices_form}
          <div className="mt-6">
            <h3 className="text-lg font-bold">Design</h3>
            <p className="text-sm">
              Upload dieline or images you want us to use or purchase our
              professional design services. We’ll work with you after you place
              your order to finalize your design before printing.
            </p>
            <div className="grid grid-cols-3 mt-2 gap-x-8 sm:grid-cols-1 gap-y-4">
              <button
                onClick={DownloadDieline}
                className="flex gap-x-2 py-2 px-2 drop-shadow-hard justify-center items-center bg-customGray rounded-full text-center text-white font-bold w-full"
              >
                <img src={download} className="w-4" />
                Download Dieline
              </button>
              <label
                htmlFor="artwork"
                className="flex cursor-pointer gap-x-2 py-2 px-2 drop-shadow-hard justify-center items-center bg-customGreen rounded-full text-center text-white font-bold w-full"
              >
                <img src={upload} className="w-4" />
                {artwork == null ? "Upload Artwork" : "Artwork Uploaded"}
                <input
                  type="file"
                  id="artwork"
                  className="hidden"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file.size > 5000000) {
                      dispatch(
                        setGlobalErrorMessage("File size must be less than 5MB")
                      );
                      setArtwork(null);
                      return;
                    }
                    if (
                      !(
                        file.type == "image/jpeg" ||
                        file.type == "image/png" ||
                        file.type == "image/jpg" ||
                        file.type == "image/svg+xml" ||
                        file.type == "application/pdf" ||
                        file.type == "application/vnd.adobe.illustrator" ||
                        file.type ==
                          "application/vnd.adobe.indesign-idml-package"
                      )
                    ) {
                      dispatch(
                        setGlobalErrorMessage("File type not supported")
                      );
                      setArtwork(null);
                      return;
                    }
                    setArtwork(file);
                  }}
                />
              </label>
              <Link to="/contact">
                <button className="flex gap-x-2 py-2 px-2 drop-shadow-hard justify-center items-center bg-primary rounded-full text-center text-white font-bold w-full">
                  <img src={cart_white} className="w-4" />
                  Purchase Design
                </button>
              </Link>
            </div>
            <textarea
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="user-input bg-white mt-4 border-black rounded-2xl h-10"
              placeholder="Comments..."
            />
          </div>
        </div>
      </div>
      <div className="mt-12">
        <h2 className="text-xl font-bold">{bundle.name}</h2>
        <div className="grid grid-cols-4 md:grid-cols-2 lg:gap-x-8 sm:grid-cols-1  gap-y-8 gap-x-12 mt-4 ">
          {bundle.products && bundle.products.length > 0 ? (
            bundle.products.map((product, index) => (
              <ProductCard
                sm={true}
                product={product}
                key={product.id}
                index={index}
              />
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Product;

import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { api_url } from "../constants";
import axios from "axios";
import check from "../static/images/check.svg";
import AddProductImageCard from "../components/AddProductImageCard";
import ProductOptionButton from "../components/ProductOptionButton";
import AddPhotoOption from "../components/AddPhotoOption";
import { changePage } from "../store/adminPageSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import trashcan_white from "../static/images/trashcan-white.svg";
import { setGlobalErrorMessage } from "../store/errorMessageSlice";
import ImageCropModal from "../components/ImageCropModal";
import change from "../static/images/change.svg";

const AdminProductNew = () => {
  const [categories, setCategories] = useState([]);
  const [toggleAddOption, setToggleAddOption] = useState(false);
  const [addOptionType, setAddOptionType] = useState(null);
  const [radioButtons, setRadioButtons] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [newProduct, setNewProduct] = useState(null);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [thumbnail, setThumbnail] = useState({
    image: null,
    file: null,
  });
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);

  function selectFile(e) {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setFile(file);
      setShowModal(true);
      document.body.style.overflow = "hidden";
      e.target.value = null;
    } else {
      e.target.value = null;
      setShowModal(false);
      document.body.style.overflow = "auto";
    }
  }

  function setResultImage(image) {
    thumbnail.image = image;
    setThumbnail(thumbnail);
  }

  function setResultFile(file) {
    thumbnail.file = file;
    setThumbnail(thumbnail);
  }

  function exitModal() {
    setShowModal(false);
    document.body.style.overflow = "auto";
    setFile(null);
  }

  useEffect(() => {
    dispatch(changePage("Products"));
    axios.get(api_url + "/categories/").then((res) => {
      const categories = res.data;
      const unselected_categories = [];
      categories.map((category) => {
        unselected_categories.push({
          ...category,
          selected: false,
        });
      });
      setCategories(unselected_categories);
    });
  }, []);

  const [resultImages, setResultImages] = useState([
    {
      index: 0,
      image: null,
      file: null,
    },
    {
      index: 1,
      image: null,
      file: null,
    },
    {
      index: 2,
      image: null,
      file: null,
    },
    {
      index: 3,
      image: null,
      file: null,
    },
  ]);

  const [productOptions, setProductOptions] = useState([]);
  const [photoOptions, setPhotoOptions] = useState([]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [seoKeywords, setSeoKeywords] = useState("");
  const [price499, setPrice499] = useState("");
  const [price999, setPrice999] = useState("");
  const [price4999, setPrice4999] = useState("");
  const [price9999, setPrice9999] = useState("");
  const [dieline, setDieline] = useState(null);

  function AddPhotoOptionHandler(e) {
    e.preventDefault();
    const options = [];
    photoOptions.map((option, index) => {
      options.push({
        text: e.target["photo-option-name-" + index].value,
        price_499: e.target["photo-option-price-499-" + index].value,
        price_999: e.target["photo-option-price-999-" + index].value,
        price_4999: e.target["photo-option-price-4999-" + index].value,
        price_9999: e.target["photo-option-price-9999-" + index].value,
        weight: e.target["photo-option-weight-" + index].value,
        image: option.image,
        file: option.file,
      });
    });
    const question = e.target["question-text"].value;
    const tip = e.target["question-tip"].value;
    setProductOptions([
      ...productOptions,
      {
        type: "photo_options",
        question,
        tip,
        options,
      },
    ]);
    setAddOptionType(null);
    setPhotoOptions([]);
  }

  function AddToggleButtonHandler(e) {
    e.preventDefault();
    const text = e.target["toggle-text"].value;
    const price_499 = e.target["toggle-price-499"].value;
    const price_999 = e.target["toggle-price-999"].value;
    const price_4999 = e.target["toggle-price-4999"].value;
    const price_9999 = e.target["toggle-price-9999"].value;
    const tip = e.target["toggle-tip"].value;
    const weight = e.target["toggle-weight"].value;
    setProductOptions([
      ...productOptions,
      {
        type: "toggle_button",
        text,
        price_499,
        price_999,
        price_4999,
        price_9999,
        tip,
        weight,
      },
    ]);
    setAddOptionType(null);
  }

  function AddRadioButtonsHandler(e) {
    e.preventDefault();
    const options = [];
    radioButtons.map((radioButton, index) => {
      const text = e.target["radio-button-text-" + index].value;
      const price_499 = e.target["radio-button-price-499-" + index].value;
      const price_999 = e.target["radio-button-price-999-" + index].value;
      const price_4999 = e.target["radio-button-price-4999-" + index].value;
      const price_9999 = e.target["radio-button-price-9999-" + index].value;
      const weight = e.target["radio-button-weight-" + index].value;
      options.push({
        text,
        price_499,
        price_999,
        price_4999,
        price_9999,
        weight,
      });
    });
    const question = e.target["question-text"].value;
    const tip = e.target["question-tip"].value;
    setProductOptions([
      ...productOptions,
      {
        type: "radio_buttons",
        question,
        tip,
        options,
      },
    ]);
    setAddOptionType(null);
    setRadioButtons([]);
  }

  function AddDropdownHandler(e) {
    e.preventDefault();
    const options = [];
    dropdownOptions.map((dropdownOption, index) => {
      const text = e.target["dropdown-text-" + index].value;
      const price_499 = e.target["dropdown-price-499-" + index].value;
      const price_999 = e.target["dropdown-price-999-" + index].value;
      const price_4999 = e.target["dropdown-price-4999-" + index].value;
      const price_9999 = e.target["dropdown-price-9999-" + index].value;

      const weight = e.target["dropdown-weight-" + index].value;
      options.push({
        text,
        weight,
        price_499,
        price_999,
        price_4999,
        price_9999,
      });
    });
    const question = e.target["question-text"].value;
    const tip = e.target["question-tip"].value;
    setProductOptions([
      ...productOptions,
      {
        type: "dropdown",
        question,
        tip,
        options,
      },
    ]);
    setAddOptionType(null);
    setDropdownOptions([]);
  }

  function AddTextFieldHandler(e) {
    e.preventDefault();
    const text = e.target["text-field-text"].value;
    setProductOptions([...productOptions, { type: "text_field", text }]);
    setAddOptionType(null);
  }

  function CancelAddOptionHandler() {
    setDropdownOptions([]);
    setAddOptionType(null);
    setRadioButtons([]);
    setPhotoOptions([]);
  }

  function SetCategorySelected(id) {
    const newCategories = [...categories];
    newCategories.map((category) => {
      if (category.id == id) {
        category.selected = !category.selected;
      }
    });
    setCategories(newCategories);
  }

  function RemoveDropdownOption(index) {
    const newDropdownOptions = [...dropdownOptions];
    newDropdownOptions.splice(index, 1);
    setDropdownOptions(newDropdownOptions);
  }
  function RemoveRadioOption(index) {
    const newRadioButtons = [...radioButtons];
    newRadioButtons.splice(index, 1);
    setRadioButtons(newRadioButtons);
  }

  async function CreateProductHandler() {
    if (
      title == "" ||
      description == "" ||
      seoTitle == "" ||
      seoDescription == "" ||
      seoKeywords == "" ||
      price499 == "" ||
      price999 == "" ||
      price4999 == "" ||
      price9999 == "" ||
      dieline == null ||
      resultImages[0].file == null ||
      resultImages[1].file == null ||
      resultImages[2].file == null ||
      resultImages[3].file == null ||
      thumbnail.file == null
    ) {
      dispatch(
        setGlobalErrorMessage(
          "Please fill in all the fields and upload all the images"
        )
      );
      return;
    }

    setIsAddingProduct(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("seo_title", seoTitle);
    formData.append("seo_description", seoDescription);
    formData.append("seo_keywords", seoKeywords);
    formData.append("price_499", price499);
    formData.append("price_999", price999);
    formData.append("price_4999", price4999);
    formData.append("price_9999", price9999);
    formData.append("dieline", dieline);
    formData.append("dieline_image_type", dieline.type);
    formData.append("thumbnail", thumbnail.file);
    formData.append("thumbnail_image_type", thumbnail.file.type.split("/")[1]);

    const selected_categories = categories
      .filter((category) => category.selected)
      .map((category) => category.id);

    if (selected_categories.length === 0) {
      dispatch(setGlobalErrorMessage("Please select at least one category"));
      setIsAddingProduct(false);
      return;
    }

    formData.append("categories", selected_categories);
    formData.append("image_1", resultImages[0].file);
    formData.append("image_1_type", resultImages[0].file.type.split("/")[1]);
    formData.append("image_2", resultImages[1].file);
    formData.append("image_2_type", resultImages[1].file.type.split("/")[1]);
    formData.append("image_3", resultImages[2].file);
    formData.append("image_3_type", resultImages[2].file.type.split("/")[1]);
    formData.append("image_4", resultImages[3].file);
    formData.append("image_4_type", resultImages[3].file.type.split("/")[1]);

    try {
      const res = await axios.post(`${api_url}/product/`, formData);
      setNewProduct(res.data);
      const id = res.data.id;

      if (productOptions.length === 0) {
        navigate("/admin/products");
      }

      for (const productOption of productOptions) {
        if (productOption.type === "photo_options") {
          await submitProductOption(id, productOption);
        } else if (productOption.type === "toggle_button") {
          await submitToggleOption(id, productOption);
        } else if (productOption.type === "radio_buttons") {
          await submitRadioOption(id, productOption);
        } else if (productOption.type === "dropdown") {
          await submitDropdownOption(id, productOption);
        } else if (productOption.type === "text_field") {
          await submitTextFieldOption(id, productOption);
        }
      }
      navigate("/admin/products");
    } catch (err) {
      setIsAddingProduct(false);
      setGlobalErrorMessage("Something went wrong. Please try again later");
      console.log(err);
    }
  }

  async function submitProductOption(id, productOption) {
    const { question, tip, options, type } = productOption;
    const formData = new FormData();
    formData.append("question", question);
    formData.append("tip", tip);
    formData.append("type", type);
    formData.append("no_of_options", options.length);
    options.forEach((option, index) => {
      formData.append(`option_${index}_text`, option.text);
      formData.append(`option_${index}_price_499`, option.price_499);
      formData.append(`option_${index}_price_999`, option.price_999);
      formData.append(`option_${index}_price_4999`, option.price_4999);
      formData.append(`option_${index}_price_9999`, option.price_9999);
      formData.append(`option_${index}_weight`, option.weight);
      formData.append(`option_${index}_image`, option.file);
      formData.append(
        `option_${index}_image_type`,
        option.file.type.split("/")[1]
      );
    });

    try {
      await axios.post(`${api_url}/product/${id}/option`, formData);
    } catch (err) {
      setIsAddingProduct(false);
      setGlobalErrorMessage("Something went wrong. Please try again later");
      console.log(err);
    }
  }

  async function submitToggleOption(id, productOption) {
    const {
      text,
      tip,
      price_499,
      price_999,
      price_4999,
      price_9999,
      weight,
      type,
    } = productOption;
    const formData = new FormData();
    formData.append("text", text);
    formData.append("tip", tip);
    formData.append("price_499", price_499);
    formData.append("price_999", price_999);
    formData.append("price_4999", price_4999);
    formData.append("price_9999", price_9999);
    formData.append("weight", weight);
    formData.append("type", type);

    try {
      await axios.post(`${api_url}/product/${id}/option`, formData);
    } catch (err) {
      setIsAddingProduct(false);
      setGlobalErrorMessage("Something went wrong. Please try again later");
      console.log(err);
    }
  }

  async function submitRadioOption(id, productOption) {
    const { question, tip, options, type } = productOption;
    const formData = new FormData();
    formData.append("question", question);
    formData.append("tip", tip);
    formData.append("type", type);
    formData.append("no_of_options", options.length);
    options.forEach((option, index) => {
      formData.append(`option_${index}_text`, option.text);
      formData.append(`option_${index}_price_499`, option.price_499);
      formData.append(`option_${index}_price_999`, option.price_999);
      formData.append(`option_${index}_price_4999`, option.price_4999);
      formData.append(`option_${index}_price_9999`, option.price_9999);
      formData.append(`option_${index}_weight`, option.weight);
    });

    try {
      await axios.post(`${api_url}/product/${id}/option`, formData);
    } catch (err) {
      setIsAddingProduct(false);
      setGlobalErrorMessage("Something went wrong. Please try again later");
      console.log(err);
    }
  }

  async function submitDropdownOption(id, productOption) {
    const { question, tip, options, type } = productOption;
    const formData = new FormData();
    formData.append("question", question);
    formData.append("tip", tip);
    formData.append("type", type);
    formData.append("no_of_options", options.length);
    options.forEach((option, index) => {
      formData.append(`option_${index}_text`, option.text);
      formData.append(`option_${index}_price_499`, option.price_499);
      formData.append(`option_${index}_price_999`, option.price_999);
      formData.append(`option_${index}_price_4999`, option.price_4999);
      formData.append(`option_${index}_price_9999`, option.price_9999);
      formData.append(`option_${index}_weight`, option.weight);
    });

    try {
      await axios.post(`${api_url}/product/${id}/option`, formData);
    } catch (err) {
      setIsAddingProduct(false);
      setGlobalErrorMessage("Something went wrong. Please try again later");
      console.log(err);
    }
  }

  async function submitTextFieldOption(id, productOption) {
    const { text, type } = productOption;
    const formData = new FormData();
    formData.append("text", text);
    formData.append("type", type);

    try {
      await axios.post(`${api_url}/product/${id}/option`, formData);
    } catch (err) {
      setIsAddingProduct(false);
      setGlobalErrorMessage("Something went wrong. Please try again later");
      console.log(err);
    }
  }

  function DeleteProductOption(index) {
    const newProductOptions = [...productOptions];
    newProductOptions.splice(index, 1);
    setProductOptions(newProductOptions);
  }
  const horizontal_line = <hr className="w-full h-0.5 my-0.5 bg-black" />;
  const delete_option = (index) => (
    <button
      className="bg-secondary px-10 py-1.5"
      onClick={() => DeleteProductOption(index)}
    >
      <img src={trashcan_white} className="w-4 h-4" alt="trashcan" />
    </button>
  );
  return (
    <div
      className={
        "w-full overflow-auto scrollbar-none mt-8 px-24 pb-20 lg:px-8 md:px-8 sm:px-4 xl:px-12"
      }
    >
      <div className="flex justify-between sm:flex-col gap-y-1 items-center">
        <h2 className="font-semibold">
          Complete the form to add a new product
        </h2>
        <button
          disabled={isAddingProduct}
          onClick={CreateProductHandler}
          className="admin-button px-8 py-1 font-semibold w-fit sm:w-full block"
        >
          {isAddingProduct ? "Adding the product" : "Add the new product"}
        </button>
      </div>
      <form className="grid-cols-[20rem,1fr,1fr,1fr] lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-y-6 mt-4 gap-x-8 grid">
        <div className="grid  gap-4 sm:gap-2 grid-cols-2 ">
          <div className="col-span-2">
            {thumbnail.image ? (
              <label
                htmlFor={"thumbnail"}
                className="cursor-pointer group h-48 w-[102%] bg-transparent primary-border flex items-center relative"
              >
                <img src={thumbnail.image} className="w-full" />
                <div className="absolute top-0 left-0 right-0 cursor-pointer transition-colors ease-in-out duration-300 bottom-0 group-hover:bg-black/25 bg-transparent"></div>
                <img
                  src={change}
                  className="w-6 absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%] group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition opacity-0 group-hover:opacity-100 scale-150"
                />
                <input
                  onChange={selectFile}
                  id={"thumbnail"}
                  type="file"
                  className="hidden"
                />
              </label>
            ) : (
              <label
                htmlFor={"thumbnail"}
                className="cursor-pointer group h-48 w-[102%] bg-transparent primary-border flex items-center text-center justify-center"
              >
                <div className="w-fit mx-auto group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition-transform text-4xl font-regular scale-150 text-black">
                  +
                </div>
                <input
                  onChange={selectFile}
                  id={"thumbnail"}
                  type="file"
                  className="hidden"
                />
              </label>
            )}
          </div>
          <div className="w-fit">
            <AddProductImageCard
              setResultImages={setResultImages}
              resultImages={resultImages}
              index={0}
            />
          </div>{" "}
          <div className="lg:ml-auto md:ml-auto w-fit sm:ml-auto">
            <AddProductImageCard
              setResultImages={setResultImages}
              resultImages={resultImages}
              index={1}
            />
          </div>
          <div className="w-fit">
            <AddProductImageCard
              setResultImages={setResultImages}
              resultImages={resultImages}
              index={2}
            />
          </div>
          <div className="lg:ml-auto w-fit md:ml-auto sm:ml-auto">
            <AddProductImageCard
              setResultImages={setResultImages}
              resultImages={resultImages}
              index={3}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-3">
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            name="title"
            className="admin-input"
            type="text"
            placeholder="Nume produs"
          />
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            name="description"
            className="admin-input h-40"
            type="text"
            placeholder="Descriere"
          />
          <input
            value={price499}
            onChange={(e) => setPrice499(e.target.value)}
            name="price_499"
            className="admin-input"
            type="number"
            step="0.01"
            placeholder="Preț 100-499 bucăți (€)"
          />
          <input
            value={price999}
            onChange={(e) => setPrice999(e.target.value)}
            name="price_999"
            className="admin-input"
            type="number"
            step="0.01"
            placeholder="Preț 500-999 bucăți (€)"
          />
          <input
            value={price4999}
            onChange={(e) => setPrice4999(e.target.value)}
            name="price_4999"
            className="admin-input"
            type="number"
            step="0.01"
            placeholder="Preț 1000-4999 bucăți (€)"
          />
          <input
            value={price9999}
            onChange={(e) => setPrice9999(e.target.value)}
            name="price_9999"
            className="admin-input"
            type="number"
            step="0.01"
            placeholder="Preț 5000-9999 bucăți (€)"
          />
          <label htmlFor="dieline" className="admin-input cursor-pointer">
            {dieline ? (
              <span className="text-green-600 font-bold">Dieline uploaded</span>
            ) : (
              "Upload dieline"
            )}
            <input
              onChange={(e) => {
                const file = e.target.files[0];
                setDieline(file);
              }}
              id="dieline"
              type="file"
              className="hidden"
            />
          </label>
        </div>
        <div className="flex flex-col gap-y-3">
          <input
            value={seoTitle}
            onChange={(e) => setSeoTitle(e.target.value)}
            name="seo_title"
            className="admin-input"
            type="text"
            placeholder="Titlu SEO"
          />
          <textarea
            value={seoDescription}
            onChange={(e) => setSeoDescription(e.target.value)}
            name="seo_description"
            className="admin-input h-40"
            type="text"
            placeholder="Descriere SEO"
          />
          <input
            value={seoKeywords}
            onChange={(e) => setSeoKeywords(e.target.value)}
            name="seo_keywords"
            className="admin-input"
            type="text"
            placeholder="Cuvinte cheie SEO"
          />
        </div>
        <ul className="primary-border p-2 flex flex-col gap-y-2 overflow-y-auto h-80">
          {categories.map((category) => (
            <li
              onClick={() => SetCategorySelected(category.id)}
              className="px-2 py-1.5 flex justify-between group hover:bg-white transition-colors ease-in-out duration-300 cursor-pointer"
            >
              <span>{category.name}</span>
              <motion.img
                initial={{ opacity: 0, x: -5 }}
                animate={{
                  opacity: category.selected ? 1 : 0,
                  x: category.selected ? 0 : -5,
                }}
                src={check}
                className="w-4"
              />
            </li>
          ))}
        </ul>
      </form>
      <div className="my-4 flex flex-col gap-y-6">
        {productOptions.map((productOption, index) => (
          <div>
            {(productOption.type == "photo_options" && (
              <div>
                <div className="flex justify-between">
                  <h3 className="font-semibold">
                    Option {index + 1} (Photo Options):
                  </h3>
                  {delete_option(index)}
                </div>
                <h4>
                  <span className="font-medium">Question:</span>{" "}
                  {productOption.question}
                </h4>
                <p>
                  <span className="font-medium">Tip:</span> {productOption.tip}
                </p>
                <div className="flex flex-wrap gap-x-4 gap-y-4">
                  {productOption.options.map((option) => (
                    <div className="flex gap-x-4 mt-2">
                      <img
                        src={option.image}
                        className="w-20 h-20 primary-border"
                      />
                      <div>
                        <p>
                          <span className="font-medium">text: </span>
                          {option.text}
                        </p>
                        <p>
                          <span className="font-medium">
                            price 100-499 units:{" "}
                          </span>
                          {option.price_499}$
                        </p>
                        <p>
                          <span className="font-medium">
                            price 500-999 units:{" "}
                          </span>
                          {option.price_999}$
                        </p>
                        <p>
                          <span className="font-medium">
                            price 1000-4999 units:{" "}
                          </span>
                          {option.price_4999}$
                        </p>
                        <p>
                          <span className="font-medium">
                            price 5000-9999 units:{" "}
                          </span>
                          {option.price_9999}$
                        </p>

                        <p>
                          <span className="font-medium">weight: </span>
                          {option.weight} kg
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )) ||
              (productOption.type == "toggle_button" && (
                <div>
                  <div className="flex justify-between">
                    <h3 className="font-semibold">
                      Option {index + 1} (Toggle Button):
                    </h3>
                    {delete_option(index)}
                  </div>
                  <h4>
                    <span className="font-medium">Question:</span>{" "}
                    {productOption.text}
                  </h4>
                  <p>
                    <span className="font-medium">Tip:</span>{" "}
                    {productOption.tip}
                  </p>
                  <p>
                    <span className="font-medium">
                      Price 100-499 units if ON:{" "}
                    </span>
                    {productOption.price_499}$
                  </p>
                  <p>
                    <span className="font-medium">
                      Price 500-999 units if ON:{" "}
                    </span>
                    {productOption.price_999}$
                  </p>
                  <p>
                    <span className="font-medium">
                      Price 1000-4999 units if ON:{" "}
                    </span>
                    {productOption.price_4999}$
                  </p>
                  <p>
                    <span className="font-medium">
                      Price 5000-9999 units if ON:{" "}
                    </span>
                    {productOption.price_9999}$
                  </p>

                  <p>
                    <span className="font-medium">+weight if ON: </span>
                    {productOption.weight} kg
                  </p>
                </div>
              )) ||
              (productOption.type == "radio_buttons" && (
                <div>
                  <div className="flex justify-between">
                    <h3 className="font-semibold">
                      Option {index + 1} (Radio Buttons):
                    </h3>
                    {delete_option(index)}
                  </div>
                  <h4>
                    <span className="font-medium">Question:</span>{" "}
                    {productOption.question}
                  </h4>
                  <p>
                    <span className="font-medium">Tip:</span>{" "}
                    {productOption.tip}
                  </p>
                  {horizontal_line}
                  <div className="flex gap-x-8 gap-y-4 flex-wrap">
                    {productOption.options.map((option) => (
                      <div className="flex-col">
                        <div>
                          <span className="font-medium">text:</span>
                          {option.text}
                        </div>
                        <div>
                          <span className="font-medium">
                            price 100-499 units:
                          </span>
                          {option.price_499}$
                        </div>
                        <div>
                          <span className="font-medium">
                            price 500-999 units:
                          </span>
                          {option.price_999}$
                        </div>
                        <div>
                          <span className="font-medium">
                            price 1000-4999 units:
                          </span>
                          {option.price_4999}$
                        </div>
                        <div>
                          <span className="font-medium">
                            price 5000-9999 units:
                          </span>
                          {option.price_9999}$
                        </div>
                        <div>
                          <span className="font-medium">weight:</span>
                          {option.weight}$
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )) ||
              (productOption.type == "dropdown" && (
                <div className="w-full">
                  <div className="flex justify-between">
                    <h3 className="font-semibold">
                      Option {index + 1} (Dropdown):
                    </h3>
                    {delete_option(index)}
                  </div>
                  <h4>
                    <span className="font-medium">Question:</span>{" "}
                    {productOption.question}
                  </h4>
                  <p>
                    <span className="font-medium">Tip:</span>{" "}
                    {productOption.tip}
                  </p>
                  {horizontal_line}
                  <div className="flex gap-x-8 gap-y-4 flex-wrap">
                    {productOption.options.map((option) => (
                      <div className="flex-col">
                        <div>
                          <span className="font-medium">text:</span>
                          {option.text}
                        </div>
                        <div>
                          <span className="font-medium">
                            price 100-499 units:
                          </span>
                          {option.price_499}$
                        </div>
                        <div>
                          <span className="font-medium">
                            price 500-999 units:
                          </span>
                          {option.price_999}$
                        </div>
                        <div>
                          <span className="font-medium">
                            price 1000-4999 units:
                          </span>
                          {option.price_4999}$
                        </div>
                        <div>
                          <span className="font-medium">
                            price 5000-9999 units:
                          </span>
                          {option.price_9999}$
                        </div>
                        <div>
                          <span className="font-medium">weight:</span>
                          {option.weight}$
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )) ||
              (productOption.type == "text_field" && (
                <div>
                  <div className="flex justify-between">
                    <h3 className="font-semibold">
                      Option {index + 1} (TextField):
                    </h3>
                    {delete_option(index)}
                  </div>

                  <h4>Question: {productOption.text}</h4>
                </div>
              ))}
          </div>
        ))}
      </div>
      <div className="mt-4 ml-auto">
        <motion.button
          initial={{ opacity: 0, height: 0 }}
          animate={{
            height: toggleAddOption ? 0 : "auto",
            opacity: toggleAddOption ? 0 : 1,
            display: toggleAddOption ? "none" : "block",
          }}
          transition={{ duration: 0.2, display: { delay: 0.2 } }}
          onClick={() => {
            setToggleAddOption(true);
          }}
          className="admin-button"
        >
          <span className="px-8 py-1 block">Add Option</span>
        </motion.button>
        <div>
          <motion.h2
            initial={{ opacity: 0, height: 0, display: "none" }}
            animate={{
              opacity: addOptionType ? 0 : toggleAddOption ? 1 : 0,
              height: addOptionType ? 0 : toggleAddOption ? "auto" : 0,
              display: addOptionType
                ? "none"
                : toggleAddOption
                ? "block"
                : "none",
            }}
            transition={{
              delay: 0.2,
              display: {
                delay: addOptionType ? 0.4 : toggleAddOption ? 0.2 : 0,
              },
            }}
            className="overflow-y-auto scrollbar-none"
          >
            Alege tipul parametrului pe care dorești să-l adaugi
          </motion.h2>
          <div className="grid grid-cols-5 lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-3 gap-y-2  gap-x-6 mt-2">
            <ProductOptionButton
              key={1}
              type="photo_options"
              setType={setAddOptionType}
              addOptionType={addOptionType}
              toggleAddOption={toggleAddOption}
            >
              {" "}
              Photo Options
            </ProductOptionButton>
            <ProductOptionButton
              key={2}
              type="toggle_button"
              setType={setAddOptionType}
              addOptionType={addOptionType}
              toggleAddOption={toggleAddOption}
            >
              {" "}
              Toggle Button
            </ProductOptionButton>
            <ProductOptionButton
              key={3}
              type="radio_buttons"
              setType={setAddOptionType}
              addOptionType={addOptionType}
              toggleAddOption={toggleAddOption}
            >
              {" "}
              Radio Buttons
            </ProductOptionButton>
            <ProductOptionButton
              key={4}
              type="dropdown"
              setType={setAddOptionType}
              addOptionType={addOptionType}
              toggleAddOption={toggleAddOption}
            >
              {" "}
              Dropdown
            </ProductOptionButton>
            <ProductOptionButton
              key={5}
              type="text_field"
              setType={setAddOptionType}
              addOptionType={addOptionType}
              toggleAddOption={toggleAddOption}
            >
              {" "}
              Text Field
            </ProductOptionButton>
          </div>
        </div>

        <div>
          {addOptionType == "photo_options" && (
            <h2>New Option: Photo Options</h2>
          )}
          {addOptionType == "toggle_button" && (
            <h2>New Option: Toggle Button</h2>
          )}
          {addOptionType == "radio_buttons" && (
            <h2>New Option: Radio Buttons</h2>
          )}
          {addOptionType == "dropdown" && <h2>New Option: Dropdown Options</h2>}
          {addOptionType == "text_field" && <h2>New Option: Text Field</h2>}
          {addOptionType == "text_field" && (
            <form
              className="flex gap-x-2 items-center"
              onSubmit={AddTextFieldHandler}
            >
              <input
                name="text-field-text"
                className="admin-input py-1"
                placeholder="Text Field Text"
              />
              <button
                type="button"
                onClick={CancelAddOptionHandler}
                className=" bg-black h-fit px-8 py-1 text-white "
              >
                Cancel
              </button>
              <button className="admin-button h-fit w-fit px-7 py-1">
                Add Text Field
              </button>
            </form>
          )}
          {addOptionType == "dropdown" && (
            <form onSubmit={AddDropdownHandler} className="mt-2">
              <div className="flex gap-x-4">
                <input
                  className="admin-input w-fit"
                  placeholder="Question Text"
                  name="question-text"
                />
                <input
                  className="admin-input w-fit"
                  placeholder="Question Tip"
                  name="question-tip"
                />
              </div>
              <div className="grid grid-cols-4 mt-4 gap-x-4 gap-y-4">
                {dropdownOptions.map((dropdownOption, index) => (
                  <div className="flex flex-col gap-y-2" key={index}>
                    <input
                      name={"dropdown-text-" + index}
                      className="admin-input min-w-[10rem]"
                      placeholder="Dropdown Option Text"
                    />

                    <input
                      type="number"
                      name={"dropdown-price-499-" + index}
                      step="0.01"
                      placeholder="price 100-499 if selected"
                      className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                    />

                    <input
                      type="number"
                      name={"dropdown-price-999-" + index}
                      step="0.01"
                      placeholder="price 500-999 if selected"
                      className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                    />

                    <input
                      type="number"
                      name={"dropdown-price-4999-" + index}
                      step="0.01"
                      placeholder="price 1000-4999 if selected"
                      className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                    />

                    <input
                      type="number"
                      name={"dropdown-price-9999-" + index}
                      step="0.01"
                      placeholder="price 5000-9999 if selected"
                      className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                    />

                    <input
                      type="number"
                      name={"dropdown-weight-" + index}
                      step="0.01"
                      placeholder="weight if selected"
                      className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                    />

                    <button
                      type="button"
                      onClick={() => RemoveDropdownOption(index)}
                      className="text-white bg-secondary px-2 py-1"
                    >
                      <img
                        src={trashcan_white}
                        alt="trashcan"
                        className="w-5 h-5 mx-auto"
                      />
                    </button>
                  </div>
                ))}
                <button
                  onClick={() =>
                    setDropdownOptions([
                      ...dropdownOptions,
                      {
                        index: dropdownOptions.length - 1,
                        text: "",
                        price: "",
                      },
                    ])
                  }
                  className="admin-button w-fit h-fit px-6 py-1"
                  type="button"
                >
                  Add Button
                </button>
              </div>

              <div className="flex w-fit mt-4 gap-x-4 ml-auto">
                <button
                  type="button"
                  onClick={CancelAddOptionHandler}
                  className="bg-black px-8 py-1 text-white "
                >
                  Cancel
                </button>
                {dropdownOptions.length > 0 && (
                  <button className="admin-button w-fit px-7 py-1">
                    Add Dropdown Options
                  </button>
                )}
              </div>
            </form>
          )}
          {addOptionType == "radio_buttons" && (
            <form onSubmit={AddRadioButtonsHandler}>
              <div className="flex gap-x-4">
                <input
                  className="admin-input w-fit"
                  placeholder="Question Text"
                  name="question-text"
                />
                <input
                  className="admin-input w-fit"
                  placeholder="Question Tip"
                  name="question-tip"
                />
              </div>
              <div className="mt-4 grid grid-cols-4 gap-x-4 gap-y-4">
                {radioButtons.map((radioButton, index) => (
                  <div className="flex flex-col gap-y-2">
                    <input
                      name={"radio-button-text-" + index}
                      className="admin-input min-w-[10rem]"
                      placeholder="Radio Button Text"
                    />
                    <input
                      type="number"
                      name={"radio-button-price-499-" + index}
                      placeholder="price 100-499 if selected"
                      step="0.01"
                      className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                    />
                    <input
                      type="number"
                      name={"radio-button-price-999-" + index}
                      placeholder="price 500-999 if selected"
                      step="0.01"
                      className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                    />
                    <input
                      type="number"
                      name={"radio-button-price-4999-" + index}
                      placeholder="price 1000-4999 if selected"
                      step="0.01"
                      className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                    />
                    <input
                      type="number"
                      name={"radio-button-price-9999-" + index}
                      placeholder="price 5000-9999 if selected"
                      step="0.01"
                      className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                    />
                    <input
                      type="number"
                      name={"radio-button-weight-" + index}
                      placeholder="weight if selected"
                      step="0.01"
                      className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                    />{" "}
                    <button
                      type="button"
                      onClick={() => RemoveRadioOption(index)}
                      className="text-white bg-secondary px-2 py-1"
                    >
                      <img
                        src={trashcan_white}
                        alt="trashcan"
                        className="w-5 h-5 mx-auto"
                      />
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() =>
                    setRadioButtons([
                      ...radioButtons,
                      { index: radioButtons.length, text: "", price: "" },
                    ])
                  }
                  className="admin-button w-fit mt-2 h-fit px-6 py-1"
                >
                  Add Button
                </button>
              </div>

              <div className="flex w-fit mt-4 gap-x-4 ml-auto">
                <button
                  type="button"
                  onClick={CancelAddOptionHandler}
                  className="bg-black px-8 py-1 text-white "
                >
                  Cancel
                </button>
                {radioButtons.length > 0 && (
                  <button className="admin-button w-fit px-7 py-1">
                    Add Radio Buttons
                  </button>
                )}
              </div>
            </form>
          )}
          {addOptionType == "toggle_button" && (
            <form
              onSubmit={AddToggleButtonHandler}
              className="w-fit flex gap-x-4"
            >
              <div className="flex flex-col gap-y-2">
                <input
                  name="toggle-text"
                  className="admin-input"
                  placeholder="Toggle Text"
                />
                <input
                  name="toggle-tip"
                  className="admin-input"
                  placeholder="Toggle Tip"
                />
                <input
                  type="number"
                  name="toggle-weight"
                  placeholder="weight if ON"
                  className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  step="0.01"
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <input
                  type="number"
                  name="toggle-price-499"
                  placeholder="price 100-499 if ON"
                  className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  step="0.01"
                />
                <input
                  type="number"
                  name="toggle-price-999"
                  placeholder="price 500-999 if ON"
                  className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  step="0.01"
                />
                <input
                  type="number"
                  name="toggle-price-4999"
                  placeholder="price 1000-4999 if ON"
                  className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  step="0.01"
                />
                <input
                  type="number"
                  name="toggle-price-9999"
                  placeholder="price 5000-9999 if ON"
                  className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  step="0.01"
                />
              </div>
              <div className="flex flex-col w-fit gap-y-2">
                <button
                  type="button"
                  onClick={CancelAddOptionHandler}
                  className="bg-black px-8 py-1 text-white "
                >
                  Cancel
                </button>
                <button className="admin-button w-fit px-7 py-1">
                  Add Toggle Option
                </button>
              </div>
            </form>
          )}
          {addOptionType == "photo_options" && (
            <div className="flex flex-col mt-2">
              <form
                className="gap-y-4 flex flex-col relative"
                onSubmit={AddPhotoOptionHandler}
              >
                <div className="flex gap-x-4">
                  <input
                    className="admin-input w-fit"
                    placeholder="Question Text"
                    name="question-text"
                  />
                  <input
                    className="admin-input w-fit"
                    placeholder="Question Tip"
                    name="question-tip"
                  />
                </div>
                <div className="flex flex-wrap  gap-y-4 gap-x-4">
                  {photoOptions.map((photoOption, index) => (
                    <div>
                      <div className="flex gap-x-2">
                        <div className="flex flex-col gap-y-3">
                          <img
                            src={photoOption.image}
                            className="primary-border w-[10.8rem] h-fit"
                          />
                          <input
                            name={"photo-option-name-" + index}
                            className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem]"
                            placeholder="name"
                          />
                          <input
                            name={"photo-option-weight-" + index}
                            className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem] "
                            placeholder="weight if selected"
                            type="number"
                            step="0.01"
                          />
                        </div>
                        <div className="flex flex-col gap-y-3">
                          <input
                            name={"photo-option-price-499-" + index}
                            className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem] "
                            placeholder="price 100-499 if selected"
                            type="number"
                            step="0.01"
                          />
                          <input
                            name={"photo-option-price-999-" + index}
                            className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem] "
                            placeholder="price 500-999 if selected"
                            type="number"
                            step="0.01"
                          />
                          <input
                            name={"photo-option-price-4999-" + index}
                            className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem] "
                            placeholder="price 1000-4999 if selected"
                            type="number"
                            step="0.01"
                          />
                          <input
                            name={"photo-option-price-9999-" + index}
                            className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem] "
                            placeholder="price 5000-9999 if selected"
                            type="number"
                            step="0.01"
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          const newPhotoOptions = [...photoOptions];
                          newPhotoOptions.splice(index, 1);
                          setPhotoOptions(newPhotoOptions);
                        }}
                        className="text-white bg-secondary px-2 py-1 w-full mt-2 block"
                      >
                        <img
                          src={trashcan_white}
                          alt="trashcan"
                          className="w-5 h-5 mx-auto"
                        />
                      </button>
                    </div>
                  ))}
                  <AddPhotoOption
                    setPhotoOptions={setPhotoOptions}
                    photoOptions={photoOptions}
                  />
                </div>

                {photoOptions && (
                  <div className="w-fit gap-x-4 flex ml-auto">
                    <button
                      type="button"
                      onClick={CancelAddOptionHandler}
                      className="bg-black px-8 py-1 text-white "
                    >
                      Cancel
                    </button>
                    <button className=" admin-button px-8 py-1">
                      Add Photos Option
                    </button>
                  </div>
                )}
              </form>
            </div>
          )}
        </div>
      </div>
      {file && (
        <ImageCropModal
          showModal={showModal}
          exitModal={exitModal}
          file={file}
          setResultFile={setResultFile}
          setResultImage={setResultImage}
          aspectRatio={16 / 9}
        />
      )}
    </div>
  );
};

export default AdminProductNew;

import React, { useEffect, useState } from "react";
import OptionTip from "./OptionTip";
import info from "../static/images/info.svg";
import { api_url, media_url } from "../constants";

const PhotoOption = ({ option, selectedOptions, setSelectedOptions }) => {
  const [selectedOption, setSelectedOption] = useState(option.variants[0]);
  console.log(option);
  useEffect(() => {
    const index = selectedOptions.findIndex(
      (item) => item.question == option.question
    );
    if (selectedOption) {
      if (index != -1) {
        const newSelectedOptions = [...selectedOptions];
        newSelectedOptions.splice(index, 1);
        setSelectedOptions([
          ...newSelectedOptions,
          {
            type: "photo_options",
            id: option.id,
            question: option.question,
            answer: selectedOption.id,
            price_99: selectedOption.price_99,
            price_499: selectedOption.price_499,
            price_999: selectedOption.price_999,
            price_4999: selectedOption.price_4999,
            price_9999: selectedOption.price_9999,
          },
        ]);
      } else {
        setSelectedOptions([
          ...selectedOptions,
          {
            type: "photo_options",
            id: option.id,
            question: option.question,
            answer: selectedOption.id,
            price_99: selectedOption.price_99,
            price_499: selectedOption.price_499,
            price_999: selectedOption.price_999,
            price_4999: selectedOption.price_4999,
            price_9999: selectedOption.price_9999,
          },
        ]);
      }
    }
  }, [selectedOption]);
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <h3 className="text-lg font-bold flex group">
        <span className="flex items-center gap-x-2 sm:flex-col sm:w-full sm:items-start">
          <div
            className="flex items-center gap-x-2 sm:cursor-pointer"
            onClick={() => setExpanded(!expanded)}
          >
            {option.tip && option.tip.length > 0 && (
              <img src={info} className="3" />
            )}{" "}
            {option.question}
          </div>
          {option.tip && option.tip.length > 0 && (
            <OptionTip expanded={expanded}>{option.tip}</OptionTip>
          )}
        </span>
      </h3>
      <div className="grid grid-cols-5 lg:grid-cols-3 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-4 gap-x-4 gap-y-6 flex-wrap mt-2">
        {option.variants.map((variant) => (
          <div
            className={"flex flex-col gap-y-1 cursor-pointer "}
            onClick={() => setSelectedOption(variant)}
          >
            <img
              src={media_url + "/" + variant.image}
              className={
                "w-24 md:w-28 rounded-md mx-auto drop-shadow ease-in-out duration-300 transition-colors border-solid  border-2 " +
                (selectedOption.id == variant.id
                  ? "border-customGreen"
                  : "border-black")
              }
            />
            <p className="text-center text-sm font-medium">{variant.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoOption;

import React, { useState } from "react";
import trashcan_white from "../static/images/trashcan-white.svg";
import axios from "axios";
import { api_url } from "../constants";

const AdminvariantOptionsCard = ({
  option,
  index,
  DeleteOption,
  fetchOptions,
  onDragStart,
  onDragEnter,
  onDragEnd,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [optionVariants, setOptionVariants] = useState(option.variants);

  function SaveChanges(e) {
    e.preventDefault();
    const question = e.target["question-text-" + index].value;
    const tip = e.target["question-tip-" + index].value;
    const options = optionVariants;
    const type = option.type;
    const formData = new FormData();
    formData.append("question", question);
    formData.append("tip", tip);
    formData.append("type", type);
    formData.append("no_of_options", options.length);
    options.map((variant, index) => {
      formData.append("option_" + index + "_text", variant.text);
      formData.append("option_" + index + "_price_499", variant.price_499);
      formData.append("option_" + index + "_price_999", variant.price_999);
      formData.append("option_" + index + "_price_4999", variant.price_4999);
      formData.append("option_" + index + "_price_9999", variant.price_9999);
      formData.append("option_" + index + "_weight", variant.weight);
    });
    axios
      .put(
        api_url + "/product/" + option.product + "/option/" + option.id + "/",
        formData
      )
      .then((res) => {
        setIsEditing(false);
        fetchOptions();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function AddVariants() {
    const variants = [...optionVariants];
    variants.push({
      text: "",
      price_499: "",
      price_999: "",
      price_4999: "",
      price_9999: "",
      weight: "",
    });
    setOptionVariants(variants);
  }

  function DeleteVariant(index) {
    const variants = [...optionVariants];
    variants.splice(index, 1);
    setOptionVariants(variants);
  }

  function CancelEditing() {
    setIsEditing(false);
    fetchOptions();
  }

  return (
    <form
      draggable={!isEditing}
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
      onDragOver={(e) => e.preventDefault()}
      onSubmit={SaveChanges}
      className="bg-white rounded-lg p-4 drop-shadow-md"
    >
      <div className="flex justify-between items-center gap-y-2 sm:flex-col-reverse sm:items-start">
        <h3 className="font-semibold">Option {index + 1} (Radio Buttons):</h3>{" "}
        <div className="flex gap-x-4  sm:ml-auto sm:w-fit">
          {isEditing ? (
            <div
              type="button"
              onClick={CancelEditing}
              className="h-fit block px-6 bg-black text-white font-semibold cursor-pointer"
            >
              CANCEL
            </div>
          ) : (
            <div
              type="button"
              onClick={() => DeleteOption(option.id, "radio_buttons_options")}
              className="h-fit block px-6 bg-black text-white font-semibold cursor-pointer"
            >
              DELETE
            </div>
          )}
          {!isEditing ? (
            <div
              type="button"
              onClick={() => setIsEditing(true)}
              className="primary-button font-semibold rounded-none px-6 cursor-pointer"
            >
              EDIT
            </div>
          ) : (
            <button
              type="submit"
              className="primary-button font-semibold rounded-none px-6"
            >
              SAVE CHANGES
            </button>
          )}
        </div>
      </div>
      {isEditing ? (
        <div>
          <div className="flex gap-x-4">
            <input
              defaultValue={option.question}
              className="admin-input w-fit"
              placeholder="Question Text"
              name={"question-text-" + index}
            />
            <input
              defaultValue={option.tip}
              className="admin-input w-fit"
              placeholder="Question Tip"
              name={"question-tip-" + index}
            />
          </div>
          <div className="mt-4 grid grid-cols-4 gap-x-4 gap-y-4">
            {optionVariants.map((variant, index) => (
              <div className="flex flex-col gap-y-2">
                <input
                  value={variant.text}
                  onChange={(e) => {
                    const variants = [...optionVariants];
                    variants[index].text = e.target.value;
                    setOptionVariants(variants);
                  }}
                  name={"radio-button-text-" + index}
                  className="admin-input min-w-[10rem]"
                  placeholder="Radio Button Text"
                />
                <input
                  value={variant.price_499}
                  onChange={(e) => {
                    const variants = [...optionVariants];
                    variants[index].price_499 = e.target.value;
                    setOptionVariants(variants);
                  }}
                  type="number"
                  name={"radio-button-price-499-" + index}
                  placeholder="price 100-499 units if selected"
                  className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  step="0.01"
                />
                <input
                  value={variant.price_999}
                  onChange={(e) => {
                    const variants = [...optionVariants];
                    variants[index].price_999 = e.target.value;
                    setOptionVariants(variants);
                  }}
                  type="number"
                  name={"radio-button-price-999-" + index}
                  placeholder="price 500-999 units if selected"
                  className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  step="0.01"
                />
                <input
                  value={variant.price_4999}
                  onChange={(e) => {
                    const variants = [...optionVariants];
                    variants[index].price_4999 = e.target.value;
                    setOptionVariants(variants);
                  }}
                  type="number"
                  name={"radio-button-price-4999-" + index}
                  placeholder="price 1000-4999 units if selected"
                  className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  step="0.01"
                />
                <input
                  value={variant.price_9999}
                  onChange={(e) => {
                    const variants = [...optionVariants];
                    variants[index].price_9999 = e.target.value;
                    setOptionVariants(variants);
                  }}
                  type="number"
                  name={"radio-button-price-9999-" + index}
                  placeholder="price 5000-9999 units if selected"
                  className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  step="0.01"
                />
                <input
                  value={variant.weight}
                  onChange={(e) => {
                    const variants = [...optionVariants];
                    variants[index].weight = e.target.value;
                    setOptionVariants(variants);
                  }}
                  type="number"
                  name={"radio-button-weight-" + index}
                  placeholder="+weight if selected"
                  className="admin-input text-sm !min-w-[5rem] w-full py-1.5"
                  step="0.001"
                />{" "}
                <button
                  type="button"
                  onClick={() => DeleteVariant(index)}
                  className="text-white bg-secondary px-2 py-1"
                >
                  <img
                    src={trashcan_white}
                    alt="trashcan"
                    className="w-5 h-5 mx-auto"
                  />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={AddVariants}
              className="admin-button w-fit mt-2 h-fit px-6 py-1"
            >
              Add Button
            </button>
          </div>
        </div>
      ) : (
        <div>
          <h4>
            <span className="font-medium">Question:</span> {option.question}
          </h4>
          <p>
            <span className="font-medium">Tip:</span> {option.tip}
          </p>
          <div className="flex gap-x-8 gap-y-4 flex-wrap">
            {option.variants &&
              option.variants.map((variant) => (
                <div className="flex-col">
                  <div>
                    <span className="font-medium">text:</span>
                    {variant.text}
                  </div>
                  <div>
                    <span className="font-medium">price 100-499 units:</span>
                    {variant.price_499} €
                  </div>
                  <div>
                    <span className="font-medium">price 500-999 units:</span>
                    {variant.price_999} €
                  </div>
                  <div>
                    <span className="font-medium">price 1000-4999 units:</span>
                    {variant.price_4999} €
                  </div>
                  <div>
                    <span className="font-medium">price 5000-9999 units:</span>
                    {variant.price_9999} €
                  </div>
                  <div>
                    <span className="font-medium">weight:</span>
                    {variant.weight} kg
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </form>
  );
};

export default AdminvariantOptionsCard;

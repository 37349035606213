import React, { useState } from "react";
import trashcan_white from "../static/images/trashcan-white.svg";
import axios from "axios";
import { api_url } from "../constants";

const AdminTextFieldOptionCard = ({
  option,
  index,
  DeleteOption,
  fetchOptions,
  onDragStart,
  onDragEnter,
  onDragEnd,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  function SaveChanges(e) {
    e.preventDefault();
    console.log("submitting");
    const text = e.target["text-field-text-" + index].value;
    const type = option.type;
    const formData = new FormData();
    formData.append("text", text);
    formData.append("type", type);
    console.log(text);
    axios
      .put(
        api_url + "/product/" + option.product + "/option/" + option.id + "/",
        formData
      )
      .then((res) => {
        setIsEditing(false);
        fetchOptions();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function CancelEditing() {
    setIsEditing(false);
    fetchOptions();
  }
  return (
    <form
      draggable
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
      onDragOver={(e) => e.preventDefault()}
      onSubmit={SaveChanges}
      className="bg-white rounded-lg p-4 drop-shadow-md"
    >
      <div className="flex justify-between items-center gap-y-2 sm:flex-col-reverse sm:items-start">
        <h3 className="font-semibold">Option {index + 1} (TextField):</h3>
        <div className="flex gap-x-4  sm:ml-auto sm:w-fit">
          {isEditing ? (
            <div
              type="button"
              onClick={CancelEditing}
              className="h-fit block px-6 bg-black text-white font-semibold cursor-pointer"
            >
              CANCEL
            </div>
          ) : (
            <div
              type="button"
              onClick={() => DeleteOption(option.id, "text_field_options")}
              className="h-fit block px-6 bg-black text-white font-semibold cursor-pointer"
            >
              DELETE
            </div>
          )}
          {!isEditing ? (
            <div
              type="button"
              onClick={() => setIsEditing(true)}
              className="primary-button font-semibold rounded-none px-6"
            >
              EDIT
            </div>
          ) : (
            <button className="primary-button font-semibold rounded-none px-6">
              SAVE CHANGES
            </button>
          )}
        </div>
      </div>
      {isEditing ? (
        <input
          defaultValue={option.question}
          name={"text-field-text-" + index}
          className="admin-input py-1 sm:w-full"
          placeholder="Text Field Text"
        />
      ) : (
        <h4>Question: {option.question}</h4>
      )}
    </form>
  );
};

export default AdminTextFieldOptionCard;

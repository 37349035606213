import React, { useEffect, useState } from "react";
import axios from "axios";
import { api_url } from "../constants";
import { motion } from "framer-motion";
import { useParams, Link } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import confirm from "../static/images/confirm.svg";
import failed from "../static/images/failed.svg";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";

const NewsletterDeactivate = () => {
  const { token } = useParams();
  const [isDeactivated, setIsDeactivated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const userPage = useSelector((state) => state.userPage);

  useEffect(() => {
    setIsLoading(true);

    // Assuming you have a separate API endpoint for newsletter deactivation
    axios
      .post(api_url + "/newsletter/deactivate/", {
        token: token,
      })
      .then((res) => {
        setIsLoading(false);
        setIsDeactivated(true);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="mt-20">
      <Helmet>
        <title>{userPage.title}</title>
        <meta name="description" content={userPage.description} />
        <meta name="keywords" content={userPage.keywords} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Apakz" />
        <link rel="canonical" href={"https://apakz.com" + userPage.slug} />
      </Helmet>
      {isLoading ? (
        <div className="mx-auto w-fit">
          <LoadingSpinner />
        </div>
      ) : isDeactivated ? (
        <div className="flex flex-col">
          <img src={confirm} alt="confirm" className="w-20 mx-auto" />
          <h1 className="text-xl font-semibold text-center mt-5">
            You have successfully unsubscribed from our newsletter!
          </h1>
        </div>
      ) : (
        <div className="flex flex-col">
          <img src={failed} alt="failed" className="w-20 mx-auto" />
          <h1 className="text-xl font-semibold text-center mt-5">
            Failed to unsubscribe from our newsletter!
          </h1>
        </div>
      )}
    </div>
  );
};

export default NewsletterDeactivate;

import React from "react";
import { Link } from "react-router-dom";

const LinkHover = ({ children, important = false, to }) => {
  return (
    <li className="relative w-fit group cursor-pointer">
      <Link to={to} className={"px-0.5 " + (important ? "font-semibold" : "")}>
        {children}
      </Link>
      <hr className="h-1 p-0 m-0 translate-y-[-50%] w-0 group-hover:w-full bg-black transition-all ease-in-out duration-300" />
    </li>
  );
};

export default LinkHover;

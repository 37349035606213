import React, { useState, useEffect } from "react";
import { countries, api_url } from "../constants";
import axios from "axios";
import Flag from "react-flagkit";
import { motion } from "framer-motion";
import ShippingCountryCard from "../components/ShippingCountryCard";
import { shipping_types } from "../constants";
import { changePage } from "../store/adminPageSlice";
import { useDispatch } from "react-redux";
import {
  setGlobalErrorMessage,
  setGlobalNotificationMessage,
} from "../store/errorMessageSlice";
const AdminShipping = () => {
  const [shippingCountries, setShippingCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [selectedShippingType, setSelectedShippingType] = useState(
    shipping_types[0]
  );
  const [shippingPrices, setShippingPrices] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changePage("Shipping"));
    const shipping_prices = [];
    shipping_types.map((type) => {
      shipping_prices.push({
        type: type,
        price: 0,
      });
    });
    setShippingPrices(shipping_prices);

    axios
      .get(`${api_url}/shipping/countries/`)
      .then((res) => {
        setShippingCountries(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function AddShippingCountry(e) {
    e.preventDefault();
    const data = {
      name: selectedCountry.name,
      code: selectedCountry.code,
      price_standard: shippingPrices.find(
        (price) => price.type == "Standard Shipping"
      ).price,
      price_per_unit_standard: shippingPrices.find(
        (price) => price.type == "Standard Shipping"
      ).price_per_unit,
      price_express: shippingPrices.find(
        (price) => price.type == "Express Shipping"
      ).price,
      price_per_unit_express: shippingPrices.find(
        (price) => price.type == "Express Shipping"
      ).price_per_unit,
      price_economy: shippingPrices.find(
        (price) => price.type == "Economy Shipping"
      ).price,
      price_per_unit_economy: shippingPrices.find(
        (price) => price.type == "Economy Shipping"
      ).price_per_unit,
    };
    setIsAdding(true);
    axios
      .post(`${api_url}/shipping/country/`, data)
      .then((res) => {
        setShippingCountries([...shippingCountries, res.data]);
        e.target.reset();
        setIsAdding(false);
        dispatch(setGlobalNotificationMessage("Shipping country added"));
      })
      .catch((err) => {
        setIsAdding(false);
        dispatch(setGlobalErrorMessage("Failed to add shipping country"));
      });
  }

  return (
    <div className="mt-8 px-24 pb-20 lg:px-8 md:px-8 sm:px-4 xl:px-12">
      <form
        onSubmit={AddShippingCountry}
        className="grid grid-cols-5 lg:grid-cols-4 gap-x-6 md:grid-cols-3 sm:grid-cols-1 gap-y-2"
      >
        <select
          className="border-2 border-solid border-black rounded-md px-2 py-1 w-full block"
          onChange={(e) =>
            setSelectedCountry(
              countries.find((country) => country.name == e.target.value)
            )
          }
        >
          {countries.map((country) => (
            <option>
              <Flag country={country.code.toUpperCase()} />
              {country.name}
            </option>
          ))}
        </select>
        <select
          onChange={(e) => setSelectedShippingType(e.target.value)}
          className="border-2 border-solid border-black rounded-md px-2 py-1 w-full block"
        >
          {shipping_types.map((type) => (
            <option
              selected={selectedShippingType == type}
              value={type}
              key={type}
            >
              {type}
            </option>
          ))}
        </select>
        {shippingPrices.map((shippingPrice) => (
          <motion.input
            initial={{ opacity: 0, display: "none" }}
            animate={{
              opacity: selectedShippingType == shippingPrice.type ? 1 : 0,
              display:
                selectedShippingType == shippingPrice.type ? "block" : "none",
            }}
            transition={{
              duration: 0.3,
              display: {
                delay: selectedShippingType == shippingPrice.type ? 0.3 : 0.3,
              },
            }}
            className="border-2 border-solid border-black rounded-md px-2 py-1 w-full block"
            type="number"
            placeholder="Price"
            step="0.01"
            onChange={(e) => {
              const newShippingPrices = [...shippingPrices];
              newShippingPrices[
                shippingPrices.findIndex(
                  (price) => price.type == shippingPrice.type
                )
              ].price = e.target.value;
              setShippingPrices(newShippingPrices);
            }}
          />
        ))}
        {shippingPrices.map((shippingPrice) => (
          <motion.input
            initial={{ opacity: 0, display: "none" }}
            animate={{
              opacity: selectedShippingType == shippingPrice.type ? 1 : 0,
              display:
                selectedShippingType == shippingPrice.type ? "block" : "none",
            }}
            transition={{
              duration: 0.3,
              display: {
                delay: selectedShippingType == shippingPrice.type ? 0.3 : 0.3,
              },
            }}
            className="border-2 border-solid border-black rounded-md px-2 py-1 w-full block"
            type="number"
            placeholder="Price per kg"
            step={0.01}
            onChange={(e) => {
              const newShippingPrices = [...shippingPrices];
              newShippingPrices[
                shippingPrices.findIndex(
                  (price) => price.type == shippingPrice.type
                )
              ].price_per_unit = e.target.value;
              setShippingPrices(newShippingPrices);
            }}
          />
        ))}
        <button className="primary-button py-1 rounded-none px-4 font-semibold w-full block">
          Add shipping country
        </button>
      </form>
      <div className="grid font-semibold py-1 px-4 grid-cols-5  mt-4 md:hidden sm:hidden">
        <div>Country</div>
        <div>Price Standard</div>
        <div>Price Express</div>
        <div>Price Economy</div>
      </div>
      <div className="flex flex-col gap-y-2 md:mt-4 sm:mt-4">
        {shippingCountries.map((country) => (
          <ShippingCountryCard
            key={country.id}
            country={country}
            shippingCountries={shippingCountries}
            setShippingCountries={setShippingCountries}
          />
        ))}
      </div>
    </div>
  );
};

export default AdminShipping;

import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { api_url } from "../constants";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import ProductCard from "../components/ProductCard";
const SearchResults = () => {
  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState(products);
  const userPage = useSelector((state) => state.userPage);

  useEffect(() => {
    axios.get(`${api_url}/products/search/?q=${q}`).then((response) => {
      setProducts(response.data);
      console.log(response.data);
      //filter all the categories
      const allCategories = [];
      response.data.forEach((product) => {
        allCategories.push(...product.categories);
      });
      const uniqueCategories = [...new Set(allCategories)];
      console.log(uniqueCategories);
      setCategories(uniqueCategories);
      setFilteredProducts(response.data);
    });
  }, [q]);
  useEffect(() => {
    if (selectedCategory) {
      const filteredProducts = products.filter((product) => {
        return product.categories.includes(selectedCategory);
      });
      console.log(filteredProducts);
      setFilteredProducts(filteredProducts);
    }
  }, [selectedCategory]);
  return (
    <div className=" mt-8">
      <Helmet>
        <title>{userPage.title}</title>
        <meta name="description" content={userPage.description} />
        <meta name="keywords" content={userPage.keywords} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Apakz" />
        <link rel="canonical" href={"https://apakz.com" + userPage.slug} />
      </Helmet>
      <h1 className="mx-52 xl:mx-40 lg:mx-12 md:mx-6 sm:mx-6 font-karla text-4xl mt-4 font-bold md:text-3xl sm:text-2xl sm:text-center ">
        We found {products.length} products for you
      </h1>
      {products.length > 0 && (
        <>
          <div className="bg-primary flex px-52 py-2 mt-4 mix-blend-multiply scrollbar-thumb-white xl:px-40 lg:px-12 md:px-6 sm:px-6 overflow-x-auto w-full">
            <ul className="flex w-full whitespace-nowrap">
              <li className="font-semibold text-white border-r-2 border-r-white border-r-solid pr-8">
                <div
                  onClick={() => setSelectedCategory(null)}
                  className="group relative cursor-pointer px-1"
                >
                  All
                  <div
                    className={
                      "absolute bottom-0 left-0 transition-all ease-in-out duration-200 h-0.5 bg-white group-hover:w-full " +
                      (selectedCategory == null ? "w-full" : "w-0")
                    }
                  ></div>
                </div>
              </li>{" "}
              {categories.map((category) => (
                <li className="font-semibold text-white border-r-2 border-r-white border-r-solid px-8">
                  <div
                    onClick={() => setSelectedCategory(category)}
                    className="group relative cursor-pointer px-1"
                  >
                    {category.name}
                    <div
                      className={
                        "absolute bottom-0 left-0 transition-all ease-in-out duration-200 h-0.5 bg-white group-hover:w-full " +
                        (selectedCategory && selectedCategory.id == category.id
                          ? "w-full"
                          : "w-0")
                      }
                    ></div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="grid grid-cols-3 px-52 mt-8 gap-16 xl:px-40 lg:px-12 md:px-6 sm:px-6 lg:gap-x-12 md:gap-x-8 gap-y-8 md:grid-cols-2 sm:grid-cols-1">
            {filteredProducts.map((product, index) => (
              <ProductCard key={product.id} product={product} index={index} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SearchResults;

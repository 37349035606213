import React from "react";

const WhyChoose = ({ image, header, paragraph, last = false }) => {
  return (
    <div className="py-3 px-6 relative">
      <div className="absolute right-0 bottom-0 h-[50%] w-0.5 bg-black/50"></div>
      <div className="absolute left-0 hidden sm:block bottom-0 h-[50%] w-0.5 bg-black/50"></div>
      <img src={image} className="w-20 mx-auto" />
      <h3 className="w-fit mx-auto font-bold mt-4 text-lg">{header}</h3>
      <p className="mt-2">{paragraph}</p>
    </div>
  );
};

export default WhyChoose;

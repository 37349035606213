import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_url } from "../constants";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
const FAQ = () => {
  const [faqs, setFaqs] = useState([]);
  const userPage = useSelector((state) => state.userPage);

  useEffect(() => {
    axios.get(`${api_url}/frequently-asked-questions/`).then((res) => {
      setFaqs(res.data);
    });
  }, []);
  return (
    <div className="mx-52 mt-8 xl:mx-40 lg:mx-12 md:mx-6 sm:mx-6 relative gap-y-4 flex flex-col">
      <Helmet>
        <title>{userPage.title}</title>
        <meta name="description" content={userPage.description} />
        <meta name="keywords" content={userPage.keywords} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Apakz" />
        <link rel="canonical" href={"https://apakz.com" + userPage.slug} />
      </Helmet>
      {faqs.map((faq) => (
        <div className="">
          <h2 className="text-2xl md:text-xl sm:text-lg font-semibold">
            {faq.question}
          </h2>
          <p className="text-lg mt-1 md:text-base sm:text-base">{faq.answer}</p>
        </div>
      ))}
    </div>
  );
};

export default FAQ;

import { createSlice } from '@reduxjs/toolkit'

const initialState = false

const showLoginSlice = createSlice({
    name: 'showLogin',
    initialState,
    reducers: {
        show(state, action) {
            state=action.payload
            return state
        },
    },
})

export const { show } = showLoginSlice.actions
export default showLoginSlice.reducer
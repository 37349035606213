import React, { useState } from "react";
import { motion } from "framer-motion";
import { api_url } from "../constants";
import axios from "axios";
import check from "../static/images/check.svg";

const AdminCategoryCard = ({ removeCategory, category }) => {
  const [categoryModified, setCategoryModified] = useState(false);
  const [isCardClosing, setIsCardClosing] = useState(false);
  function ModifyCategory(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    axios
      .put(api_url + "/category/" + category.id + "/", formData)
      .then((res) => {
        setCategoryModified(false);
      });
  }

  function DeleteCategory(e) {
    e.preventDefault();
    axios.delete(api_url + "/category/" + category.id + "/").then((res) => {
      setIsCardClosing(true);
      setTimeout(() => {
        removeCategory(category.id);
      }, 500);
    });
  }

  return (
    <motion.form
      initial={{ height: "auto" }}
      animate={{ height: isCardClosing ? 0 : "auto" }}
      onSubmit={ModifyCategory}
      onChange={() => setCategoryModified(true)}
      className="bg-white scrollbar-none overflow-auto rounded-2xl drop-shadow "
    >
      <div className="flex justify-between p-4">
        <input
          name="name"
          className="transparent-input"
          defaultValue={category.name}
        />
        <div className="flex gap-x-6">
          <motion.button
            type="submit"
            initial={{ opacity: 0, display: "none", x: 20 }}
            animate={{
              opacity: categoryModified ? 1 : 0,
              x: categoryModified ? 0 : 20,
              display: categoryModified ? "block" : "none",
            }}
            className="relative text-3xl font-bold group flex items-center"
          >
            <img className="w-4" src={check} />
          </motion.button>
          <button
            type="button"
            onClick={DeleteCategory}
            className="relative text-3xl font-bold group flex items-center"
          >
            -
          </button>
        </div>
      </div>
    </motion.form>
  );
};

export default AdminCategoryCard;

import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import logo from "../static/images/logo.svg";
import shopping_cart from "../static/images/shopping-cart.svg";
import account from "../static/images/account.svg";
import search from "../static/images/search.svg";
import LinkHover from "../components/LinkHover";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import LoginForm from "../components/LoginForm";
import axios from "axios";
import { api_url, auth_url } from "../constants";
import queryString from "query-string";
import { show } from "../store/showLoginSlice";
import { userLoaded, loginFail, loginSuccess } from "../store/authSlice";
import { set } from "../store/cartSlice";
import NewsletterCard from "../components/NewsletterCard";
import HoverProductItem from "../components/HoverProductItem";
import { setUserPage } from "../store/userPageSlice";
import burger from "../static/images/burger.svg";
import SearchBar from "../components/SearchBar";
import useMediaQuery from "../hooks/useMediaQuery";

const UserLayout = () => {
  const [servicesHover, setServicesHover] = useState(false);
  const [services, setServices] = useState([]);
  const auth = useSelector((state) => state.auth);
  const [cartItems, setCartItems] = useState([]);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const isSM = useMediaQuery("(max-width: 640px)");

  const [isAuthenticated, setIsAuthenticated] = useState(
    auth.user ? true : false
  );
  const location = useLocation();
  const pathname = location.pathname;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(api_url + "/page/seo/", {
        params: {
          slug: pathname,
        },
      })
      .then((res) => {
        dispatch(setUserPage(res.data));
      })
      .catch((err) => {});
  }, [pathname]);
  console.log(isSM);
  useEffect(() => {
    axios
      .post(`${api_url}/statistics/website/view/`)
      .then((res) => {})
      .catch((err) => {});

    const values = queryString.parse(location.search);
    const state = values.state;
    const code = values.code;
    console.log(values);
    if (state && code) {
      axios
        .post(
          auth_url + "/o/google-oauth2/?state=" + state + "&code=" + code,
          {},
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          dispatch(loginSuccess(res.data));
        })
        .catch((err) => {
          console.log(err);
        });
    }

    axios
      .post(
        `${auth_url}/jwt/verify/`,
        {
          token: auth.access,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        axios
          .get(api_url + "/user/", {
            params: {
              token: auth.access,
            },
          })
          .then((res) => {
            dispatch(userLoaded(res.data));
            axios
              .get(api_url + "/user/cart/count/", {
                params: {
                  token: auth.access,
                },
              })
              .then((res) => {
                dispatch(set(res.data));
              })
              .catch((err) => {
                console.log(err);
              });
            axios
              .get(`${api_url}/user/cart/items/`, {
                params: {
                  token: auth.access,
                },
              })
              .then((res) => {
                setCartItems(res.data);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {});
        setIsAuthenticated(true);
      })
      .catch((err) => {
        dispatch(loginFail());
        setIsAuthenticated(false);
      });
  }, [auth.access]);

  useEffect(() => {
    axios
      .get(api_url + "/services/names/")
      .then((res) => {
        setServices(res.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (isSM) {
      return;
    }
    const services_labels = document.getElementById("services");
    const services_hover = document.getElementById("services-hover");
    services_hover.style.left =
      services_labels.getBoundingClientRect().x + "px";
    services_hover.style.top = services_labels.getBoundingClientRect().y + "px";
  }, [window.innerWidth, window.innerHeight]);

  function HandleUserButton() {
    if (isAuthenticated) {
      navigate("/account/profile");
    } else {
      dispatch(show(true));
    }
  }
  const cart = useSelector((state) => state.cart);
  useEffect(() => {
    if (auth.user) {
      setIsAuthenticated(true);
    }
  }, [auth.user]);
  const [isNewCartItem, setIsNewCartItem] = useState(false);

  useEffect(() => {
    setIsNewCartItem(true);

    setTimeout(() => {
      setIsNewCartItem(false);
    }, 500);
  }, [cart]);
  const [cartHovered, setCartHovered] = useState(false);
  const [cartItemsHovered, setCartItemsHovered] = useState(false);

  useEffect(() => {
    if (isSM || pathname === "/cart") {
      return;
    }

    const cart_coord_x = document
      .getElementById("cart")
      .getBoundingClientRect().x;
    document.getElementById("cart-hover").style.left = cart_coord_x + "px";
    const cart_coord_y = document
      .getElementById("cart")
      .getBoundingClientRect().y;
    document.getElementById("cart-hover").style.top = cart_coord_y + "px";
  }, [window.innerWidth, pathname]);

  useEffect(() => {
    if (isAuthenticated) {
      axios
        .get(`${api_url}/user/cart/items/`, {
          params: {
            token: auth.access,
          },
        })
        .then((res) => {
          setCartItems(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isAuthenticated, cart]);

  return (
    <div className="flex flex-col min-h-[100vh]">
      {isSM && (
        <motion.div
          initial={{ x: "-100%" }}
          animate={{ x: isMenuOpened ? "0%" : "-100%" }}
          transition={{ duration: 0.2, ease: "easeIn" }}
          className="fixed list-none inset-0 bg-texture bg-repeat bg-contain flex flex-col items-center justify-center z-10 gap-y-8 text-lg font-medium"
        >
          <motion.img
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.1 }}
            src={burger}
            className="w-6 h-6 absolute top-8 right-8 cursor-pointer"
            onClick={() => setIsMenuOpened(false)}
          />
          <span onClick={(e) => setIsMenuOpened(false)}>
            <LinkHover to="/" onClick={(e) => setIsMenuOpened(false)}>
              Home
            </LinkHover>
          </span>
          <div
            onClick={() => setServicesHover(!servicesHover)}
            className="relative cursor-pointer"
          >
            <span
              className={
                "text-nowrap break-keep	 whitespace-nowrap	" +
                (servicesHover ? " font-bold" : "")
              }
            >
              Our Services
            </span>
            <motion.ul
              initial={{ opacity: 0, height: 0 }}
              animate={{
                opacity: servicesHover ? 1 : 0,

                height: servicesHover ? "auto" : 0,
              }}
              transition={{ duration: 0.1, ease: "easeInOut", delay: 0.1 }}
              className="overflow-y-auto flex text-center mt-3 justify-center items-center flex-col gap-y-2 scrollbar-none w-full"
            >
              {services.map((service, index) => (
                <span onClick={(e) => setIsMenuOpened(false)}>
                  <LinkHover
                    key={index}
                    onClick={(e) => setIsMenuOpened(false)}
                    to={"/service/" + service.slug}
                  >
                    {service.title}
                  </LinkHover>
                </span>
              ))}
            </motion.ul>
          </div>
          <span onClick={(e) => setIsMenuOpened(false)}>
            <LinkHover to="/about">About</LinkHover>
          </span>
          <Link
            to="/contact"
            onClick={(e) => setIsMenuOpened(false)}
            className=" primary-button py-1 px-4"
          >
            Contact
          </Link>
        </motion.div>
      )}
      <nav className="flex relative w-full px-52  py-8 justify-between sm:justify-center items-center xl:px-40 lg:px-12 md:flex-col gap-y-4 md:px-6 sm:px-6">
        <Link to="/" className="sm:absolute sm:left-1/2 sm:-translate-x-1/2">
          <img src={logo} className="w-44 sm:w-32 h-fit" />
        </Link>
        {isSM && (
          <motion.img
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.1 }}
            src={burger}
            className="w-6 h-6 absolute top-8 left-8 hidden sm:block cursor-pointer"
            onClick={() => setIsMenuOpened(true)}
          />
        )}
        {/* {isSM && <SearchBar />} */}
        <ul className="relative flex gap-x-8 items-center font-medium md:flex-col gap-y-4 md:w-full sm:ml-auto sm:flex-col">
          <div className="list-none sm:hidden flex gap-x-8 items-center justify-between md:w-full">
            <LinkHover to="/">Home</LinkHover>

            <div
              onMouseEnter={() => setServicesHover(true)}
              onMouseLeave={() => setServicesHover(false)}
              className="relative cursor-pointer text-nowrap break-keep	 whitespace-nowrap"
              id="services"
            >
              Our Services<div className="h-1"></div>
            </div>

            <LinkHover to="/about">About</LinkHover>
            <li>
              <SearchBar />
            </li>
          </div>
          <div className="flex gap-x-8 sm:gap-x-6 items-center md:ml-auto sm:w-full">
            <motion.li
              onHoverStart={() => setCartHovered(true)}
              onMouseLeave={() => {
                setTimeout(() => {
                  setCartHovered(false);
                }, 1000);
              }}
              initial={{ scale: 1 }}
              animate={{ scale: isNewCartItem ? 1.2 : 1 }}
              transition={{ duration: 0.25 }}
              className="relative w-fit block"
              id="cart"
            >
              <Link
                to={isAuthenticated && "/cart"}
                onClick={!isAuthenticated && HandleUserButton}
              >
                <img src={shopping_cart} className="w-6 sm:w-[1.35rem]" />
                {isAuthenticated && cart != 0 && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="text-white w-4 h-4 text-center top-0 right-0 -translate-y-1/2 translate-x-1/2 flex items-center justify-center absolute text-xs bg-primary rounded-full p-0.5"
                  >
                    {cart}
                  </motion.div>
                )}
              </Link>
            </motion.li>
            <li>
              <img
                onClick={HandleUserButton}
                src={account}
                className="w-6 sm:w-[1.35rem] cursor-pointer"
              />
            </li>
            <li className="sm:hidden">
              <Link to="/contact" className=" primary-button py-1 px-4">
                Contact
              </Link>
            </li>
          </div>
        </ul>
      </nav>
      <LoginForm />
      <div className="relative">
        <Outlet />
      </div>
      <footer className="pt-28 mt-auto">
        <div className="grid-cols-6 gap-y-8 sm:grid-cols-2 px-52 grid xl:px-40 lg:px-12 md:px-6 sm:px-6 lg:grid-cols-5">
          <img
            src={logo}
            className="sm:w-40 sm:mx-auto lg:col-span-2 md:col-span-2 sm:col-span-2 w-2/3"
          />

          <ul className="md:col-span-2">
            <LinkHover important={true}>Home</LinkHover>
            <LinkHover to={"/service/packaging"}>Packaging</LinkHover>
            <LinkHover to="/service/design">Design</LinkHover>
            <LinkHover to="/service/printing">Printing</LinkHover>
          </ul>
          <ul className="sm:flex sm:flex-col sm:items-end md:col-span-2 sm:text-right">
            <LinkHover to={"/about"} important={true}>
              About
            </LinkHover>
            <LinkHover to={"/contact"}>Contacts</LinkHover>
            <LinkHover to="/frequently-asked-questions">FAQ</LinkHover>
            <LinkHover to={"/terms-and-conditions"}>
              Terms and Conditions
            </LinkHover>
          </ul>
          <div className="col-span-3 sm:col-span-2 lg:col-span-2">
            <NewsletterCard />
          </div>
        </div>
        <div className="py-1.5 mt-8 sm:mt-14 bg-primary w-full justify-between flex items-center px-4">
          <p className=" text-sm font-semibold text-white w-fit">
            © 2023 apakz. All rights reserved
          </p>
          <p className="text-white w-fit text-sm font-semibold">
            made by scriptify
          </p>
        </div>
      </footer>
      {isSM
        ? ""
        : pathname !== "/cart" && (
            <motion.div
              id="cart-hover"
              onMouseEnter={() => setCartItemsHovered(true)}
              onMouseLeave={() => setCartItemsHovered(false)}
              initial={{ opacity: 0, height: 0 }}
              animate={{
                opacity:
                  isAuthenticated &&
                  cart != 0 &&
                  (cartHovered || cartItemsHovered)
                    ? 1
                    : 0,
                height:
                  isAuthenticated &&
                  cart != 0 &&
                  (cartHovered || cartItemsHovered)
                    ? "auto"
                    : 0,
                translateY:
                  isAuthenticated &&
                  cart != 0 &&
                  (cartHovered || cartItemsHovered)
                    ? "2.75rem"
                    : "2.5rem",
              }}
              transition={{ duration: 0.2, ease: "easeInOut", delay: 0.1 }}
              className="absolute overflow-y-auto rounded-md w-fit scrollbar-none bg-white z-50"
            >
              <div className="py-3  px-4 flex flex-col  gap-y-2 w-full">
                {cartItems.map((item) => (
                  <HoverProductItem
                    items={cartItems}
                    setItems={setCartItems}
                    item={item}
                  />
                ))}
                <Link
                  to="/cart"
                  className="text-center green-button rounded-md py-1 text-sm font-semibold"
                >
                  View cart
                </Link>{" "}
              </div>
            </motion.div>
          )}
      {!isSM && (
        <motion.ul
          id="services-hover"
          initial={{ opacity: 0, translateY: "1rem", height: 0 }}
          animate={{
            opacity: servicesHover ? 1 : 0,
            translateY: servicesHover ? "2rem" : "1rem",
            height: servicesHover ? "auto" : "0",
          }}
          onMouseEnter={() => setServicesHover(true)}
          onMouseLeave={() => setServicesHover(false)}
          transition={{ duration: 0.2, ease: "easeInOut", delay: 0.1 }}
          className="overflow-y-auto flex flex-col gap-y-2 scrollbar-none absolute w-full"
        >
          {services.map((service, index) => (
            <LinkHover key={index} to={"/service/" + service.slug}>
              {service.title}
            </LinkHover>
          ))}
        </motion.ul>
      )}
    </div>
  );
};

export default UserLayout;

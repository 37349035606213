import axios from "axios";
import { auth_url } from "../constants";
async function CheckUserAuthentication() {
  const token = localStorage.getItem("access");
  try {
    const response = await axios.post(
      `${auth_url}/jwt/verify/`,
      {
        token: token,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
}

export default CheckUserAuthentication;

function convertPythonDateTimetoDateTime(date) {
    let date_time = date.split("T");

    let date_split = date_time[0].split("-");
    let time_split = date_time[1].split(":");
    let day = date_split[2];
    let month = date_split[1];
    let year = date_split[0];
    let hour = time_split[0];
    let minute = time_split[1];
    let date_time_string = `${day}/${month}/${year} ${hour}:${minute}`;

    return date_time_string;
}

export default convertPythonDateTimetoDateTime;
import { Route, Routes, useLocation } from "react-router-dom";
import UserLayout from "./layouts/UserLayout";
import Home from "./screens/Home";
import AdminLayout from "./layouts/AdminLayout";
import AdminServices from "./screens/AdminServices";
import AdminCategories from "./screens/AdminCategories";
import AdminProducts from "./screens/AdminProducts";
import Contact from "./screens/Contact";
import Service from "./screens/Service";
import ActivateAccount from "./screens/ActivateAccount";
import PasswordReset from "./screens/PasswordReset";
import Product from "./screens/Product";
import UserAccountLayout from "./layouts/UserAccountLayout";
import UserAccountProfile from "./screens/UserAccountProfile";
import UserAccountOrderHistory from "./screens/UserAccountOrderHistory";
import UserAccountFavourites from "./screens/UserAccountFavourites";
import ResetEmail from "./screens/ResetEmail";
import AdminNewsletter from "./screens/AdminNewsletter";
import NewsletterActivate from "./screens/NewsletterActivate";
import NewsletterDeactivate from "./screens/NewsletterDeactivate";
import AdminProductNew from "./screens/AdminProductNew";
import AdminProduct from "./screens/AdminProduct";
import AdminProductEdit from "./screens/AdminProductEdit";
import AdminShipping from "./screens/AdminShipping";
import UserCart from "./screens/UserCart";
import AdminBundles from "./screens/AdminBundles";
import AdminBundlesNew from "./screens/AdminBundlesNew";
import AdminOrders from "./screens/AdminOrders";
import AdminOrder from "./screens/AdminOrder";
import AdminSeo from "./screens/AdminSeo";
import AdminSeoAdd from "./screens/AdminSeoAdd";
import AdminSeoPage from "./screens/AdminSeoPage";
import AdminFAQ from "./screens/AdminFAQ";
import About from "./screens/About";
import FAQ from "./screens/FAQ";
import TermsAndConditions from "./screens/TermsAndConditions";
import AdminStatistics from "./screens/AdminStatistics";
import SearchResults from "./screens/SearchResults";
import ErrorMessage from "./components/ErrorMessage";
import { useEffect } from "react";
import AdminContacts from "./screens/AdminContacts";
import AdminContact from "./screens/AdminContact";
import AdminBundle from "./screens/AdminBundle";
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
  const location = useLocation();
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [location]);

  return (
    <div className="App">
      <Routes>
        <Route path="" element={<UserLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/service/:slug" element={<Service />} />
          <Route path="/product/:slug" element={<Product />} />
          <Route path="/activate/:uid/:token" element={<ActivateAccount />} />
          <Route path="/cart" element={<UserCart />} />
          <Route path="/about" element={<About />} />
          <Route path="/frequently-asked-questions" element={<FAQ />} />
          <Route path="/search" element={<SearchResults />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route
            path="/password/reset/confirm/:uid/:token"
            element={<PasswordReset />}
          />
          <Route
            path="/email/reset/confirm/:uid/:token"
            element={<ResetEmail />}
          />
          <Route
            path="/newsletter/activate/:token"
            element={<NewsletterActivate />}
          />
          <Route
            path="/newsletter/deactivate/:token"
            element={<NewsletterDeactivate />}
          />
          <Route path="/account" element={<UserAccountLayout />}>
            <Route path="/account/profile" element={<UserAccountProfile />} />
            <Route
              path="/account/order-history"
              element={<UserAccountOrderHistory />}
            />
            <Route
              path="/account/favourites"
              element={<UserAccountFavourites />}
            />
          </Route>
        </Route>
        <Route path="/admin" element={<AdminLayout />}>
          <Route path="/admin/services" element={<AdminServices />} />
          <Route path="/admin/categories" element={<AdminCategories />} />
          <Route path="/admin/products" element={<AdminProducts />} />
          <Route path="/admin/products/new" element={<AdminProductNew />} />
          <Route path="/admin/products/:id" element={<AdminProduct />} />
          <Route path="/admin/shipping" element={<AdminShipping />} />
          <Route path="/admin/bundles" element={<AdminBundles />} />
          <Route path="/admin/bundles/new" element={<AdminBundlesNew />} />
          <Route path="/admin/orders" element={<AdminOrders />} />
          <Route path="/admin/orders/:id" element={<AdminOrder />} />
          <Route path="/admin/seo" element={<AdminSeo />} />
          <Route path="/admin/seo/add" element={<AdminSeoAdd />} />
          <Route path="/admin/seo/:id" element={<AdminSeoPage />} />
          <Route path="/admin/statistics" element={<AdminStatistics />} />
          <Route path="/admin/faq" element={<AdminFAQ />} />
          <Route path="/admin/bundle/:id" element={<AdminBundle />} />
          <Route
            path="/admin/products/:id/edit"
            element={<AdminProductEdit />}
          />
          <Route path="/admin/newsletter" element={<AdminNewsletter />} />
          <Route path="/admin/contacts" element={<AdminContacts />} />
          <Route path="/admin/contacts/:id" element={<AdminContact />} />
        </Route>
      </Routes>
      <ErrorMessage />
    </div>
  );
}

export default App;

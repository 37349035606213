import React, { useState } from "react";
import trashcan_white from "../static/images/trashcan-white.svg";
import axios from "axios";
import { api_url, media_url } from "../constants";
import { motion } from "framer-motion";
import AddPhotoOption from "./AddPhotoOption";
const AdminPhotoButtonCard = ({
  option,
  index,
  DeleteOption,
  fetchOptions,
  onDragStart,
  onDragEnter,
  onDragEnd,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [optionVariants, setOptionVariants] = useState(option.variants);
  function DeleteVariant(index) {
    const variants = [...optionVariants];
    variants.splice(index, 1);
    setOptionVariants(variants);
  }

  function CancelEditing() {
    setIsEditing(false);
    fetchOptions();
    setOptionVariants(option.variants);
  }
  function SaveChanges(e) {
    e.preventDefault();
    const question = e.target["question-text-" + index].value;
    const tip = e.target["question-tip-" + index].value;
    const options = optionVariants;
    const type = option.type;
    const formData = new FormData();
    formData.append("question", question);
    formData.append("tip", tip);
    formData.append("type", type);
    formData.append("no_of_options", options.length);
    options.map((option, index) => {
      formData.append(
        "option_" + index + "_text",
        e.target["photo-option-name-" + index].value
      );
      formData.append(
        "option_" + index + "_price_499",
        e.target["photo-option-price-499-" + index].value
      );
      formData.append(
        "option_" + index + "_price_999",
        e.target["photo-option-price-999-" + index].value
      );
      formData.append(
        "option_" + index + "_price_4999",
        e.target["photo-option-price-4999-" + index].value
      );
      formData.append(
        "option_" + index + "_price_9999",
        e.target["photo-option-price-9999-" + index].value
      );

      formData.append(
        "option_" + index + "_weight",
        e.target["photo-option-weight-" + index].value
      );
      if (option.id) {
        formData.append("option_" + index + "_id", option.id);
      } else {
        formData.append(
          "option_" + index + "_image_type",
          option.file.type.split("/")[1]
        );
        formData.append("option_" + index + "_image", option.file);
      }
    });
    axios
      .put(
        api_url + "/product/" + option.product + "/option/" + option.id + "/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        fetchOptions();
        setIsEditing(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  console.log(optionVariants);

  return (
    <motion.form
      draggable={!isEditing}
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
      onDragOver={(e) => e.preventDefault()}
      onSubmit={SaveChanges}
      initial={{
        minHeight: "fit-content",
      }}
      animate={{
        minHeight: isEditing ? "100vh" : "fit-content",
      }}
      className="bg-white rounded-lg p-4 drop-shadow-md"
    >
      <div className="flex justify-between items-center gap-y-2 sm:flex-col-reverse sm:items-start ">
        <h3 className="font-semibold">Option {index + 1} (Photo Options):</h3>
        <div className="flex gap-x-4 sm:ml-auto sm:w-fit">
          {isEditing ? (
            <div
              type="button"
              onClick={CancelEditing}
              className="h-fit block px-6 bg-black text-white font-semibold cursor-pointer"
            >
              CANCEL
            </div>
          ) : (
            <div
              type="button"
              onClick={() => DeleteOption(option.id, "photo_options")}
              className="h-fit block px-6 bg-black text-white font-semibold cursor-pointer"
            >
              DELETE
            </div>
          )}
          {!isEditing ? (
            <div
              type="button"
              onClick={() => setIsEditing(true)}
              className="primary-button font-semibold rounded-none px-6 cursor-pointer"
            >
              EDIT
            </div>
          ) : (
            <button
              type="submit"
              className="primary-button font-semibold rounded-none px-6"
            >
              SAVE CHANGES
            </button>
          )}
        </div>
      </div>
      {isEditing ? (
        <div className="flex flex-col mt-2">
          <div className="gap-y-4 flex flex-col ">
            <div className="flex gap-x-4 gap-y-2 sm:flex-col">
              <input
                defaultValue={option.question}
                className="admin-input w-fit sm:w-full"
                placeholder="Question Text"
                name={"question-text-" + index}
              />
              <input
                defaultValue={option.tip}
                className="admin-input w-fit sm:w-full"
                placeholder="Question Tip"
                name={"question-tip-" + index}
              />
            </div>
            <div className="flex flex-wrap  gap-y-4 gap-x-4">
              {optionVariants.map((photoOption, index) => (
                <div key={photoOption.image}>
                  <div className="flex gap-x-2">
                    <img
                      src={
                        photoOption.id
                          ? media_url + "/" + photoOption.image
                          : photoOption.image
                      }
                      className="primary-border w-[7.5rem] h-fit"
                    />
                    <div className="flex flex-col gap-y-3">
                      <input
                        defaultValue={photoOption.text}
                        name={"photo-option-name-" + index}
                        className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem]"
                        placeholder="name"
                      />
                      <input
                        defaultValue={photoOption.price_499}
                        name={"photo-option-price-499-" + index}
                        className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem] "
                        placeholder="price 100-499 units"
                        type="number"
                        step="0.01"
                      />
                      <input
                        defaultValue={photoOption.price_999}
                        name={"photo-option-price-999-" + index}
                        className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem] "
                        placeholder="price 500-999 units"
                        type="number"
                        step="0.01"
                      />
                      <input
                        defaultValue={photoOption.price_4999}
                        name={"photo-option-price-4999-" + index}
                        className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem] "
                        placeholder="price 1000-4999 units"
                        type="number"
                        step="0.01"
                      />
                      <input
                        defaultValue={photoOption.price_9999}
                        name={"photo-option-price-9999-" + index}
                        className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem] "
                        placeholder="price 5000-9999 units"
                        type="number"
                        step="0.01"
                      />
                      <input
                        defaultValue={photoOption.weight}
                        name={"photo-option-weight-" + index}
                        className="admin-input py-1 px-2 w-fit block text-sm !min-w-[10rem] "
                        placeholder="+weight if selected"
                        type="number"
                        step="0.001"
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => DeleteVariant(index)}
                    className="text-white bg-secondary px-2 py-1 w-full mt-2 block"
                  >
                    <img
                      src={trashcan_white}
                      alt="trashcan"
                      className="w-5 h-5 mx-auto"
                    />
                  </button>
                </div>
              ))}
              <AddPhotoOption
                setPhotoOptions={setOptionVariants}
                photoOptions={optionVariants}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h4>
            <span className="font-medium">Question:</span> {option.question}
          </h4>
          <p>
            <span className="font-medium">Tip:</span> {option.tip}
          </p>
          <div className="flex flex-wrap gap-x-4 gap-y-4">
            {optionVariants &&
              optionVariants.map((variant) => (
                <div className="flex gap-x-4 mt-2">
                  <img
                    src={media_url + "/" + variant.image}
                    className="w-20 h-20 primary-border"
                  />
                  <div>
                    <p>
                      <span className="font-medium">text: </span>
                      {variant.text}
                    </p>
                    <p>
                      {" "}
                      <span className="font-medium">price 100-499 units: </span>
                      {variant.price_499} $
                    </p>
                    <p>
                      {" "}
                      <span className="font-medium">price 500-999 units: </span>
                      {variant.price_999} $
                    </p>
                    <p>
                      {" "}
                      <span className="font-medium">
                        price 1000-4999 units:{" "}
                      </span>
                      {variant.price_4999} $
                    </p>
                    <p>
                      {" "}
                      <span className="font-medium">
                        price 5000-9999 units:{" "}
                      </span>
                      {variant.price_9999} $
                    </p>
                    <p>
                      {" "}
                      <span className="font-medium">weight: </span>
                      {variant.weight} kg
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </motion.form>
  );
};

export default AdminPhotoButtonCard;
